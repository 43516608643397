import React from 'react';

const PhoneIconSm: React.FC = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8076 12.6875C12.5001 12.6875 12.2451 12.9425 12.2451 13.25V14C12.2451 14.93 11.4876 15.6875 10.5576 15.6875H7.55762C6.62762 15.6875 5.87012 14.93 5.87012 14V5C5.87012 4.07 6.62762 3.3125 7.55762 3.3125H10.5576C11.4876 3.3125 12.2451 4.07 12.2451 5C12.2451 5.3075 12.5001 5.5625 12.8076 5.5625C13.1151 5.5625 13.3701 5.3075 13.3701 5C13.3701 3.4475 12.1101 2.1875 10.5576 2.1875H7.55762C6.00512 2.1875 4.74512 3.4475 4.74512 5V14C4.74512 15.5525 6.00512 16.8125 7.55762 16.8125H10.5576C12.1101 16.8125 13.3701 15.5525 13.3701 14V13.25C13.3701 12.9425 13.1151 12.6875 12.8076 12.6875Z"
        fill="currentColor"
      />
      <path
        d="M7.74512 14C7.74512 14.3075 8.00012 14.5625 8.30762 14.5625H9.80762C10.1151 14.5625 10.3701 14.3075 10.3701 14C10.3701 13.6925 10.1151 13.4375 9.80762 13.4375H8.30762C8.00012 13.4375 7.74512 13.6925 7.74512 14Z"
        fill="currentColor"
      />
      <path
        d="M9.27478 9.37288C9.44053 9.25728 9.64924 9.20861 9.84568 9.23903L10.8279 9.39721C11.0918 9.4398 11.3681 9.37288 11.5891 9.22078C11.81 9.0626 11.9574 8.83141 12.0003 8.56372L12.1599 7.59029C12.1906 7.38953 12.3073 7.21309 12.4669 7.0975L13.1114 6.64121C13.4552 6.39785 13.934 6.47694 14.1857 6.81764L14.5786 7.35911C15.4012 8.49071 15.1372 10.0725 13.9954 10.8878L12.4055 12.0255C11.9696 12.3357 11.454 12.5 10.9199 12.5C10.7788 12.5 10.6437 12.4878 10.5025 12.4635C9.8334 12.354 9.24409 11.995 8.84508 11.4475L8.4522 10.906C8.20666 10.5653 8.28032 10.0908 8.62409 9.84742L9.26864 9.38504L9.27478 9.37288ZM9.59399 10.8999C9.84568 11.2528 10.2263 11.484 10.656 11.5509C11.0795 11.6178 11.5154 11.5205 11.8714 11.2711L13.4613 10.1394C14.1918 9.61623 14.3576 8.60631 13.8297 7.88232L13.5289 7.46253L13.0623 7.79715L12.9089 8.70973C12.8291 9.2147 12.5467 9.65882 12.1293 9.96301C11.7118 10.2611 11.19 10.3828 10.6805 10.2976L9.75974 10.1516L9.2932 10.4862L9.59399 10.906V10.8999Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PhoneIconSm;
