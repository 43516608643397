import React from 'react';

// not current color
const OracleIcon: React.FC = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="7" width="19" height="10" rx="5" stroke="#F24E4E" strokeWidth="2" />
    </svg>
  );
};

export default OracleIcon;
