import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { Fade } from 'react-reveal';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/ui/Layout/Layout';
import { FaqBlock } from '../../components/Home/FaqBlock/FaqBlock';
import { partnersFaqData } from '../../components/Home/FaqBlock/FaqBlockConstants';
import { StockAndNewsBlock } from '../../components/Home/StockAndNewsBlock/StockAndNewsBlock';
import { UpperProtectionBlock } from '../../components/Protection/UpperProtectionBlock';
import {
  portsActiveProtectionLinks,
  portsInactiveProtectionLinks,
} from '../../components/Protection/UpperProtectionLinks';
import PortsBanner from '../../assets/backgrounds/ports-banner-background.jpg';
import PortsXsBanner from '../../assets/backgrounds/ports-xs-banner.png';
import { Banner } from '../../components/ui/Banners/Banner';
import { PortsRatesBlock } from '../../components/Protection/PortsRatesBlock/PortsRatesBlock';
import AnimationBlock from '../../components/Protection/AnimationBlock/AnimationBlock';
import AttachPopup from '../../components/ui/Popups/AttachPopup';
import { redirectToProject } from '../../utils/redirectToProject';

const useStyles = makeStyles((theme: Theme) => ({
  upperBlock: {
    marginTop: '97px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '50px',
    },
  },
  animationBlock: {
    marginTop: '81px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '50px',
    },
  },
  ratesBlock: {
    marginTop: '70px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '60px',
    },
  },
  faqBlock: {
    marginTop: '88px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '66px',
    },
  },
  stockAndNewsBlock: {
    marginTop: '93px',
    marginBottom: '63px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '80px',
      marginBottom: '50px',
    },
  },
}));

const PortsProtectionPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isShowAttachPopup, setIsShowAttachPopup] = useState(false);

  const onConnectButtonClick = () => {
    redirectToProject();
  };
  const onQuestionButtonClick = () => {
    setIsShowAttachPopup(true);
  };

  return (
    <Layout>
      {isShowAttachPopup && <AttachPopup onClose={() => setIsShowAttachPopup(false)} />}
      <Banner
        title="Защита TCP-сервиса"
        subtitle="Моментально блокирует любые DDoS-атаки."
        maxWidth
        xsBackground={PortsXsBanner}
        background={PortsBanner}
        leftButton={<Button onClick={onConnectButtonClick}>{t('Подключиться')}</Button>}
        rightButton={
          <Button variant="contained" onClick={onQuestionButtonClick}>
            {t('Задать вопрос')}
          </Button>
        }
      />
      <div className={classes.upperBlock}>
        <Fade>
          <UpperProtectionBlock
            isReverse
            title="Удаленная защита для ваших TCP сервисов"
            subtitle={
              'Моментально блокирует любые DDoS-атаки.\n' +
              '\n' +
              'Значительно повышает отказоустойчивость вашего сервиса.\n' +
              ' Точки фильтрации на выбор: Россия или Европа.\n'
            }
            item={{
              activeLinks: portsActiveProtectionLinks,
              inactiveLinks: portsInactiveProtectionLinks,
              inactiveTitle: 'Работает на',
            }}
          />
        </Fade>
      </div>
      <div className={classes.animationBlock}>
        <AnimationBlock />
      </div>
      <div className={classes.ratesBlock}>
        <PortsRatesBlock />
      </div>
      <div className={classes.faqBlock}>
        <FaqBlock items={partnersFaqData} />
      </div>
      <div className={classes.stockAndNewsBlock}>
        <StockAndNewsBlock />
      </div>
    </Layout>
  );
};

export default withRouter(PortsProtectionPage);
