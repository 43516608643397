import React from 'react';

const LaptopIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 11.25H18.75V5C18.75 2.38 16.62 0.25 14 0.25H6C3.38 0.25 1.25 2.38 1.25 5V11.25H1C0.59 11.25 0.25 11.59 0.25 12V13C0.25 14.52 1.48 15.75 3 15.75H17C18.52 15.75 19.75 14.52 19.75 13V12C19.75 11.59 19.41 11.25 19 11.25ZM2.75 5C2.75 3.21 4.21 1.75 6 1.75H14C15.79 1.75 17.25 3.21 17.25 5V11.25H13.41C12.94 11.25 12.5 11.43 12.17 11.76L11.76 12.17C11.76 12.17 11.65 12.24 11.58 12.24H8.41C8.34 12.24 8.28 12.21 8.23 12.17L7.82 11.76C7.49 11.43 7.05 11.25 6.58 11.25H2.74V5H2.75ZM18.25 13C18.25 13.69 17.69 14.25 17 14.25H3C2.31 14.25 1.75 13.69 1.75 13V12.75H6.59C6.66 12.75 6.72 12.78 6.77 12.82L7.18 13.23C7.51 13.56 7.95 13.74 8.42 13.74H11.59C12.06 13.74 12.5 13.56 12.83 13.23L13.24 12.82C13.24 12.82 13.35 12.75 13.42 12.75H18.26V13H18.25Z"
        fill="currentColor"
      />
      <path
        d="M10 9.75C10.41 9.75 10.75 9.41 10.75 9V7C10.75 6.59 10.41 6.25 10 6.25C9.59 6.25 9.25 6.59 9.25 7V9C9.25 9.41 9.59 9.75 10 9.75Z"
        fill="currentColor"
      />
      <path
        d="M7.75 9V8C7.75 7.59 7.41 7.25 7 7.25C6.59 7.25 6.25 7.59 6.25 8V9C6.25 9.41 6.59 9.75 7 9.75C7.41 9.75 7.75 9.41 7.75 9Z"
        fill="currentColor"
      />
      <path
        d="M13 9.75C13.41 9.75 13.75 9.41 13.75 9V5C13.75 4.59 13.41 4.25 13 4.25C12.59 4.25 12.25 4.59 12.25 5V9C12.25 9.41 12.59 9.75 13 9.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LaptopIcon;
