import React from 'react';

const ChatIcon: React.FC = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.48373 0.740019C11.991 0.664041 14.3938 1.60427 16.1983 3.36127C18.0028 5.11826 19 7.47359 19 9.99987V17.5977C19 18.5094 18.2497 19.2597 17.338 19.2597H9.74965C7.22338 19.2597 4.86805 18.2625 3.11105 16.458C1.35406 14.6535 0.423324 12.2602 0.489805 9.74344C0.622767 4.91882 4.65911 0.882478 9.48373 0.749516V0.740019ZM4.12726 15.4608C5.61834 16.9899 7.61276 17.8351 9.74015 17.8351H17.3475C17.4804 17.8351 17.5849 17.7307 17.5849 17.5977V9.99987C17.5849 7.86298 16.7396 5.86855 15.2106 4.38697C13.7385 2.95289 11.8011 2.16461 9.74965 2.16461C9.67367 2.16461 9.59769 2.16461 9.52172 2.16461C5.43789 2.27858 2.02836 5.6881 1.9144 9.77193C1.85741 11.9088 2.64569 13.9317 4.12726 15.4608V15.4608Z"
        fill="currentColor"
      />
      <path
        d="M9.73106 9.28763H9.74056C10.1299 9.28763 10.4434 9.61054 10.4434 9.99992C10.4434 10.3893 10.1205 10.7122 9.73106 10.7122C9.34168 10.7122 9.01877 10.3893 9.01877 9.99992C9.01877 9.61054 9.34168 9.28763 9.73106 9.28763Z"
        fill="currentColor"
      />
      <path
        d="M13.5299 9.28763H13.5394C13.9288 9.28763 14.2422 9.61054 14.2422 9.99992C14.2422 10.3893 13.9193 10.7122 13.5299 10.7122C13.1405 10.7122 12.8176 10.3893 12.8176 9.99992C12.8176 9.61054 13.1405 9.28763 13.5299 9.28763Z"
        fill="currentColor"
      />
      <path
        d="M5.93126 9.28763C6.32065 9.28763 6.64355 9.61054 6.64355 9.99992C6.64355 10.3893 6.32065 10.7122 5.93126 10.7122C5.54187 10.7122 5.21896 10.3893 5.21896 9.99992C5.21896 9.61054 5.53237 9.28763 5.93126 9.28763Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChatIcon;
