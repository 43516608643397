import { FooterLinkProps } from '../../Link/FooterLink';
import { ProjectPath } from '../../../../constants/ProjectPath';

export const servicesLinks: FooterLinkProps[] = [
  {
    title: 'Защита веб-сайтов',
    url: ProjectPath.SiteProtection,
  },
  {
    title: 'Защита TCP-сервиса',
    url: ProjectPath.PortsProtection,
  },
  {
    title: 'Тарифы',
    url: ProjectPath.Tariffs,
  },
  {
    title: 'Акции',
    url: ProjectPath.Stocks,
  },
];

export const forClientLinks: FooterLinkProps[] = [
  {
    title: 'Личный кабинет',
    url: ProjectPath.Profile,
  },
  {
    title: 'Чат-поддержка',
    url: ProjectPath.TechnicalSupport,
  },
  {
    title: 'Партнерам',
    url: ProjectPath.Partners,
  },
  {
    title: 'Под атакой ?',
    url: '',
  },
];

export const forClientLinksXs: FooterLinkProps[] = [
  {
    title: 'Личный кабинет',
    url: ProjectPath.Profile,
  },
  {
    title: 'Чат-поддержка',
    url: ProjectPath.TechnicalSupport,
  },
  {
    title: 'Партнерам',
    url: ProjectPath.Partners,
  },
  {
    title: 'Политика конфиденциальности',
    url: ProjectPath.Politics,
  },
];

export const companyLinks: FooterLinkProps[] = [
  {
    title: 'О компании',
    url: ProjectPath.About,
  },
  {
    title: 'База знаний',
    url: ProjectPath.KnowledgeBase,
  },
  {
    title: 'Партнерам',
    url: ProjectPath.Partners,
  },
  {
    title: 'Блог',
    url: ProjectPath.Blog,
  },
];
