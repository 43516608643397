import React, { useRef } from 'react';
import { Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../../constants/Colors';
import {
  DESKTOP_HEADER_HEIGHT,
  LAYOUT_LEFT_RIGHT_PADDING_LG,
  LAYOUT_LEFT_RIGHT_PADDING_MD,
  LAYOUT_LEFT_RIGHT_PADDING_XL,
  MOBILE_HEADER_HEIGHT,
} from '../../../../../constants/UI';
import { GlobalStyles } from '../../../../../styles/GlobalStyles';
import { CustomLink } from '../../../Link/CustomLink';
import { aboutLinks, clientLinks, protectLinks } from './MenuConstants';
import { Socials } from '../../../Social/Socials';
import useOnClickInside from '../../../../../hooks/useOnClickInside';
import PhoneIcon from '../../../Icons/PhoneIcon';
import EmailIcon from '../../../Icons/EmailIcon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 4,
    position: 'absolute',
    top: `calc(${DESKTOP_HEADER_HEIGHT} - 2.5rem)`,
    left: 0,
    right: 0,
    [theme.breakpoints.down('md')]: {
      top: MOBILE_HEADER_HEIGHT,
      // minHeight: `calc(100vh - ${MOBILE_HEADER_HEIGHT})`,
    },
  },
  content: {
    borderRadius: '0px 0px 30px 30px',
    height: '100%',
    width: '100%',
    padding: `2.375rem ${LAYOUT_LEFT_RIGHT_PADDING_XL}`,
    backgroundColor: Colors.white,
    ...GlobalStyles.lightBorder,
    ...GlobalStyles.boxShadow,
    [theme.breakpoints.down('lg')]: {
      padding: `2.375rem ${LAYOUT_LEFT_RIGHT_PADDING_LG}`,
    },
    [theme.breakpoints.down('md')]: {
      padding: `2.375rem ${LAYOUT_LEFT_RIGHT_PADDING_MD} 3rem`,
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      padding: `1rem ${LAYOUT_LEFT_RIGHT_PADDING_MD} 1rem`,
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      padding: `1rem`,
    },
  },
  contentMobile: {
    height: 'calc(100vh - 4.35rem)',
    overflow: 'scroll',
    [theme.breakpoints.down('xs')]: {
      padding: `1rem`,
    },
  },
  grid: {
    flexWrap: 'nowrap',
    gap: '1.25rem',
    [theme.breakpoints.down(1310)]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      gap: `1rem`,
    },
  },
  gridItem: {
    minWidth: '16.25rem',
    padding: '1.875rem',
    backgroundColor: Colors.backgroundSecondary,
    marginRight: 0,
    borderRadius: '1.25rem',
    '&:not(:last-child)': {
      [theme.breakpoints.down('xs')]: {
        paddingBottom: `1rem`,
      },
    },
    '&:last-child': {
      [theme.breakpoints.down('xs')]: {
        paddingBottom: `2.5rem`,
      },
    },
  },
  title: {
    marginBottom: '1.25rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '26px',
      lineHeight: '39px',
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    height: '1.5rem',
    marginBottom: '0.875rem',
    [theme.breakpoints.down('xs')]: {
      height: `1.2rem`,
    },
  },
  socials: {
    display: 'flex',
    gap: '1.25rem',
    marginTop: '0.625rem',
  },
  languageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  underMenu: {
    height: '100vh',
  },
  linkFont: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
      lineHeight: '17.61px',
    },
  },
}));

export type TProps = {
  onCloseButtonClick: () => void;
};

export const Menu: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { onCloseButtonClick } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(1310));

  const underMenu = useRef<HTMLDivElement>(null);
  const clickOutsideHandler = () => {
    onCloseButtonClick();
  };
  useOnClickInside(underMenu, clickOutsideHandler);

  const renderGridItem = (children: React.ReactNode) => {
    if (mobile) {
      return (
        <Grid item xs={12} sm md={5} className={classes.gridItem}>
          {children}
        </Grid>
      );
    }
    return (
      <Grid item xs={3} className={classes.gridItem}>
        {children}
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <div className={[classes.content, mobile ? classes.contentMobile : ''].join(' ')}>
        {/* {mobile && (
          <div className={classes.languageContainer}>
            <LanguageButton />
          </div>
        )} */}
        <Grid container className={classes.grid}>
          {renderGridItem(
            <>
              <Typography variant="h3" component="div" className={classes.title}>
                {t('Защита')}
              </Typography>
              {protectLinks.map((item, index) => {
                return (
                  <div key={index} className={classes.link}>
                    <CustomLink
                      className={classes.linkFont}
                      item={item}
                      isIconFixed={item.isIconFixed}
                      isLg
                      isBlueIcon
                      textColor={Colors.fontColor}
                    />
                  </div>
                );
              })}
            </>,
          )}
          {renderGridItem(
            <>
              <Typography variant="h3" component="div" className={classes.title}>
                {t('Клиентам')}
              </Typography>
              {clientLinks.map((item, index) => {
                return (
                  <div key={index} className={classes.link}>
                    <CustomLink
                      className={classes.linkFont}
                      item={item}
                      isLg
                      isIconFixed={item.isIconFixed}
                      isBlueIcon
                      textColor={Colors.fontColor}
                    />
                  </div>
                );
              })}
            </>,
          )}
          {renderGridItem(
            <>
              <Typography variant="h3" component="div" className={classes.title}>
                {t('Компания')}
              </Typography>
              {aboutLinks.map((item, index) => {
                return (
                  <div key={index} className={classes.link}>
                    <CustomLink
                      className={classes.linkFont}
                      item={item}
                      isIconFixed={item.isIconFixed}
                      isLg
                      textColor={Colors.fontColor}
                    />
                  </div>
                );
              })}
            </>,
          )}
          {renderGridItem(
            <>
              <Typography variant="h3" component="div" className={classes.title}>
                {t('Контакты')}
              </Typography>
              <div className={classes.link}>
                <CustomLink
                  className={classes.linkFont}
                  isLg
                  isPhone
                  isBlueIcon
                  item={{
                    icon: <PhoneIcon />,
                    title: '8 800 888 77 66',
                    url: '',
                  }}
                  textColor={Colors.fontColor}
                />
              </div>
              <div className={classes.link}>
                <CustomLink
                  className={classes.linkFont}
                  isLg
                  isEmail
                  isBlueIcon
                  item={{
                    icon: <EmailIcon />,
                    title: 'support@ddosa.net',
                    url: '',
                  }}
                  textColor={Colors.fontColor}
                />
              </div>
              <div className={classes.socials}>
                <Socials />
              </div>
            </>,
          )}
        </Grid>
      </div>
      <div className={classes.underMenu} ref={underMenu} />
    </div>
  );
};
