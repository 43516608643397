import React from 'react';

const LockIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.25C4.62 0.25 0.25 4.62 0.25 10C0.25 15.38 4.62 19.75 10 19.75C15.38 19.75 19.75 15.38 19.75 10C19.75 4.62 15.38 0.25 10 0.25ZM10 18.25C5.45 18.25 1.75 14.55 1.75 10C1.75 5.45 5.45 1.75 10 1.75C14.55 1.75 18.25 5.45 18.25 10C18.25 14.55 14.55 18.25 10 18.25Z"
        fill="currentColor"
      />
      <path
        d="M13 8.25H12.75V8C12.75 6.48 11.52 5.25 10 5.25C8.48 5.25 7.25 6.48 7.25 8V8.25H7C6.04 8.25 5.25 9.04 5.25 10V13C5.25 13.96 6.04 14.75 7 14.75H13C13.96 14.75 14.75 13.96 14.75 13V10C14.75 9.04 13.96 8.25 13 8.25ZM8.75 8C8.75 7.31 9.31 6.75 10 6.75C10.69 6.75 11.25 7.31 11.25 8V8.25H8.75V8ZM13.25 13C13.25 13.14 13.14 13.25 13 13.25H7C6.86 13.25 6.75 13.14 6.75 13V10C6.75 9.86 6.86 9.75 7 9.75H13C13.14 9.75 13.25 9.86 13.25 10V13Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LockIcon;
