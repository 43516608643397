import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { makeStyles, Theme } from '@material-ui/core';
import { Fade } from 'react-reveal';
import { Layout } from '../../components/ui/Layout/Layout';
import { MainBanner } from '../../components/ui/Banners/MainBanner';
import { UpperBlock } from '../../components/Home/UpperBlock/UpperBlock';
import { LgHowProtectionWorksBanner } from '../../components/ui/Banners/HowProtectionWorks/LgHowProtectionWorksBanner';
import { AboutFeaturesBlock } from '../../components/Home/AboutBlock/AboutFeaturesBlock';
import { FaqBlock } from '../../components/Home/FaqBlock/FaqBlock';
import { PartnersBlock } from '../../components/Home/PartnersBlock/PartnersBlock';
import { StockAndNewsBlock } from '../../components/Home/StockAndNewsBlock/StockAndNewsBlock';
import { indexFaqData } from '../../components/Home/FaqBlock/FaqBlockConstants';

const useStyles = makeStyles((theme: Theme) => ({
  protectionBanner: {
    marginTop: '6.25rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '60px',
    },
  },
  featuresContainer: {
    margin: '9.375rem 0 9.5rem',
    [theme.breakpoints.down('xs')]: {
      margin: '70px 0',
    },
  },
  newsContainer: {
    marginTop: '7.75rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '70px',
    },
  },
  faqsContainer: {
    margin: '8.625rem 0 3.125rem',
    [theme.breakpoints.down('xs')]: {
      margin: '70px 0 0',
    },
  },
}));

const IndexPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Fade>
        <MainBanner />
        <UpperBlock />
      </Fade>
      <div className={classes.protectionBanner}>
        <Fade>
          <LgHowProtectionWorksBanner />
        </Fade>
      </div>
      <div className={classes.featuresContainer}>
        <AboutFeaturesBlock />
      </div>
      <PartnersBlock />
      <div className={classes.newsContainer}>
        <StockAndNewsBlock />
      </div>
      <div className={classes.faqsContainer}>
        <FaqBlock items={indexFaqData} />
      </div>
    </Layout>
  );
};

export default withRouter(IndexPage);
