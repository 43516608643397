import React from 'react';
import { Button, Link, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import BasePopup from './BasePopup';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { ProjectPath } from '../../../constants/ProjectPath';
import { Tooltip } from '../Tooltip/Tooltip';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '389px',
    padding: '40px 32px 34px 30px',
    backgroundColor: Colors.backgroundSecondary,
    ...GlobalStyles.lightBorder,
    ...GlobalStyles.borderRadius,
    [theme.breakpoints.down('xs')]: {
      width: '309px',
      padding: '40px 16px 34px 16px',
    },
  },
  input: {
    height: '100%',
    width: '100%',
    marginBottom: '14px',
    '& .MuiInputBase-root': {
      height: '100%',
      backgroundColor: Colors.white,
      '& .MuiOutlinedInput-input': {
        padding: '12px 20px',
      },
      '& input': {
        fontFamily: 'Raleway-Medium',
        fontSize: '13px',
        lineHeight: '15.26px',
        color: Colors.fontColor,
        fontFeatureSettings: `'pnum' on, 'lnum' on`,
        '&::placeholder': {
          color: Colors.fontColor,
        },
      },
    },
  },
  container: {
    position: 'relative',
  },
  tooltip: {
    top: '14px',
    right: '14px',
    position: 'absolute',
  },
  multiline: {
    borderRadius: '15px',
    height: '90px',

    '& .MuiInputBase-root': {
      padding: 0,
      borderRadius: '15px',
      display: 'flow-root',
      '& > textarea': {
        width: '84%',
        fontFamily: 'Raleway-Medium',
        fontSize: '13px',
        lineHeight: '15.26px',
        color: Colors.fontColor,
        fontFeatureSettings: `'pnum' on, 'lnum' on`,
        '&::placeholder': {
          color: Colors.fontColor,
          opacity: 1,
        },
      },
    },
  },
  subtitle: {
    color: Colors.darkGray,
  },
  link: {
    color: Colors.primary,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  error: {
    bottom: 0,
    color: Colors.error,
    fontSize: '13px',
    lineHeight: '15.73px',
    margin: '0.6rem 0',
    display: 'inline-block',
  },
  inputError: {
    marginBottom: 0,
    '& .MuiInputBase-root': {
      border: `1px solid ${Colors.error}`,
    },
  },
}));

type TProps = {
  onClose: () => void;
};

interface IConnectFormData {
  email: string;
  name: string;
  message: string;
  phone: string;
}

const AttachPopup: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { onClose } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const schema = yup.object().shape({
    email: yup.string().required(t('Ошибка. Данные не заполнены')).email().max(500),
    name: yup.string().required(t('Ошибка. Данные не заполнены')).max(500),
    phone: yup.string().required(t('Ошибка. Данные не заполнены')).max(100),
    message: yup.string(),
  });

  const { control, handleSubmit, errors } = useForm<IConnectFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  return (
    <BasePopup
      title="Контакты"
      onClose={onClose}
      content={
        <div className={classes.form}>
          <Controller
            control={control}
            name="name"
            defaultValue=""
            as={({ onChange, onBlur, value }) => (
              <div>
                <TextField
                  className={[classes.input, errors.name?.message ? classes.inputError : ''].join(
                    ' ',
                  )}
                  fullWidth
                  id="name"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  placeholder="Ваше имя"
                  variant="outlined"
                  hiddenLabel
                />
                <span className={classes.error}>{errors.name?.message}</span>
              </div>
            )}
          />
          <Controller
            control={control}
            name="email"
            defaultValue=""
            as={({ onChange, onBlur, value }) => (
              <div>
                <TextField
                  className={[classes.input, errors.email?.message ? classes.inputError : ''].join(
                    ' ',
                  )}
                  fullWidth
                  id="email"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  type="email"
                  placeholder="Email"
                  variant="outlined"
                  hiddenLabel
                />
                <span className={classes.error}>{errors.email?.message}</span>
              </div>
            )}
          />
          <Controller
            control={control}
            name="phone"
            defaultValue=""
            as={({ onChange, onBlur, value }) => (
              <div>
                <TextField
                  className={[classes.input, errors.phone?.message ? classes.inputError : ''].join(
                    ' ',
                  )}
                  fullWidth
                  id="phone"
                  type="tel"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  placeholder="Телефон"
                  variant="outlined"
                  hiddenLabel
                />
                <span className={classes.error}>{errors.phone?.message}</span>
              </div>
            )}
          />
          <div className={classes.container}>
            <div className={classes.tooltip}>
              <Tooltip text="Бесперебойная доступность сервиса на протяжении всего периода" />
            </div>
            <Controller
              control={control}
              name="message"
              defaultValue=""
              as={({ onChange, onBlur, value }) => (
                <div>
                  <TextField
                    className={`${classes.input} ${classes.multiline}`}
                    id="message"
                    placeholder="Ваш вопрос"
                    multiline
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxRows={4}
                    hiddenLabel
                    variant="outlined"
                  />
                </div>
              )}
            />
          </div>
          <Typography variant="body2" component="p" className={classes.subtitle}>
            {t('Нажимая кнопку «Отправить», я подтверждаю своё согласие с ')}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              className={classes.link}
              onClick={() => {
                history.push(ProjectPath.Politics);
                onClose();
              }}
            >
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              {t('Политикой конфиденциальности')}
            </Link>
            {t(' и Условий использования сервисами.')}
          </Typography>
        </div>
      }
      rightButton={<Button onClick={handleSubmit(onClose)}>{t('Отправить')}</Button>}
    />
  );
};

export default AttachPopup;
