export const DESKTOP_HEADER_HEIGHT = '6.625rem';
export const MOBILE_HEADER_HEIGHT = '4.375rem';

export const DESKTOP_FOOTER_HEIGHT = '24rem';
export const MOBILE_FOOTER_HEIGHT = '44.75rem';

export const LAYOUT_LEFT_RIGHT_PADDING_XL = '16.25rem';
export const LAYOUT_LEFT_RIGHT_BANNER_PADDING_XL = '10rem';
export const LAYOUT_LEFT_RIGHT_CONTENT_PADDING_XL = '6.25rem';

export const LAYOUT_LEFT_RIGHT_PADDING_LG = '6rem';
export const LAYOUT_LEFT_RIGHT_BANNER_PADDING_LG = '3rem';
export const LAYOUT_LEFT_RIGHT_CONTENT_PADDING_LG = '3rem';

export const LAYOUT_LEFT_RIGHT_PADDING_MD = '3rem';
export const LAYOUT_LEFT_RIGHT_BANNER_PADDING_MD = '1.5rem';
export const LAYOUT_LEFT_RIGHT_CONTENT_PADDING_MD = '1.5rem';

export const LAYOUT_LEFT_RIGHT_PADDING_XS = '1rem';
export const LAYOUT_LEFT_RIGHT_BANNER_PADDING_XS = 0;
export const LAYOUT_LEFT_RIGHT_CONTENT_PADDING_XS = '1rem';
