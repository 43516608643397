import React from 'react';

const HugeIdeaIcon: React.FC = () => {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6192 6.40002C14.9292 2.80002 11.6692 0.130018 8.0192 0.250018C4.3492 0.120018 1.0692 2.80002 0.379204 6.40002C-0.200796 9.44002 1.1792 12.59 3.7992 14.25C4.1592 14.48 4.4892 15.08 4.4892 15.5V17.61C4.4892 18.79 5.3992 19.75 6.5092 19.75H9.4692C10.5792 19.75 11.4892 18.79 11.4892 17.61V15.5C11.4892 15.08 11.8192 14.48 12.1792 14.25C14.8092 12.59 16.1792 9.44002 15.5992 6.40002H15.6192ZM9.4792 18.25H6.5192C6.2292 18.25 5.9992 17.96 5.9992 17.61V16.75H9.9592C9.9592 16.75 9.9892 16.75 10.0092 16.75V17.62C10.0092 17.97 9.7792 18.26 9.4892 18.26L9.4792 18.25ZM11.3892 12.98C10.6592 13.44 10.1192 14.39 10.0292 15.27C9.9992 15.27 9.9792 15.25 9.9492 15.25H5.9592C5.8692 14.38 5.3292 13.44 4.6092 12.98C2.4992 11.65 1.3892 9.11002 1.8592 6.68002C2.3992 3.84002 4.9492 1.75002 7.8692 1.75002C7.9192 1.75002 7.9692 1.75002 8.0192 1.75002C10.9892 1.66002 13.5892 3.79002 14.1492 6.68002C14.6092 9.12002 13.5092 11.65 11.3992 12.98H11.3892Z"
        fill="currentColor"
      />
      <path
        d="M5.08949 3.87997C4.58949 4.15997 3.88949 4.67997 3.41949 5.55997C2.39949 7.42997 3.22949 9.33997 3.39949 9.70997C3.52949 9.97997 3.79949 10.13 4.07949 10.13C4.18949 10.13 4.29949 10.11 4.39949 10.06C4.76949 9.87997 4.92949 9.42997 4.74949 9.05997C4.67949 8.90997 4.04949 7.52997 4.72949 6.27997C5.03949 5.71997 5.48949 5.37997 5.81949 5.18997C6.17949 4.98997 6.30949 4.52997 6.09949 4.16997C5.89949 3.80997 5.43949 3.67997 5.07949 3.88997L5.08949 3.87997Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HugeIdeaIcon;
