import React from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { CustomLinkProps } from '../Link/CustomLink';
import DotsIcon from '../../../assets/icons/dots.svg';
import DotsWhiteIcon from '../../../assets/icons/dots-white.svg';

const useStyles = makeStyles({
  root: {
    height: '100%',
    maxWidth: '28.25rem',
    backgroundColor: Colors.white,
    padding: '2rem 1.18rem 1.18rem 2rem',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    ...GlobalStyles.boxShadow,
    ...GlobalStyles.borderRadius,

    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover button.MuiIconButton-root': {
      color: Colors.white,
      opacity: 1,
      backgroundColor: Colors.primary,

      '& span div': {
        backgroundImage: `url(${DotsWhiteIcon})`,
      },
    },
  },
  header: {
    display: 'flex',
    marginBottom: '1rem',
    '& > button': {
      fontFamily: 'Raleway-SemiBold',
      fontSize: '20px',
    },
  },
  content: {
    '& > p': {
      color: Colors.lightBlack,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dotsButton: {
    height: '2.5rem',
    width: '2.5rem',
    backgroundColor: Colors.backgroundSecondary,
  },
  dotsIcon: {
    height: '1rem',
    width: '1rem',
    backgroundImage: `url(${DotsIcon})`,
    ...GlobalStyles.icon,
  },
  icon: {
    height: '1.5rem',
    width: '1.5rem',
    marginRight: '0.75rem',
    ...GlobalStyles.icon,
  },
});

type TProps = {
  subtitle: string;
  link: CustomLinkProps;
};

export const UpperBlockCard: React.FC<TProps> = (props: TProps) => {
  const { link, subtitle } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const onClick = () => history.push(link.url ?? '');
  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <div className={classes.root} onClick={onClick} onKeyDown={onClick}>
      <div className={classes.header}>
        <div className={classes.icon}>{link.icon}</div>
        <Typography variant="h3" component="div">
          {t(link.title)}
        </Typography>
      </div>
      <div className={classes.content}>
        <Typography variant="h6" component="p">
          {t(subtitle)}
        </Typography>
      </div>
      <div className={classes.buttonContainer}>
        <IconButton className={classes.dotsButton} onClick={onClick}>
          <div className={classes.dotsIcon} />
        </IconButton>
      </div>
    </div>
  );
};
