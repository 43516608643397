import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Fade } from 'react-reveal';
import { Layout } from '../../components/ui/Layout/Layout';
import ErrorWithDescriptionBanner from '../../components/Error/ErrorWithDescriptionBanner';
import ErrorCards from '../../components/Error/ErrorCards';
import { StatusEnum } from '../../components/ui/Status/Status';
import EarthError from '../../assets/errors/earth-error.png';
import ShieldError from '../../assets/errors/shield-error.png';
import ServerError from '../../assets/errors/server-error.png';

const GatewayErrorDescriptionPage: React.FC<RouteComponentProps> = () => {
  return (
    <Layout>
      <Fade>
        <ErrorWithDescriptionBanner
          title="Ошибка шлюза"
          subtitle="Ошибка. Попробуйте перезагрузить страницу или зайти позже"
          buttonText="Обновить"
          leftTitle="Частые причины ошибки:"
          leftSubtitle={['Проблемы на этапе шлюза.', 'Не верно настроены настройки сервиса.']}
          rightTitle="Если вы администратор сайта:"
          rightSubtitle={[
            'Связаться со службой поддержки используя личный кабинет или телефон горячей линии: 8-800-100-2116 (круглосуточно).',
          ]}
          content={
            <ErrorCards
              blackIndex={3}
              xsBlackIndex={1}
              cards={[
                { status: StatusEnum.Success, image: EarthError, title: 'Браузер' },
                { status: StatusEnum.Error, image: ShieldError, title: '' },
                { status: StatusEnum.None, image: ServerError, title: 'Сайт' },
              ]}
            />
          }
        />
      </Fade>
    </Layout>
  );
};

export default withRouter(GatewayErrorDescriptionPage);
