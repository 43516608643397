import React from 'react';

const LogoIcon: React.FC = () => {
  return (
    <svg width="60" height="24" viewBox="0 0 60 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.79 16.6031V6.215H15.2937C16.5915 6.215 17.6748 6.44909 18.5437 6.91729C19.4235 7.37573 20.0834 8 20.5233 8.79008C20.9743 9.57041 21.1997 10.4385 21.1997 11.3944C21.1997 12.4479 20.9523 13.3647 20.4573 14.1451C19.9734 14.9254 19.286 15.5302 18.3952 15.9593C17.5154 16.3885 16.4815 16.6031 15.2937 16.6031H10.79ZM17.9333 11.3944C17.9333 10.8579 17.8288 10.3897 17.6198 9.98983C17.4109 9.58016 17.1084 9.26315 16.7125 9.03881C16.6677 9.0134 16.6218 8.98943 16.575 8.9669C16.2085 8.79049 15.7814 8.70229 15.2937 8.70229H14.007V14.1158H15.2937C15.8547 14.1158 16.3276 13.9988 16.7125 13.7647C16.7557 13.7391 16.7979 13.7125 16.8389 13.6848C17.1734 13.4592 17.4337 13.1639 17.6198 12.799C17.8288 12.3796 17.9333 11.9114 17.9333 11.3944Z"
        fill="#4577FA"
      />
      <path
        d="M32.497 16.7641H29.3626V12.4332C29.3626 12.0528 29.2636 11.7797 29.0656 11.6139C28.8787 11.4383 28.6587 11.3505 28.4058 11.3505C28.2298 11.3505 28.0428 11.3993 27.8449 11.4968C27.6469 11.5846 27.4654 11.7114 27.3005 11.8772C27.1355 12.0431 27.0035 12.2333 26.9045 12.4479V16.7641H23.7701V9.06807H26.5911V10.2093C26.8 9.92643 27.064 9.69233 27.3829 9.507C27.7019 9.31192 28.0703 9.16561 28.4883 9.06807C28.9062 8.97053 29.3626 8.92176 29.8575 8.92176C30.4514 8.92176 30.9243 9.01442 31.2763 9.19975C31.6282 9.38508 31.8922 9.62405 32.0681 9.91668C32.2441 10.2093 32.3596 10.5166 32.4146 10.8384C32.4696 11.1506 32.497 11.4383 32.497 11.7017V16.7641Z"
        fill="#4577FA"
      />
      <path
        d="M38.2891 16.9104C37.5302 16.9104 36.8538 16.8079 36.2599 16.6031C35.666 16.3885 35.1656 16.1008 34.7587 15.7399C34.3628 15.379 34.0603 14.9644 33.8514 14.4962C33.6424 14.028 33.5379 13.5354 33.5379 13.0185C33.5379 12.2772 33.7194 11.5993 34.0823 10.9847C34.4453 10.3605 34.9787 9.86303 35.6825 9.49237C36.3864 9.11196 37.2553 8.92176 38.2891 8.92176C39.3229 8.92176 40.1918 9.10709 40.8956 9.47774C41.5995 9.8484 42.1329 10.3361 42.4959 10.9409C42.8588 11.5456 43.0403 12.204 43.0403 12.9161C43.0403 13.0624 43.0293 13.2136 43.0073 13.3696C42.9963 13.5159 42.9798 13.6476 42.9578 13.7647H36.8703C36.8923 14.0378 36.9748 14.2621 37.1178 14.4377C37.2718 14.6035 37.4587 14.7254 37.6787 14.8035C37.9096 14.8815 38.1461 14.9205 38.3881 14.9205C38.729 14.9205 39.0479 14.8522 39.3449 14.7157C39.6528 14.5791 39.8563 14.3889 39.9553 14.1451L42.6113 14.8181C42.3804 15.2278 42.0559 15.5887 41.638 15.9008C41.2311 16.2129 40.7472 16.4617 40.1863 16.647C39.6254 16.8226 38.993 16.9104 38.2891 16.9104ZM36.8043 12.0967H39.6748C39.6419 11.8431 39.5594 11.6285 39.4274 11.4529C39.3064 11.2774 39.1414 11.1408 38.9325 11.0433C38.7345 10.9457 38.5036 10.897 38.2396 10.897C37.9756 10.897 37.7392 10.9457 37.5302 11.0433C37.3323 11.1408 37.1673 11.2774 37.0353 11.4529C36.9143 11.6285 36.8373 11.8431 36.8043 12.0967Z"
        fill="#4577FA"
      />
      <path
        d="M50.06 16.3397C49.7741 16.447 49.4661 16.5495 49.1362 16.647C48.8173 16.7348 48.4873 16.8031 48.1464 16.8518C47.8164 16.9006 47.492 16.925 47.173 16.925C46.7001 16.925 46.2657 16.8567 45.8698 16.7202C45.4738 16.5738 45.1549 16.3397 44.9129 16.0179C44.682 15.6862 44.5665 15.2424 44.5665 14.6864V11.1603H43.4942V9.06807H44.5665V6.65393H47.701V9.06807H49.4002V11.1603H47.701V13.7061C47.701 13.9597 47.7614 14.1402 47.8824 14.2475C48.0034 14.345 48.1684 14.3938 48.3773 14.3938C48.5313 14.3938 48.7073 14.3694 48.9052 14.3207C49.1032 14.2719 49.3012 14.2134 49.4991 14.1451L50.06 16.3397Z"
        fill="#4577FA"
      />
      <rect
        x="1.25"
        y="0.75"
        width="58"
        height="22.3"
        rx="11.15"
        stroke="#4577FA"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default LogoIcon;
