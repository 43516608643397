import { FeatureCardProps } from '../../ui/Cards/FeatureCard';
import RuIcon from '../../../assets/icons/ru.svg';
import IdeaIcon from '../../../assets/icons/idea.svg';
import PadlockIcon from '../../../assets/icons/padlock.svg';
import RocketIcon from '../../../assets/icons/feature-rocket.svg';

export const featuresData: FeatureCardProps[] = [
  {
    title: 'Надежно',
    subtitle: 'Мы работаем уже много лет над созданием и развитием наших технологий и стандартов',
    isContained: true,
    icon: PadlockIcon,
    isLeft: false,
  },
  {
    title: 'Быстро',
    subtitle:
      'Оптимизация работы, регламенты, тесты и новые разработки, позволяют ускорять запуск и поддержку',
    isContained: false,
    icon: RocketIcon,
    isLeft: false,
  },
  {
    title: 'Доступно',
    subtitle:
      'Наша ценовая политика нацелена на широкий сектор и на поддержку  доступной безопасности',
    isContained: false,
    icon: RuIcon,
    isLeft: false,
  },
  {
    title: 'Просто',
    subtitle:
      'Разрабатывая инструменты мы смотрим на удобство и эффективность продуктов для наших пользователей',
    isContained: true,
    icon: IdeaIcon,
    isLeft: false,
  },
];

export const featuresDataOneColumn: FeatureCardProps[] = [
  {
    title: 'Надежно',
    subtitle: 'Мы работаем уже много лет над созданием и развитием наших технологий и стандартов',
    isContained: true,
    icon: PadlockIcon,
    isLeft: false,
  },
  {
    title: 'Быстро',
    subtitle:
      'Оптимизация работы, регламенты, тесты и новые разработки, позволяют ускорять запуск и поддержку',
    isContained: false,
    icon: RocketIcon,
    isLeft: true,
  },
  {
    title: 'Просто',
    subtitle:
      'Разрабатывая инструменты мы смотрим на удобство и эффективность продуктов для наших пользователей',
    isContained: true,
    icon: IdeaIcon,
    isLeft: false,
  },
  {
    title: 'Доступно',
    subtitle:
      'Наша ценовая политика нацелена на широкий сектор и на поддержку  доступной безопасности',
    isContained: false,
    icon: RuIcon,
    isLeft: true,
  },
];
