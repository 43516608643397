import React, { useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Fade } from 'react-reveal';
import { useTranslation } from 'react-i18next';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { Colors } from '../../../constants/Colors';
import { SitesRateCard } from './SitesRateCard';
import { sitesRatesConstants } from './SitesRatesConstants';
import { SitesRatesSwiper } from './SitesRatesSwiper';
import RateCard from '../RateCard';
import { certificates, protectedDns } from '../../Home/UpperBlock/UpperBlockConstants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(760)]: {
      alignItems: 'initial',
      flexDirection: 'column',
    },
  },
  button: {
    '&.MuiButton-root': {
      height: '2.25rem',
      width: '7.875rem',
      padding: '0.625rem 1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      fontFamily: 'Inter-Regular',
      fontSize: '15px',
      '&:hover': {
        opacity: 1,
        color: Colors.primary,
        backgroundColor: 'rgba(69, 119, 250, 0.12)',
        ...GlobalStyles.lightBorder,
      },
      lineHeight: '18.15px',
      '&.MuiButton-outlined': {
        color: Colors.darkGray,
        ...GlobalStyles.lightBorder,
        '&:hover': {
          opacity: 1,

          color: Colors.primary,
          backgroundColor: 'rgba(69, 119, 250, 0.12)',
          ...GlobalStyles.lightBorder,
        },
      },
    },
  },
  activeButton: {
    '&.MuiButton-root': {
      '&.MuiButton-outlined': {
        color: Colors.primary,
        backgroundColor: 'rgba(69, 119, 250, 0.12)',
      },
    },
  },
  buttonText: {
    color: Colors.accent,
  },
  buttonsGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.625rem',
    [theme.breakpoints.down(760)]: {
      marginTop: '2rem',
      display: '-webkit-inline-box',
      overflow: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '2rem',
      display: '-webkit-inline-box',
      overflow: 'scroll',
      margin: '2rem -1rem 0',
      paddingLeft: '1rem',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  ratesBlock: {
    overflow: 'hidden',
    marginTop: '1.625rem',
    padding: '1.875rem 1.875rem 58px 1.875rem',
    backgroundColor: Colors.backgroundSecondary,
    ...GlobalStyles.borderRadius,
    [theme.breakpoints.down('xs')]: {
      margin: '1.625rem -2rem 0',
      borderRadius: 0,
    },
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'unset',
    gap: '1.25rem',
    [theme.breakpoints.down(1890)]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('xs')]: {
      gap: '46px',
    },
  },
  gridInfoItem: {
    maxWidth: '23.625rem',
    marginLeft: '1.375rem',
    [theme.breakpoints.down(1890)]: {
      marginLeft: 0,
      maxWidth: 'initial',
      width: '100%',
    },
  },
  bottomGrid: {
    display: 'flex',
    marginLeft: '22px',
    gap: '1.25rem',
    [theme.breakpoints.down(1890)]: {
      marginLeft: 0,
      flexWrap: 'unset',
    },
    [theme.breakpoints.down(900)]: {
      marginLeft: 0,
      flexWrap: 'wrap',
    },
  },
  content: {
    [theme.breakpoints.down(1890)]: {
      maxWidth: '28.75rem',
    },
  },
  smallGrid: {
    display: 'flex',
    gap: '1.25rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '16px',
    },
  },
  verySmallGrid: {
    display: 'flex',
    gap: '1.25rem',
    [theme.breakpoints.down(1890)]: {
      flexWrap: 'nowrap',
    },
    [theme.breakpoints.down(900)]: {
      flexWrap: 'wrap',
    },
  },
  list: {
    fontFamily: 'Inter-Regular',
    padding: '0 0 0 1rem',
    color: Colors.lightBlack,
    [theme.breakpoints.down('xs')]: {
      '& li': {
        fontSize: '0.6em',
        marginBottom: '4px',
        color: Colors.fontColor,
      },
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontFamily: 'Raleway-SemiBold',
      fontSize: '16px',
      lineHeight: '18.78px',
    },
  },
  subtitle: {
    fontFamily: 'Inter-Regular',
    color: Colors.newBlack,
    [theme.breakpoints.down('xs')]: {
      fontFamily: 'Raleway-Medium',
      color: Colors.fontColor,
    },
  },
  freeList: {
    marginTop: '1.5rem',
  },
}));

export type TRateButton = {
  month: number;
  title: string;
  percent?: string;
};

export const rateButtonsList: TRateButton[] = [
  {
    month: 0,
    title: 'Месяц',
  },
  {
    month: 1,
    title: '3 мес.',
    percent: '-5%',
  },
  {
    month: 2,
    title: '6 мес.',
    percent: '-15%',
  },
  {
    month: 3,
    title: 'За год',
    percent: '-29%',
  },
];

const subtitles: string[] = [
  'Защита без переноса сайта по методу Reverse-proxy',
  'Выделенный защищенный IP-адрес',
  'Защита от DDoS-атак до 3 Tbps/650 Mpps/10000000 RPS',
  'Уведомления о DDoS-атаках по email, telegram',
  'Без ограничений по типам и количеству DDoS-атак, трафику и посещаемости',
];

export const SitesRatesBlock: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeMonth, setActiveMonth] = useState<number>(1);
  const theme = useTheme();
  const isSwiperRendered = useMediaQuery(theme.breakpoints.down(1072));
  const isLg = useMediaQuery(theme.breakpoints.down(1890));

  const renderButton = (item: TRateButton, index: number) => {
    return (
      <Button
        className={`${classes.button} ${activeMonth === item.month && classes.activeButton}`}
        variant="outlined"
        key={index}
        onClick={() => setActiveMonth(item.month)}
      >
        {t(item.title)}
        {item.percent && (
          <Typography variant="h5" component="div" className={classes.buttonText}>
            {item.percent}
          </Typography>
        )}
      </Button>
    );
  };

  const renderSwiper = () => {
    if (isSwiperRendered) {
      return <SitesRatesSwiper activeMonth={activeMonth} />;
    }
    return sitesRatesConstants.map((card, index) => {
      return (
        <Grid item key={index}>
          <SitesRateCard item={card} activeMonth={activeMonth} />
        </Grid>
      );
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h2">{t('Тарифы')}</Typography>
        <div className={classes.buttonsGroup}>
          {rateButtonsList.map((item, index) => renderButton(item, index))}
        </div>
      </div>
      <div className={classes.ratesBlock}>
        <Fade>
          <Grid container className={classes.grid}>
            {renderSwiper()}
            <Grid container className={classes.bottomGrid}>
              <Grid item xs={12} md={isLg ? 4 : 12}>
                <Typography variant="h3" component="div" className={classes.title}>
                  {t('Во всех тарифах:')}
                </Typography>
                <ul className={classes.list}>
                  {subtitles.map((item, index) => {
                    return (
                      <li key={index}>
                        <Typography variant="h6" component="p" className={classes.subtitle}>
                          {t(item)}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </Grid>
              <Grid item xs={12} md={isLg ? 8 : 12}>
                <Grid container className={classes.smallGrid}>
                  <Grid item xs={12}>
                    <Typography variant="h3" component="div" className={classes.title}>
                      {t('Бесплатно в тарифах:')}
                    </Typography>
                  </Grid>
                  <Grid container className={classes.verySmallGrid}>
                    <Grid item xs={12} md={isLg ? 6 : 12}>
                      <RateCard
                        link={certificates}
                        subtitle="Управление черными/белыми списками
  Кеширование статического контента
  Оптимизация соединений и общее ускорение
  "
                      />
                    </Grid>
                    <Grid item sm={12} md={isLg ? 6 : 12}>
                      <RateCard
                        link={protectedDns}
                        subtitle="Поддержка SSL/TLS 1.2&1.3 шифрования Поддержка HTTP/2 и SPDY.
  Установка собственного сертификата. Бесплатный сертификат от Let`s Encrypt "
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </div>
    </div>
  );
};
