const Colors = {
  primary: '#4577FA',
  fontColor: '#000000',
  fontColorSecondary: '#4577FA',
  accent: '#FD9A3F',
  white: '#ffffff',
  error: '#FD4B3F',
  green: '#66D012',
  success: '#80d086',
  gray: '#D9D9D9',
  lightBlack: 'rgba(0, 0, 0, 0.75)',
  newBlack: 'rgba(0, 0, 0, 0.75)',
  darkGray: 'rgba(43, 43, 43, 0.55)',
  borderColor: 'rgba(43, 43, 43, 0.15)',
  background: '#F5F5F5',
  backgroundSecondary: 'rgba(43, 43, 43, 0.03)',
  linearGradient: 'linear-gradient(114.02deg, #131312 49.12%, #302F2E 82.45%)',
  grayLink: '#2F2F2F',
};

export { Colors };
