import React, { PropsWithChildren } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { Colors } from '../../../constants/Colors';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.375rem',
    color: Colors.newBlack,
    fontFamily: 'Raleway-Medium',
    textAlign: 'left',
    '&:hover': {
      color: Colors.primary,
    },
  },
  icon: {
    height: '1.5rem',
    width: '1.5rem',
    marginRight: '0.5rem',
    ...GlobalStyles.icon,
  },
});

export type FooterLinkProps = {
  title: string;
  url: string;
};

type TProps = {
  item: FooterLinkProps;
  onClick?: () => void;
};

export const FooterLink: React.FC<PropsWithChildren<TProps>> = (
  props: PropsWithChildren<TProps>,
) => {
  const { item, onClick } = props;
  const classes = useStyles();
  const history = useHistory();

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      className={classes.root}
      underline="none"
      component="button"
      variant="body2"
      onClick={() => {
        onClick?.();
        if (item && item.url) {
          history.push(item.url);
        }
      }}
    >
      {item.title}
    </Link>
  );
};
