import { THowPartnersWorksCardProps } from './HowPartnersWorksCard';

export const howPartnersWorksBlockConstants: THowPartnersWorksCardProps[] = [
  {
    id: 0,
    title: 'Привлекайте новых клиентов в Ddosa.net',
    subtitle:
      'Используйте индивидуальную реферальную ссылку для рекомендации нашего сервиса потенциальным клиентам.' +
      'Для этого вы можете использовать: профессиональный связи, медиаресурсы, социальные сети и другие каналы.',
  },
  {
    id: 1,
    title: 'Клиент оформляет любые услуги',
    subtitle:
      'Каждый потенциальный клиент посетивший наш сайт по вашей ссылке - будет является вашим рефералом, если ваш реферал сделает заказ услуг в течении 30 дней после посещения нашего сайта по вашей ссылке',
  },
  {
    id: 2,
    title: 'Вы получаетете до 50% от его оплат',
    subtitle: 'Вы получите 50% от каждого платежа за каждый период контракта клиента.',
  },
];
