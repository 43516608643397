import React from 'react';

// not current color
const HttpIcon: React.FC = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.78212 18.7942L3.2057 12L7.78212 5.20577L17.2179 5.20577L21.7943 12L17.2179 18.7942L7.78212 18.7942Z"
        fill="#0E5A9D"
        stroke="#208EC9"
        strokeWidth="2"
      />
    </svg>
  );
};

export default HttpIcon;
