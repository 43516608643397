import React from 'react';

const ChatIconSm: React.FC = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.78969 0.187516C9.76969 0.127516 11.6672 0.870016 13.0922 2.25752C14.5172 3.64502 15.3047 5.50502 15.3047 7.50002V13.5C15.3047 14.22 14.7122 14.8125 13.9922 14.8125H7.99969C6.00469 14.8125 4.14469 14.025 2.75719 12.6C1.36969 11.175 0.634688 9.28502 0.687188 7.29752C0.792188 3.48752 3.97969 0.300016 7.78969 0.195016V0.187516ZM3.55969 11.8125C4.73719 13.02 6.31219 13.6875 7.99219 13.6875H13.9997C14.1047 13.6875 14.1872 13.605 14.1872 13.5V7.50002C14.1872 5.81252 13.5197 4.23752 12.3122 3.06752C11.1497 1.93502 9.61969 1.31252 7.99969 1.31252C7.93969 1.31252 7.87969 1.31252 7.81969 1.31252C4.59469 1.40252 1.90219 4.09502 1.81219 7.32002C1.76719 9.00752 2.38969 10.605 3.55969 11.8125V11.8125Z"
        fill="currentColor"
      />
      <path
        d="M7.98438 6.9375H7.99188C8.29938 6.9375 8.54688 7.1925 8.54688 7.5C8.54688 7.8075 8.29187 8.0625 7.98438 8.0625C7.67688 8.0625 7.42188 7.8075 7.42188 7.5C7.42188 7.1925 7.67688 6.9375 7.98438 6.9375Z"
        fill="currentColor"
      />
      <path
        d="M10.9844 6.9375H10.9919C11.2994 6.9375 11.5469 7.1925 11.5469 7.5C11.5469 7.8075 11.2919 8.0625 10.9844 8.0625C10.6769 8.0625 10.4219 7.8075 10.4219 7.5C10.4219 7.1925 10.6769 6.9375 10.9844 6.9375Z"
        fill="currentColor"
      />
      <path
        d="M4.98438 6.9375C5.29187 6.9375 5.54688 7.1925 5.54688 7.5C5.54688 7.8075 5.29187 8.0625 4.98438 8.0625C4.67688 8.0625 4.42188 7.8075 4.42188 7.5C4.42188 7.1925 4.66938 6.9375 4.98438 6.9375Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChatIconSm;
