import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { CustomLink, CustomLinkProps } from '../Link/CustomLink';

const useStyles = makeStyles({
  root: {
    maxWidth: '22.75rem',
    marginBottom: '1.25rem',
    backgroundColor: Colors.white,
    padding: '1.875rem',
    display: 'flex',
    flexDirection: 'column',
    ...GlobalStyles.borderRadius,
    ...GlobalStyles.lightBorder,
    ...GlobalStyles.boxShadow,
  },
  subtitle: {
    marginTop: '1rem',
  },
});

export type TechnologyItemProps = {
  link: CustomLinkProps;
  subtitle: string;
};

type TProps = {
  item: TechnologyItemProps;
};

export const TechnologyItem: React.FC<TProps> = (props: TProps) => {
  const { item } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <CustomLink item={item.link} />
      <div className={classes.subtitle}>
        <Typography variant="body2" component="p">
          {t(item.subtitle)}
        </Typography>
      </div>
    </div>
  );
};
