import ProtectIcon from '../../../assets/icons/protect-port.svg';
import IndividualIcon from '../../../assets/icons/individual.svg';
import MedBoxIcon from '../../../assets/icons/med-box-icon.svg';
import { TPortsRateCard } from './PortsRateCard';

export const portsRatesConstants: TPortsRateCard[] = [
  {
    icon: MedBoxIcon,
    title: 'Стандарт',
    price: ['4853 ₽/мес', '13 000 ₽/мес', '12 000 ₽/мес', '11 000 ₽/мес'],
    middleRows: [
      {
        left: 'Трафик',
        right: 'до 30 mb/s',
      },
      {
        left: 'Защита портов',
        right: '2',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        left: 'Доступность SLA',
        right: 'от 98%',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        left: 'Тех. поддержка',
        right: '24/7',
      },
    ],
    footerRows: [
      {
        isDisabled: true,
        left: 'Точка фильтрации \n на выбор',
        right: '-',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        isDisabled: true,
        left: 'Создание GRE туннеля',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        isDisabled: true,
        left: 'Оплата на Р/С',
      },
    ],
  },
  {
    icon: ProtectIcon,
    title: 'Расширенный',
    oldPrice: '15 000 ₽',
    price: ['13 594 ₽/мес', '13 000 ₽/мес', '12 000 ₽/мес', '11 000 ₽/мес'],
    middleRows: [
      {
        left: 'Трафик',
        right: 'до 100 mb/s',
      },
      {
        left: 'Защита портов',
        right: '5',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        left: 'Доступность SLA',
        right: 'от 99%',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        left: 'Тех. поддержка',
        right: 'Приоритет',
      },
    ],
    footerRows: [
      {
        left: 'Точка фильтрации \n на выбор',
        right: 'RU / EU',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        isDisabled: true,
        left: 'Создание GRE туннеля',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        isActive: true,
        left: 'Оплата на Р/С',
      },
    ],
  },
  {
    icon: IndividualIcon,
    title: 'Индивидуальный',
    price: ['По договоренности', 'По договоренности', 'По договоренности', 'По договоренности'],
    middleRows: [
      {
        left: 'Трафик',
        right: 'до 10 gbps',
      },
      {
        left: 'Защита портов ',
        right: '100',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        left: 'Доступность SLA',
        right: 'от 99%',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        left: 'Тех. поддержка',
        right: 'Приоритет',
      },
    ],
    footerRows: [
      {
        left: 'Точка фильтрации \n на выбор',
        right: 'RU / EU',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        isActive: true,
        left: 'Создание GRE туннеля',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        isActive: true,
        left: 'Оплата на Р/С',
      },
    ],
  },
];
