import React from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-reveal';
import { useHistory } from 'react-router';
import { FeatureCard, FeatureCardProps } from '../../ui/Cards/FeatureCard';
import { featuresData, featuresDataOneColumn } from './AboutFeaturesConstants';
import { ProjectPath } from '../../../constants/ProjectPath';
import { Colors } from '../../../constants/Colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // height: '23.5rem',
  },
  featuresGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 'fit-content',
  },
  featuresContainer: {
    maxWidth: '700px',
    gap: '32px',
    [theme.breakpoints.down(1200)]: {
      maxWidth: 'initial',
      justifyContent: 'center',
      marginTop: '1.625rem',
    },
    [theme.breakpoints.down('xs')]: {
      gap: '10px',
      maxWidth: 'initial',
      justifyContent: 'center',
      marginTop: '1.625rem',
    },
  },
  lgGrid: {
    '@media (max-width: 771px)': {
      display: 'none',
    },
  },
  mdGrid: {
    '@media (min-width: 772px)': {
      display: 'none',
    },
  },
  gridItem: {
    height: 'fit-content',
  },
  title: {
    marginTop: '1.25rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0',
    },
  },
  text: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '0.6125rem',
    },
    marginTop: '1.25rem',
    color: Colors.lightBlack,
  },
  subtitle: {
    marginTop: '0.625rem',
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  button: {
    marginTop: '1.875rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '1.125rem',
      '&.MuiButton-root': {
        fontSize: '15px',
        lineHeight: '17.61px',
        fontFamily: 'Inter-Regular',
      },
    },
  },
}));

export const AboutFeaturesBlock: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const onAboutButtonClick = () => {
    history.push(ProjectPath.About);
  };

  const renderFeatures = (data: FeatureCardProps[]) => {
    return data.map((item, index) => {
      return (
        <Grid className={classes.gridItem} key={index} item>
          <Fade>
            <FeatureCard item={item} />
          </Fade>
        </Grid>
      );
    });
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item md={12} lg={3} xl={4}>
          <Typography variant="h2" component="div" className={classes.title}>
            {t('Наши ключевые особенности')}
          </Typography>
          <Fade>
            <Typography variant="h6" component="p" className={classes.text}>
              {t(
                'Мы создали сервис DDoSa.net, чтобы решить ваши проблемы с DDoSом раз и навсегда.\n\nНаши специалисты с опытом более 10 лет отбили уже тысячи DDoS-атак и ежедневно мы успешно продолжаем вести эту борьбу!\nВам не потребуется специальных знаний и опыта, вам нужно только принять решение, выбрать и оплатить наши услуги, или же воспользоваться бесплатным 24 часовым тестом.\n\nМы стремимся предложить альтернативу, сделать качественную защиту от DDoS-атак доступной для каждого!',
              )}
            </Typography>
            <div className={classes.buttonContainer}>
              <Button variant="outlined" className={classes.button} onClick={onAboutButtonClick}>
                {t('О компании')}
              </Button>
            </div>
          </Fade>
        </Grid>
        <Grid item md={10} lg={9} xl={8} className={classes.featuresGrid}>
          <Grid container className={`${classes.featuresContainer} ${classes.mdGrid}`}>
            {renderFeatures(featuresDataOneColumn)}
          </Grid>
          <Grid container className={`${classes.featuresContainer} ${classes.lgGrid}`}>
            {renderFeatures(featuresData)}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
