import React, { useState } from 'react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Fade } from 'react-reveal';
import 'swiper/swiper-bundle.css';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SwiperClass from 'swiper/types/swiper-class';
import { PartnerCard } from '../../ui/Cards/PartnerCard';
import { partnersData } from './PartnersBlockConstants';
import { LAYOUT_LEFT_RIGHT_PADDING_XL } from '../../../constants/UI';
import { Colors } from '../../../constants/Colors';
import { SwiperButtonsContainer } from '../../ui/Buttons/SwiperButtonsContainer';
import FirstPartnersSlide from '../../ui/Cards/Partners/FirstPartnersSlide';
import SecondPartnersSlide from '../../ui/Cards/Partners/SecondPartnersSlide';
import ThirdPartnersSlide from '../../ui/Cards/Partners/ThirdPartnersSlide';

SwiperCore.use([Navigation, Autoplay, Pagination]);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    container: {
      margin: `1.625rem -${LAYOUT_LEFT_RIGHT_PADDING_XL} 0`,
      [theme.breakpoints.down('sm')]: {
        backgroundColor: Colors.backgroundSecondary,
      },
    },
    card: {
      height: '13.125rem',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    subtitle: {
      marginTop: '5px',
      color: Colors.lightBlack,
    },
    companiesScroll: {
      backgroundColor: Colors.backgroundSecondary,
      '& .swiper-wrapper': {
        boxSizing: 'inherit',
        '-webkit-transition-timing-function': 'linear!important',
        '-o-transition-timing-function': 'linear!important',
        'transition-timing-function': 'linear!important',
        '& .swiper-slide': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
    mobileCarousel: {
      '& .swiper-slide': {
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '3.5rem',
      },
      '& .swiper-wrapper': {
        boxSizing: 'inherit',
      },
      '& .swiper-pagination': {
        left: 0,
        bottom: '26px',
        backgroundColor: 'transparent',
      },
      '& .swiper-pagination-bullet': {
        height: '0.75rem',
        width: '0.75rem',
        backgroundColor: Colors.borderColor,
        opacity: 0.3,
      },
      '& .swiper-pagination-bullet-active': {
        height: '0.75rem',
        width: '0.75rem',
        backgroundColor: Colors.borderColor,
        opacity: 1,
      },
    },
    desktop: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    mobile: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

export const PartnersBlock: React.FC = () => {
  const classes = useStyles();
  const autoplaySpeed = 5000;
  const navigationSpeed = 300;
  const [delay, setDelay] = useState<number>(0);
  const [speed, setSpeed] = useState<number>(autoplaySpeed);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const { t } = useTranslation();

  const prevButtonClass = 'partners-prev-button';
  const nextButtonClass = 'partners-next-button';

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          <Typography variant="h2" component="div">
            {t('Партнеры')}
          </Typography>
          <Typography variant="h6" component="p" className={classes.subtitle}>
            {t('Наши клиенты о нас говорят, нас советуют и работают с нами годами')}
          </Typography>
        </div>
        <SwiperButtonsContainer
          prevButtonClass={prevButtonClass}
          nextButtonClass={nextButtonClass}
          onNextClick={() => {
            setSpeed(navigationSpeed);
            if (swiper) {
              swiper.slideNext(navigationSpeed);
            }
          }}
          onPrevClick={() => {
            setSpeed(navigationSpeed);
            if (swiper) {
              swiper.slidePrev(navigationSpeed);
            }
          }}
        />
      </div>
      <Fade>
        <div className={classes.desktop}>
          <div className={classes.container}>
            <Swiper
              autoplay={{
                delay,
                disableOnInteraction: true,
              }}
              navigation={{
                prevEl: `.${prevButtonClass}`,
                nextEl: `.${nextButtonClass}`,
              }}
              breakpoints={{
                // when window width is >= 640px
                420: {
                  slidesPerView: 1,
                },
                700: {
                  slidesPerView: 4,
                },
                1000: {
                  slidesPerView: 6,
                },
                // when window width is >= 768px
                1300: {
                  slidesPerView: 8,
                },
              }}
              loopPreventsSlide={false}
              speed={speed}
              freeMode
              direction="horizontal"
              loop
              className={classes.companiesScroll}
              onSwiper={setSwiper}
            >
              {partnersData.map((partner, index) => (
                // eslint-disable-next-line no-underscore-dangle
                <SwiperSlide className={classes.card} key={index}>
                  <PartnerCard item={partner} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </Fade>
      <Fade>
        <div className={classes.mobile}>
          <div className={classes.container}>
            <Swiper
              slidesPerView={1}
              direction="horizontal"
              className={classes.mobileCarousel}
              navigation={{
                prevEl: `.${prevButtonClass}`,
                nextEl: `.${nextButtonClass}`,
              }}
              pagination={{ clickable: true }}
            >
              <SwiperSlide>
                <FirstPartnersSlide />
              </SwiperSlide>
              <SwiperSlide>
                <SecondPartnersSlide />
              </SwiperSlide>
              <SwiperSlide>
                <ThirdPartnersSlide />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </Fade>
    </div>
  );
};
