import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { sitesRatesConstants } from './SitesRatesConstants';
import { SitesRateCard } from './SitesRateCard';

SwiperCore.use([Navigation, Pagination]);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 -3.875rem',
      overflow: 'hidden',
    },
    swiper: {
      margin: '0 4.875rem',
      minHeight: '353px',
      overflow: 'initial',
      ...GlobalStyles.pagination,
    },
    sliderContainer: {
      height: 'auto',
    },
    slide: {
      height: '100%',
      padding: '0 0 3.5rem 0',
      [theme.breakpoints.down('xs')]: {
        padding: '0 0 2.5rem 0',
      },
    },
  }),
);

type TProps = {
  activeMonth: number;
};

export const SitesRatesSwiper: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { activeMonth } = props;
  const renderSlides = () => {
    return sitesRatesConstants.map((val, index) => {
      return (
        <SwiperSlide key={index} className={classes.sliderContainer}>
          <div className={classes.slide}>
            <SitesRateCard item={val} activeMonth={activeMonth} />
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className={classes.root}>
      <Swiper
        className={classes.swiper}
        spaceBetween={20}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          720: {
            slidesPerView: 2,
          },
        }}
        direction="horizontal"
        pagination={{ clickable: true }}
      >
        {renderSlides()}
      </Swiper>
    </div>
  );
};
