import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import VkIcon from '../../../assets/icons/vk-icon.svg';
import TelegramIcon from '../../../assets/icons/telegram-icon.svg';
import VcIcon from '../../../assets/icons/vc-icon.svg';
import { GlobalStyles } from '../../../styles/GlobalStyles';

const useStyles = makeStyles({
  socialIcon: {
    height: '2.25rem',
    width: '2.25rem',
    ...GlobalStyles.icon,
  },
});

export const Socials: React.FC = () => {
  const classes = useStyles();
  const onVkButtonClick = () => {};
  const onTelegramButtonClick = () => {};
  const onVcButtonClick = () => {};

  return (
    <>
      <IconButton
        className={classes.socialIcon}
        style={{ backgroundImage: `url(${VkIcon})` }}
        onClick={onVkButtonClick}
      />
      <IconButton
        className={classes.socialIcon}
        style={{ backgroundImage: `url(${TelegramIcon})` }}
        onClick={onTelegramButtonClick}
      />
      <IconButton
        className={classes.socialIcon}
        style={{ backgroundImage: `url(${VcIcon})` }}
        onClick={onVcButtonClick}
      />
    </>
  );
};
