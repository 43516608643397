import React from 'react';

const PhoneIcon: React.FC = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3271 16.25C16.9171 16.25 16.5771 16.59 16.5771 17V18C16.5771 19.24 15.5671 20.25 14.3271 20.25H10.3271C9.08715 20.25 8.07715 19.24 8.07715 18V6C8.07715 4.76 9.08715 3.75 10.3271 3.75H14.3271C15.5671 3.75 16.5771 4.76 16.5771 6C16.5771 6.41 16.9171 6.75 17.3271 6.75C17.7371 6.75 18.0771 6.41 18.0771 6C18.0771 3.93 16.3971 2.25 14.3271 2.25H10.3271C8.25715 2.25 6.57715 3.93 6.57715 6V18C6.57715 20.07 8.25715 21.75 10.3271 21.75H14.3271C16.3971 21.75 18.0771 20.07 18.0771 18V17C18.0771 16.59 17.7371 16.25 17.3271 16.25Z"
        fill="currentColor"
      />
      <path
        d="M10.5771 18C10.5771 18.41 10.9171 18.75 11.3271 18.75H13.3271C13.7371 18.75 14.0771 18.41 14.0771 18C14.0771 17.59 13.7371 17.25 13.3271 17.25H11.3271C10.9171 17.25 10.5771 17.59 10.5771 18Z"
        fill="currentColor"
      />
      <path
        d="M12.6167 11.8305C12.8377 11.6764 13.116 11.6115 13.3779 11.652L14.6875 11.8629C15.0394 11.9197 15.4077 11.8305 15.7024 11.6277C15.9971 11.4168 16.1935 11.1085 16.2508 10.7516L16.4636 9.45373C16.5045 9.18603 16.66 8.95079 16.8728 8.79666L17.7322 8.18827C18.1906 7.8638 18.829 7.96925 19.1646 8.42352L19.6884 9.14547C20.7852 10.6543 20.4332 12.7634 18.9109 13.8504L16.791 15.3673C16.2099 15.781 15.5223 16 14.8102 16C14.622 16 14.4419 15.9838 14.2537 15.9513C13.3615 15.8053 12.5758 15.3267 12.0438 14.5966L11.5199 13.8747C11.1925 13.4204 11.2908 12.7877 11.7491 12.4632L12.6085 11.8467L12.6167 11.8305ZM13.0423 13.8666C13.3779 14.3371 13.8854 14.6453 14.4583 14.7345C15.0231 14.8238 15.6042 14.694 16.0789 14.3614L18.1988 12.8526C19.1728 12.155 19.3938 10.8084 18.6899 9.8431L18.2888 9.28338L17.6668 9.72953L17.4621 10.9463C17.3557 11.6196 16.9792 12.2118 16.4227 12.6174C15.8661 13.0148 15.1704 13.1771 14.491 13.0635L13.2633 12.8688L12.6413 13.315L13.0423 13.8747V13.8666Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PhoneIcon;
