import React from 'react';

const ClockIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.25C4.62 0.25 0.25 4.62 0.25 10C0.25 15.38 4.62 19.75 10 19.75C15.38 19.75 19.75 15.38 19.75 10C19.75 4.62 15.38 0.25 10 0.25ZM10.75 18.21V17C10.75 16.59 10.41 16.25 10 16.25C9.59 16.25 9.25 16.59 9.25 17V18.21C5.3 17.85 2.15 14.7 1.79 10.75H3C3.41 10.75 3.75 10.41 3.75 10C3.75 9.59 3.41 9.25 3 9.25H1.79C2.15 5.3 5.3 2.15 9.25 1.79V3C9.25 3.41 9.59 3.75 10 3.75C10.41 3.75 10.75 3.41 10.75 3V1.79C14.7 2.15 17.85 5.3 18.21 9.25H17C16.59 9.25 16.25 9.59 16.25 10C16.25 10.41 16.59 10.75 17 10.75H18.21C17.85 14.7 14.7 17.85 10.75 18.21Z"
        fill="currentColor"
      />
      <path
        d="M9.99945 6.25C9.58945 6.25 9.24945 6.59 9.24945 7V9.69L6.46945 12.47C6.17945 12.76 6.17945 13.24 6.46945 13.53C6.61945 13.68 6.80945 13.75 6.99945 13.75C7.18945 13.75 7.37945 13.68 7.52945 13.53L10.5295 10.53C10.6695 10.39 10.7495 10.2 10.7495 10V7C10.7495 6.59 10.4095 6.25 9.99945 6.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ClockIcon;
