import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { CustomLink, CustomLinkProps } from '../Link/CustomLink';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    // overflow: 'hidden',
    position: 'relative',
    backgroundColor: 'rgba(69, 119, 250, 0.12)',
    animation: '1.25s linear 0.2s left-right-background',
    ...GlobalStyles.borderRadius,
    [theme.breakpoints.down(750)]: {
      height: 'auto !important',
    },
  },
  inactive: {
    padding: '2.5rem 0 2rem 3rem',
    width: '50%',
    [theme.breakpoints.down(750)]: {
      padding: '1.25rem 0 1.25rem 1.25rem',
      width: '100%',
      height: '50%',
    },
  },
  active: {
    width: '50%',
    zIndex: 1,
    padding: '2.5rem',
    top: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    animation: '1.25s linear 0.2s left-right-slide',
    backgroundColor: Colors.white,
    ...GlobalStyles.border,
    ...GlobalStyles.borderRadius,
    ...GlobalStyles.boxShadow,
    [theme.breakpoints.down(750)]: {
      padding: '1.25rem',
      top: 'auto',
      width: '100%',
      height: '50%',
      animation: '0.75s linear 0s top-bottom-slide',
    },
  },
  title: {
    marginBottom: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '18.78px',
    },
  },
  link: {
    marginBottom: '0.5rem',
  },
}));

export type TSwitcherProps = {
  activeLinks: CustomLinkProps[];
  inactiveLinks: CustomLinkProps[];
  inactiveTitle: string;
};

type TProps = {
  isReversed?: boolean;
  data: TSwitcherProps;
};

export const Switcher: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { data, isReversed } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root} style={{ height: isReversed ? '240px' : '300px' }}>
      <div className={classes.active}>
        <Typography variant="h3" component="div" className={classes.title}>
          {t('Собственные технологии')}
        </Typography>
        {data.activeLinks.map((item, index) => {
          return (
            <div key={index} className={classes.link}>
              <CustomLink isLg item={item} textColor={Colors.fontColor} />
            </div>
          );
        })}
      </div>
      <div className={classes.inactive}>
        <Typography variant="h3" component="div" className={classes.title}>
          {t(data.inactiveTitle)}:
        </Typography>
        {data.inactiveLinks.map((item, index) => {
          return (
            <div key={index} className={classes.link}>
              <CustomLink isLg item={item} textColor={Colors.fontColor} />
            </div>
          );
        })}
      </div>
      <div className={classes.inactive} style={{ opacity: 0 }}>
        <Typography variant="h3" component="div" className={classes.title}>
          {t(data.inactiveTitle)}:
        </Typography>
        {data.activeLinks.map((item, index) => {
          return (
            <div key={index} className={classes.link}>
              <CustomLink isLg item={item} textColor={Colors.fontColor} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
