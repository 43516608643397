import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/ui/Layout/Layout';
import { Banner } from '../../components/ui/Banners/Banner';
import { PageDetails, PageDetailsProps } from '../../components/ui/Details/PageDetails';
import DnsBanner from '../../assets/backgrounds/dns-banner.jpg';
import DnsXsBanner from '../../assets/backgrounds/tsp-xs-banner.png';
import { Colors } from '../../constants/Colors';
import { redirectToProject } from '../../utils/redirectToProject';

const useStyles = makeStyles({
  root: {},
  textButton: {
    padding: '0 !important',
    color: Colors.darkGray,
    backgroundColor: 'transparent !important',
    fontFamily: 'Raleway-Medium',
  },
});

const protectedDnsPageData: PageDetailsProps = {
  title: 'Первый уровень безопасности',
  subtitle:
    'Безопасность обеспечивается множеством компонентов и технологий, каждый из которых играет важную роль\n',
  descriptionOne:
    '\n' +
    'DNS (англ. Domain Name System «система доменных имён») — компьютерная распределённая система для получения информации о доменах. Чаще всего используется для получения IP-адреса по имени хоста (компьютера или устройства), получения информации о маршрутизации почты и/или обслуживающих узлах для протоколов в домене (SRV-запись).\n' +
    '\n' +
    'Распределённая база данных DNS поддерживается с помощью иерархии DNS-серверов, взаимодействующих по определённому протоколу.\n',
  descriptionTwo:
    'Основой DNS является представление об иерархической структуре имени и зонах. Каждый сервер, отвечающий за имя, может передать ответственность за дальнейшую часть домена другому серверу (с административной точки зрения — другой организации или человеку), что позволяет возложить ответственность за актуальность информации на серверы различных организаций (людей), отвечающих только за «свою» часть доменного имени.\n' +
    '\n' +
    'Начиная с 2010 года в систему DNS внедряются средства проверки целостности передаваемых данных, называемые DNS Security Extensions (DNSSEC). Передаваемые данные не шифруются, но их достоверность проверяется криптографическими способами. Внедряемый стандарт DANE обеспечивает передачу средствами DNS достоверной криптографической информации (сертификатов), используемых для установления безопасных и защищённых соединений транспортного и прикладного уровней.\n',
};

const ProtectedDnsPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onConnectButtonClick = () => {
    redirectToProject();
  };

  return (
    <Layout>
      <Banner
        title="Защищенный DNS"
        subtitle="Безопасность обеспечивается множеством компонентов и технологий, каждый из которых играет важную роль"
        background={DnsBanner}
        xsBackground={DnsXsBanner}
        leftButton={<Button onClick={onConnectButtonClick}>{t('Подключиться')}</Button>}
        rightButton={
          <Typography variant="h6" component="p" className={classes.textButton}>
            {t('Бесплатно')}
          </Typography>
        }
      />
      <PageDetails item={protectedDnsPageData} isDns />
    </Layout>
  );
};

export default withRouter(ProtectedDnsPage);
