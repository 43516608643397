import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Fade } from 'react-reveal';
import { Layout } from '../../components/ui/Layout/Layout';
import { NotFoundBanner } from '../../components/Error/NotFoundBanner';
import AnotherErrorBox from '../../assets/errors/another.png';

const AnotherErrorPage: React.FC<RouteComponentProps> = () => {
  return (
    <Layout>
      <Fade>
        <NotFoundBanner
          title="Ошибка ......"
          subtitle="Ошибка. Попробуйте перезагрузить страницу или зайти позже"
          buttonText="На главную"
          img={AnotherErrorBox}
          imgXs={AnotherErrorBox}
        />
      </Fade>
    </Layout>
  );
};

export default withRouter(AnotherErrorPage);
