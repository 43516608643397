import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LinuxOne from '../../../assets/backgrounds/knowledge-linux-one.png';
import LinuxTwo from '../../../assets/backgrounds/knowledge-linux-two.png';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const LinkContent: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={10} className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h2">
          {t('Oracle Linux (прежде известная как Oracle Enterprise Linux, Unbreakable Linux)')}
        </Typography>
        <Typography>
          {t(
            'Oracle Linux (прежде известная как Oracle Enterprise Linux, Unbreakable Linux) — открытый дистрибутив операционной системы Linux, доступный под лицензией GNU General Public License и свободный для скачивания через сайт корпорации Oracle (после регистрации в Oracle Software Delivery Cloud) или через общественный репозиторий Oracle (Oracle Linux Yum Server)[2]. Oracle также предоставляет услуги по платной технической поддержке организаций, использующих дистрибутив. Дополнительно, компанией Oracle предложен[3] и подготовлен скрипт[4] перехода на дистрибутив Oracle Linux для всех пользователей CentOS.\n' +
              '\n' +
              'Создан 25 октября 2006 года на основе Red Hat Enterprise Linux[5][6], появление дистрибутива серьёзно повлияло на курс акций Red Hat, который снизился на 28 % и составил 14,25 доллара США за акцию[7][8], так как фактически предлагая копию дистрибутива от Red Hat, Oracle устанавливает стоимость услуг по технической поддержке на Oracle Linux вдвое ниже (от $99 в расчёте на систему в год до $1199 за пакет расширенных услуг, включающий юридическую защиту пользователей Linux)[9].\n' +
              '\n' +
              '22 марта 2012 года корпорация Oracle объявила о том, что дистрибутивы, обновления и Errata для Oracle Linux становятся доступными бесплатно, в том числе для коммерческого использования[10] (до этого, как и у Red Hat, обновления и исправления были доступны только подписчикам техподдержки).',
          )}
        </Typography>
      </Grid>
      <Grid item>
        <img src={LinuxOne} alt="" />
      </Grid>
      <Grid item>
        <img src={LinuxTwo} alt="" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" component="p">
          {t(
            'История выпусков\n' +
              '\n' +
              'Oracle Linux 9\n' +
              'Oracle Linux 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6\n' +
              'Oracle Linux 7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9\n' +
              'Oracle Linux 6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 6.10\n' +
              'Oracle Linux 5, 5.1, 5.2, 5.3, 5.4, 5.5, 5.6, 5.7, 5.8, 5.9, 5.10, 5.11\n' +
              'Oracle Enterprise Linux 4.4, 4.5, 4.6, 4.7, 4.8, 4.9\n',
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};
