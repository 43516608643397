import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { NewsCard } from '../../ui/Cards/NewsCard';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { newsConstants } from './NewsConstants';

SwiperCore.use([Navigation, Pagination]);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 -1rem 0 0',
      overflow: 'hidden',
    },
    swiper: {
      minHeight: '353px',
      overflow: 'initial',
      margin: '0 1rem 0 0',
      ...GlobalStyles.pagination,
      [theme.breakpoints.down('xs')]: {
        minHeight: 'auto',
      },
    },
    sliderContainer: {
      height: 'auto',
    },
    slide: {
      height: '100%',
      padding: '0 0 3.5rem 0',
      [theme.breakpoints.down('xs')]: {
        padding: '0 0 44px 0',
      },
    },
  }),
);

type TProps = {
  prevButtonClass: string;
  nextButtonClass: string;
};

export const NewsSwiper: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { prevButtonClass, nextButtonClass } = props;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const renderSlides = () => {
    return newsConstants.map((val, index) => {
      return (
        <SwiperSlide key={index} className={classes.sliderContainer}>
          <div className={classes.slide}>
            <NewsCard item={val} />
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className={classes.root}>
      <Swiper
        className={classes.swiper}
        spaceBetween={isXs ? 10 : 20}
        navigation={{
          prevEl: `.${prevButtonClass}`,
          nextEl: `.${nextButtonClass}`,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          900: {
            slidesPerView: 2,
          },
          1400: {
            slidesPerView: 3,
          },
        }}
        direction="horizontal"
        pagination={{ clickable: true }}
      >
        {renderSlides()}
      </Swiper>
    </div>
  );
};
