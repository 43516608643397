import React, { ReactNode } from 'react';
import { makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import {
  LAYOUT_LEFT_RIGHT_CONTENT_PADDING_LG,
  LAYOUT_LEFT_RIGHT_CONTENT_PADDING_MD,
  LAYOUT_LEFT_RIGHT_CONTENT_PADDING_XL,
} from '../../../constants/UI';
import { Colors } from '../../../constants/Colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
    margin: `0 -${LAYOUT_LEFT_RIGHT_CONTENT_PADDING_XL}`,
    height: '23.5rem',
    backgroundColor: Colors.backgroundSecondary,
    position: 'relative',
    ...GlobalStyles.borderRadius,
    ...GlobalStyles.lightBorder,
    [theme.breakpoints.down('lg')]: {
      margin: `0 -${LAYOUT_LEFT_RIGHT_CONTENT_PADDING_LG}`,
    },
    [theme.breakpoints.down('md')]: {
      margin: `0 -${LAYOUT_LEFT_RIGHT_CONTENT_PADDING_MD}`,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      margin: '0 -1rem',
    },
  },
  content: {
    position: 'relative',
    maxWidth: '45rem',
    padding: '6.375rem 0 9.25rem 8.125rem',
    [theme.breakpoints.down('lg')]: {
      padding: '6.375rem 0 9.25rem 4.125rem',
    },
    [theme.breakpoints.down('md')]: {
      padding: '6.375rem 0 9.25rem 2.125rem',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '1rem 2.125rem 1rem 2.125rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '22px 16px 19px 16px',
    },
  },
  subtitle: {
    color: Colors.newBlack,
    margin: '0.625rem 0 26px',
  },
  maxWidthSubtitle: {
    color: Colors.newBlack,
    margin: '0.625rem 0 26px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '213px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    animation: 'banner 1s linear',
    width: '2301px',
    right: '-18%',
    bottom: '-355px',
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      right: '-34%',
    },
    [theme.breakpoints.down('sm')]: {
      right: '-78%',
    },
    [theme.breakpoints.down('xs')]: {
      animation: 'none',
      width: 'auto',
      right: 0,
      bottom: 0,
    },
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  leftButton: {
    marginRight: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      marginRight: '11px',
    },
  },
}));

type TProps = {
  title: string;
  subtitle: string;
  background: string;
  maxWidth?: boolean;
  xsBackground?: string;
  leftButton: ReactNode;
  rightButton: ReactNode;
};

export const Banner: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { title, subtitle, background, leftButton, rightButton, xsBackground, maxWidth } = props;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className={classes.root}>
      <img className={classes.image} src={isXs ? xsBackground : background} alt="" />
      <div className={classes.content}>
        <div>
          <Typography variant="h1">{t(title)}</Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={`${maxWidth ? classes.maxWidthSubtitle : classes.subtitle}`}
          >
            {t(subtitle)}
          </Typography>
        </div>

        <div className={classes.buttonsContainer}>
          <div className={classes.leftButton}>{leftButton}</div>
          {rightButton}
        </div>
      </div>
    </div>
  );
};
