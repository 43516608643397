import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { makeStyles, Theme } from '@material-ui/core';
import { Fade } from 'react-reveal';
import { Layout } from '../../components/ui/Layout/Layout';
import { MissionBlock } from '../../components/About/MissionBlock';
import { CompanyInNumbersBlock } from '../../components/About/CompanyBlock/CompanyInNumbersBlock';
import { TechnologiesSwiper } from '../../components/About/TechnologiesBlock/TechnologiesSwiper';
import { ContactsBlock } from '../../components/About/ContactsBlock/ContactsBlock';
import { HistorySwiper } from '../../components/About/HistoryBlock/HistorySwiper';
import { AboutBanner } from '../../components/ui/Banners/AboutBanner';

const useStyles = makeStyles((theme: Theme) => ({
  missionContainer: {
    marginTop: '6.25rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '50px',
    },
  },
  historyContainer: {
    marginTop: '8.125rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '50px',
    },
  },
  companyInNumbersContainer: {
    marginTop: '5.875rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '106px',
    },
  },
  technologiesContainer: {
    marginTop: '8.125rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '60px',
    },
  },
  contactsContainer: {
    marginTop: '9.5rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '70px',
    },
  },
}));

const AboutPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Fade>
        <AboutBanner />
      </Fade>
      <div className={classes.missionContainer}>
        <MissionBlock />
      </div>
      <div className={classes.historyContainer}>
        <HistorySwiper />
      </div>
      <div className={classes.companyInNumbersContainer}>
        <CompanyInNumbersBlock />
      </div>
      <div className={classes.technologiesContainer}>
        <TechnologiesSwiper />
      </div>
      <div className={classes.contactsContainer}>
        <ContactsBlock />
      </div>
    </Layout>
  );
};

export default withRouter(AboutPage);
