import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Fade } from 'react-reveal';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/ui/Layout/Layout';
import { UpperNewsBlock } from '../../components/NewsAndStocks/UpperNewsBlock';
import { DownNewsBlock } from '../../components/NewsAndStocks/DownNewsBlock';
import { StocksBlock } from '../../components/NewsAndStocks/StocksBlock';
import {
  LAYOUT_LEFT_RIGHT_PADDING_LG,
  LAYOUT_LEFT_RIGHT_PADDING_MD,
  LAYOUT_LEFT_RIGHT_PADDING_XL,
  LAYOUT_LEFT_RIGHT_PADDING_XS,
} from '../../constants/UI';
import { Colors } from '../../constants/Colors';
import { GlobalStyles } from '../../styles/GlobalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: '3.75rem',
    margin: `0 -${LAYOUT_LEFT_RIGHT_PADDING_XL}`,
    padding: `0.625rem ${LAYOUT_LEFT_RIGHT_PADDING_XL}`,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(69, 119, 250, 0.12)',
    ...GlobalStyles.lightBorder,
    [theme.breakpoints.down('lg')]: {
      margin: `0 -${LAYOUT_LEFT_RIGHT_PADDING_LG}`,
      padding: `0.625rem ${LAYOUT_LEFT_RIGHT_PADDING_LG}`,
    },
    [theme.breakpoints.down('md')]: {
      margin: `0 -${LAYOUT_LEFT_RIGHT_PADDING_MD}`,
      padding: `0.625rem ${LAYOUT_LEFT_RIGHT_PADDING_MD}`,
    },
    [theme.breakpoints.down('xs')]: {
      margin: `0 -${LAYOUT_LEFT_RIGHT_PADDING_XS}`,
      padding: `0.625rem ${LAYOUT_LEFT_RIGHT_PADDING_XS}`,
    },
  },
  line: {
    width: '1px',
    height: '1.125rem',
    backgroundColor: Colors.borderColor,
    margin: '0 1.5rem',
  },
  title: {
    fontFamily: 'Raleway-SemiBold',
    fontSize: '26px',
    lineHeight: '39px',
  },
  upperNewsBlock: {
    marginTop: '3.75rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '30px',
    },
  },
  stocksBlock: {
    marginTop: '3.75rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '30px',
    },
  },
}));

const NewsAndStocksPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Layout>
      <div className={classes.header}>
        <Typography variant="h2" className={classes.title}>
          {t('Новости')}
        </Typography>
        <div className={classes.line} />
        <Typography variant="h2" className={classes.title}>
          {t('Акции')}
        </Typography>
      </div>
      <div className={classes.upperNewsBlock}>
        <Fade>
          <UpperNewsBlock />
        </Fade>
      </div>
      <div className={classes.stocksBlock}>
        <StocksBlock isReversed />
      </div>
      <Fade>
        <DownNewsBlock />
      </Fade>
    </Layout>
  );
};

export default withRouter(NewsAndStocksPage);
