import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../constants/Colors';
import { CompanyLogo } from '../../Logo/CompanyLogo';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      color: Colors.newBlack,
    },
    [theme.breakpoints.down('md')]: {
      marginRight: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  companyLogo: {
    marginRight: '1.125rem',
  },
  text: {
    maxWidth: '289px',
  },
}));

export const CompanyContacts: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.companyLogo}>
        <CompanyLogo />
      </div>
      <Typography variant="body2" component="p" className={classes.text}>
        {t('Эффективная облачная защита от DDoS, взлома и ботов')}
      </Typography>
    </div>
  );
};
