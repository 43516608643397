import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    backgroundColor: Colors.white,
    ...GlobalStyles.borderRadius,
    ...GlobalStyles.boxShadow,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  image: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '178px',
      width: 'fit-content',
    },
  },
  content: {
    padding: '1.875rem',
  },
  title: {
    fontFamily: 'Raleway-Bold',
    fontSize: '32px',
    lineHeight: '100%',

    color: Colors.primary,
    [theme.breakpoints.down('xs')]: {
      fontFamily: 'Raleway-SemiBold',
      fontSize: '19px',
      lineHeight: '23.75px',
    },
  },
  subtitle: {
    marginTop: '0.625rem',
    color: Colors.lightBlack,
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      lineHeight: '16.44px',
    },
  },
}));

export type TProfitCardProps = {
  img: string;
  title: string;
  subtitle: string;
};

type TProps = {
  item: TProfitCardProps;
};

export const ProfitCard: React.FC<TProps> = (props: TProps) => {
  const { item } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <img alt="" src={item.img} className={classes.image} />
      <div className={classes.content}>
        <Typography className={classes.title}>{t(item.title)}</Typography>
        <Typography variant="h6" component="p" className={classes.subtitle}>
          {t(item.subtitle)}
        </Typography>
      </div>
    </div>
  );
};
