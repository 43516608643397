import React from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { GlobalStyles } from '../../../../styles/GlobalStyles';
import { Colors } from '../../../../constants/Colors';
import { CustomLink, CustomLinkProps } from '../../Link/CustomLink';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    backgroundColor: Colors.backgroundSecondary,
    padding: '1.875rem 1.875rem 2.3rem 1.875rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...GlobalStyles.borderRadius,
    ...GlobalStyles.lightBorder,
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      marginRight: '0',
      padding: '30px 27px 25px 30px',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover button.MuiButton-contained': {
      color: Colors.white,
      opacity: 1,
      backgroundColor: Colors.primary,
    },
  },
  header: {
    display: 'flex',
    marginBottom: '0.875rem',
  },
  icon: {
    height: '1.5rem',
    width: '1.5rem',
    marginRight: '0.75rem',
    ...GlobalStyles.icon,
  },
  content: {
    minHeight: '8rem',
    '& > p': {
      color: Colors.lightBlack,
    },
  },
  links: {
    alignItems: 'end',
  },
  link: {
    marginTop: '0.375rem',
  },
  button: {
    position: 'absolute',
    bottom: '-16px',
    right: 0,

    [theme.breakpoints.down(600)]: {
      position: 'initial',
      // width: '100% !important',
      marginTop: '15px',
    },
  },
  buttonContainer: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}));

type TProps = {
  title: string;
  subtitle: string;
  icon: string;
  links: CustomLinkProps[];
  url: string;
  onClose: () => void;
};

export const ServiceCard: React.FC<TProps> = (props: TProps) => {
  const { title, icon, links, url, subtitle, onClose } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobileLink = useMediaQuery(theme.breakpoints.down(850));

  const onButtonClick = () => {
    onClose();
    // history.push(url);
  };

  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <div className={classes.root} onClick={onButtonClick} onKeyDown={onButtonClick}>
      <div>
        <div className={classes.header}>
          <div className={classes.icon} style={{ backgroundImage: `url(${icon})` }} />
          <Typography variant="h3" component="div">
            {t(title)}
          </Typography>
        </div>
      </div>
      <Grid container className={classes.links}>
        <Grid item xs={12} sm={mobileLink ? 12 : 9}>
          {links.map((item, index) => {
            return (
              <div key={index} className={classes.link}>
                <CustomLink item={item} textColor={Colors.fontColor} />
              </div>
            );
          })}
        </Grid>
        <Grid item xs={12} sm={mobileLink ? 12 : 3} className={classes.buttonContainer}>
          <Button variant="contained" className={classes.button} onClick={onButtonClick}>
            {t('Выбрать')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
