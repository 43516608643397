import { TRateCard } from './SitesRateCard';
import StartUpIcon from '../../../assets/icons/start-up.svg';
import BusinessIcon from '../../../assets/icons/business.svg';
import IndividualIcon from '../../../assets/icons/individual.svg';
import { TFreeCardProps } from './SitesFreeCard';
import RocketIcon from '../../ui/Icons/RocketIcon';
import { ProjectPath } from '../../../constants/ProjectPath';
import KeyIcon from '../../ui/Icons/KeyIcon';
import DiamondIcon from '../../ui/Icons/DiamondIcon';

export const sitesRatesConstants: TRateCard[] = [
  {
    icon: StartUpIcon,
    title: 'StartUP',
    price: ['4853 ₽/мес', '13 000 ₽/мес', '12 000 ₽/мес', '11 000 ₽/мес'],
    oldPrice: '5000 ₽',
    subtitle:
      'Идеальное решение для малого бизнеса, включает безлимитную защиту от любых DDoS-атак и профессиональную поддержку',
    middleRows: [
      {
        left: 'Колличество сайтов',
        right: '1',
      },
      {
        left: 'Поддоменов',
        right: 'до 10',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        left: 'Доступность SLA',
        right: 'от 98%',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        left: 'Тех. поддержка',
        right: '24/7',
      },
    ],
    footerRows: [
      {
        isDisabled: true,
        left: 'Точка фильтрации \n на выбор',
        right: '-',
      },
      {
        isDisabled: true,
        left: 'Балансировка \n нагрузки ',
        right: '-',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        isDisabled: true,
        left: 'Поддержка «WebSocket»',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        isDisabled: true,
        left: 'Подключения WAF',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        isDisabled: true,
        left: 'Оплата на Р/С',
      },
    ],
  },
  {
    icon: BusinessIcon,
    title: 'Бизнес',
    oldPrice: '15 000 ₽',
    price: ['13 594 ₽/мес', '13 000 ₽/мес', '12 000 ₽/мес', '11 000 ₽/мес'],
    subtitle:
      'Еще больше защиты. WAF включен в стоимость. Приоритетная поддержка. Больше опций. Для сайтов с высокой посещаемостью',
    middleRows: [
      {
        left: 'Колличество сайтов',
        right: '3+',
        rightTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
      },
      {
        left: 'Поддоменов',
        right: 'до 100',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        left: 'Доступность SLA',
        right: 'от 99%',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        left: 'Тех. поддержка',
        right: 'Приоритет',
      },
    ],
    footerRows: [
      {
        left: 'Точка фильтрации \n на выбор',
        right: 'RU / EU',
      },
      {
        left: 'Балансировка \n нагрузки ',
        right: '2 ws',
      },
      {
        isActive: true,
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        left: 'Поддержка «WebSocket»',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        isDisabled: true,
        left: 'Подключения WAF',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        isActive: true,
        left: 'Оплата на Р/С',
      },
    ],
  },
  {
    icon: IndividualIcon,
    title: 'Индивидуальный',
    subtitle:
      'Нужно что-то особенное? \n' +
      'Мы разработаем уникальный тарифный план специально для ваших задач ',
    middleRows: [
      {
        left: 'Колличество сайтов',
        right: '∞',
      },
      {
        left: 'Поддоменов',
        right: '∞',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        left: 'Доступность SLA',
        right: 'от 99%',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        left: 'Тех. поддержка',
        right: 'Приоритет',
      },
    ],
    footerRows: [
      {
        left: 'Точка фильтрации \n на выбор',
        right: 'RU / EU',
      },
      {
        left: 'Балансировка \n нагрузки ',
        right: '10 ws',
      },
      {
        isActive: true,
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        left: 'Поддержка «WebSocket»',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        isActive: true,
        left: 'Подключения WAF',
      },
      {
        leftTooltip: 'Бесперебойная доступность сервиса на протяжении всего периода',
        isActive: true,
        left: 'Оплата на Р/С',
      },
    ],
  },
];

export const freeRatesConstants: TFreeCardProps[] = [
  {
    link: {
      title: 'Adamant',
      url: '',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <DiamondIcon />,
    },
    subtitle: 'Технология фильтрации на основе машинного обучения AI',
  },
  {
    link: {
      title: 'Защищенный DNS',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <RocketIcon />,
      url: ProjectPath.ProtectedDns,
    },
    subtitle:
      'Управление черными/белыми списками' +
      'Кеширование статического контента' +
      'Оптимизация соединений и общее ускорение',
  },
  {
    link: {
      title: 'Сертификаты',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <KeyIcon />,
      url: ProjectPath.Certificates,
    },
    subtitle:
      'Установка собственного сертификата' +
      'Бесплатный сертификат от Let`s Encrypt' +
      'Поддержка SSL/TLS 1.2&1.3 шифрования' +
      'Поддержка HTTP/2 и SPDY',
  },
];
