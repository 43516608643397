import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';

const useStyles = makeStyles({
  root: {
    height: '28rem',
    padding: '2.5rem 1.625rem 2.5rem 2.5rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.backgroundSecondary,
    ...GlobalStyles.borderRadius,
  },
  lgRoot: {
    padding: '2.5rem 1.625rem 3rem 2.5rem',
    backgroundColor: Colors.white,
    ...GlobalStyles.border,
    ...GlobalStyles.boxShadow,
  },
  smRoot: {
    maxHeight: '13.375rem',
    height: 'auto',
  },
  header: {
    display: 'flex',
    marginBottom: '1rem',
  },
  title: {
    color: Colors.accent,
    fontFamily: 'Raleway-Medium',
  },
  content: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
  },
  lgContent: {
    '-webkit-line-clamp': 17,
  },
  mdContent: {
    '-webkit-line-clamp': 21,
  },
  smContent: {
    '-webkit-line-clamp': 6,
  },
});

export type THistoryCard = {
  title: string;
  subtitle: string;
  size: 'sm' | 'md' | 'lg';
};

type TProps = {
  item: THistoryCard;
};

export const HistoryCard: React.FC<TProps> = (props: TProps) => {
  const { item } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      className={`${classes.root} ${item.size === 'lg' && classes.lgRoot} ${
        item.size === 'sm' && classes.smRoot
      }`}
    >
      <div className={classes.header}>
        <Typography className={classes.title} variant="h3" component="div">
          {t(item.title)}
        </Typography>
      </div>
      <div
        className={`${classes.content} ${item.size === 'lg' && classes.lgContent} ${
          item.size === 'md' && classes.mdContent
        } ${item.size === 'sm' && classes.smContent}`}
      >
        <Typography variant={item.size === 'lg' ? 'h6' : 'body2'}>{t(item.subtitle)}</Typography>
      </div>
    </div>
  );
};
