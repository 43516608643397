import React from 'react';
import { Grid, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { StockSmCard } from '../../ui/Cards/StockSmCard';
import { StockMdCard } from '../../ui/Cards/StockMdCard';
import 'swiper/swiper-bundle.css';
import { GlobalStyles } from '../../../styles/GlobalStyles';

SwiperCore.use([Navigation, Pagination]);
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '0 -1rem 0 0',
    overflow: 'hidden',
  },
  swiper: {
    minHeight: '353px',
    overflow: 'initial',
    margin: '0 1rem 0 0',
    ...GlobalStyles.pagination,
    ...GlobalStyles.slides,
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto',
    },
  },
  slide: {
    height: '100%',
    padding: '0 0 3.5rem 0',
    [theme.breakpoints.down('xs')]: {
      padding: '0 0 1.5rem 0',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.625rem',
  },
  firstGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  secondGrid: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  reversed: {
    flexDirection: 'row-reverse',
  },
}));

type TProps = {
  isReversed?: boolean;
  prevButtonClass: string;
  nextButtonClass: string;
};

export const StocksSwiper: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { prevButtonClass, nextButtonClass, isReversed } = props;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const renderSlides = () => {
    return new Array(3).fill(1).map((val, index) => {
      return (
        <SwiperSlide key={index} className={classes.slide}>
          <Grid container spacing={5} className={`${isReversed ? classes.reversed : ''}`}>
            <Grid item md={12} lg={8}>
              <StockMdCard />
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={5} className={classes.secondGrid}>
                <Grid item xs={12}>
                  <StockSmCard />
                </Grid>
                <Grid item xs={12}>
                  <StockSmCard />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className={classes.root}>
      <Swiper
        spaceBetween={isXs ? 10 : 20}
        slidesPerView={1}
        direction="horizontal"
        className={classes.swiper}
        navigation={{
          prevEl: `.${prevButtonClass}`,
          nextEl: `.${nextButtonClass}`,
        }}
        pagination={{ clickable: true }}
      >
        {renderSlides()}
      </Swiper>
    </div>
  );
};
