import { CustomAccordionProps } from '../../ui/Accordion/CustomAccordion';

export const indexFaqData: CustomAccordionProps[] = [
  {
    id: 0,
    title: 'Какие у вас гарантии?',
    subtitle:
      'Наш рекорд - 6 минут, за это время был подключен новый клиент и отражена мощная DDoS-атака.\n' +
      'Большую часть времени подключения, занимает перенаправление трафика с ваших ресурсов, через редактирование DNS записей вашего сайта.\n' +
      'Приблизительное время перенаправления трафика, вы можете рассчитать на основе значений TTL, указанных в ваших DNS записях.\n' +
      'После завершения перенаправления трафика, защита от DDoS-атак начинает работать сразу.',
  },
  {
    id: 1,
    title: 'Как быстро происходит подключение защиты, когда мой сайт будет работать?',
    subtitle:
      'Наш рекорд - 6 минут, за это время был подключен новый клиент и отражена мощная DDoS-атака.\n' +
      'Большую часть времени подключения, занимает перенаправление трафика с ваших ресурсов, через редактирование DNS записей вашего сайта.\n' +
      'Приблизительное время перенаправления трафика, вы можете рассчитать на основе значений TTL, указанных в ваших DNS записях.\n' +
      'После завершения перенаправления трафика, защита от DDoS-атак начинает работать сразу.',
  },
  {
    id: 2,
    title: 'Какие методы оплаты доступны для клиентов?',
    subtitle:
      'Наш рекорд - 6 минут, за это время был подключен новый клиент и отражена мощная DDoS-атака.\n' +
      'Большую часть времени подключения, занимает перенаправление трафика с ваших ресурсов, через редактирование DNS записей вашего сайта.\n' +
      'Приблизительное время перенаправления трафика, вы можете рассчитать на основе значений TTL, указанных в ваших DNS записях.\n' +
      'После завершения перенаправления трафика, защита от DDoS-атак начинает работать сразу.',
  },
  {
    id: 3,
    title: 'Есть ли у вас гарантия возврата денежных средств?',
    subtitle:
      'Наш рекорд - 6 минут, за это время был подключен новый клиент и отражена мощная DDoS-атака.\n' +
      'Большую часть времени подключения, занимает перенаправление трафика с ваших ресурсов, через редактирование DNS записей вашего сайта.\n' +
      'Приблизительное время перенаправления трафика, вы можете рассчитать на основе значений TTL, указанных в ваших DNS записях.\n' +
      'После завершения перенаправления трафика, защита от DDoS-атак начинает работать сразу.',
  },
  {
    id: 4,
    title: 'Как быстро отвечает служба технической поддержки?',
    subtitle:
      'Наш рекорд - 6 минут, за это время был подключен новый клиент и отражена мощная DDoS-атака.\n' +
      'Большую часть времени подключения, занимает перенаправление трафика с ваших ресурсов, через редактирование DNS записей вашего сайта.\n' +
      'Приблизительное время перенаправления трафика, вы можете рассчитать на основе значений TTL, указанных в ваших DNS записях.\n' +
      'После завершения перенаправления трафика, защита от DDoS-атак начинает работать сразу.',
  },
];

export const partnersFaqData: CustomAccordionProps[] = [
  {
    id: 0,
    title: 'Условия тестового периода',
    subtitle:
      'Минимальный срок действия всех тарифных планов защиты от DDoS-атак - 1 календарный месяц. Только так мы можем гарантировать максимальную степень защиты для ваших ресурсов в сети интернет.',
  },
  {
    id: 1,
    title: 'Как быстро происходит подключение защиты, когда мой сайт будет работать?',
    subtitle:
      'Минимальный срок действия всех тарифных планов защиты от DDoS-атак - 1 календарный месяц. Только так мы можем гарантировать максимальную степень защиты для ваших ресурсов в сети интернет.',
  },
  {
    id: 2,
    title: 'Можно ли подключить защиту от DDoS-атак на пару дней?',
    subtitle:
      'Минимальный срок действия всех тарифных планов защиты от DDoS-атак - 1 календарный месяц. Только так мы можем гарантировать максимальную степень защиты для ваших ресурсов в сети интернет.',
  },
  {
    id: 3,
    title: 'Поддерживаете ли вы HTTPS фильтрацию?',
    subtitle:
      'Минимальный срок действия всех тарифных планов защиты от DDoS-атак - 1 календарный месяц. Только так мы можем гарантировать максимальную степень защиты для ваших ресурсов в сети интернет.',
  },
  {
    id: 4,
    title: 'Работаете ли вы с юридическими лицами из РФ (ООО, ЗАО, ПАО, ОАО, ИП)?',
    subtitle:
      'Минимальный срок действия всех тарифных планов защиты от DDoS-атак - 1 календарный месяц. Только так мы можем гарантировать максимальную степень защиты для ваших ресурсов в сети интернет.',
  },
];
