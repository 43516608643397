import { PartnerCardProps } from '../../ui/Cards/PartnerCard';
import NicknameCompany from '../../../assets/companies/nickname-company.png';
import MonolitCompany from '../../../assets/companies/monolit-company.png';
import CarsCompany from '../../../assets/companies/cars-company.png';
import MidCompany from '../../../assets/companies/mid-company.png';
import MagneticCompany from '../../../assets/companies/magnetic-company.png';
import ProofCompany from '../../../assets/companies/proof-company.png';
import FantasyCompany from '../../../assets/companies/fantasy-company.png';
import AutoStatCompany from '../../../assets/companies/autostat-company.png';
import FlowersCompany from '../../../assets/companies/flowers-company.png';
import DiaCompany from '../../../assets/companies/dia-company.png';
import TeploCompany from '../../../assets/companies/teplo-company.png';
import FlexCompany from '../../../assets/companies/flex-company.png';
import AviaCompany from '../../../assets/companies/avia-company.png';
import MainClubCompany from '../../../assets/companies/main-club-company.png';
import MonoCompany from '../../../assets/companies/mono-company.png';
import RustersCompany from '../../../assets/companies/rusters-company.png';
import ChehovCompany from '../../../assets/companies/chehov-company.png';

export const partnersData: PartnerCardProps[] = [
  {
    imageUrl: NicknameCompany,
  },
  {
    imageUrl: MonolitCompany,
  },
  {
    imageUrl: CarsCompany,
  },
  {
    imageUrl: MidCompany,
  },
  {
    imageUrl: MagneticCompany,
  },
  {
    imageUrl: ProofCompany,
  },
  {
    imageUrl: FantasyCompany,
  },
  {
    imageUrl: AutoStatCompany,
  },
  {
    imageUrl: FlowersCompany,
  },
  {
    imageUrl: DiaCompany,
  },
  {
    imageUrl: TeploCompany,
  },
  {
    imageUrl: FlexCompany,
  },
  {
    imageUrl: AviaCompany,
  },
  {
    imageUrl: MainClubCompany,
  },
  {
    imageUrl: MonoCompany,
  },
  {
    imageUrl: RustersCompany,
  },
  {
    imageUrl: ChehovCompany,
  },
];

export const mobileFirstSlide: PartnerCardProps[] = [
  {
    imageUrl: NicknameCompany,
  },
  {
    imageUrl: MonolitCompany,
  },
  {
    imageUrl: CarsCompany,
  },
  {
    imageUrl: MidCompany,
  },
  {
    imageUrl: MagneticCompany,
  },
  {
    imageUrl: ProofCompany,
  },
  {
    imageUrl: FantasyCompany,
  },
  {
    imageUrl: AutoStatCompany,
  },
];

export const mobileSecondSlide: PartnerCardProps[] = [
  {
    imageUrl: FlowersCompany,
  },
  {
    imageUrl: DiaCompany,
  },
  {
    imageUrl: TeploCompany,
  },
  {
    imageUrl: FlexCompany,
  },
  {
    imageUrl: AviaCompany,
  },
  {
    imageUrl: MainClubCompany,
  },
  {
    imageUrl: MonoCompany,
  },
  {
    imageUrl: RustersCompany,
  },
];

export const mobileThirdSlide: PartnerCardProps[] = [
  {
    imageUrl: ChehovCompany,
  },
];
