import React from 'react';
import { Button, Grid, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { newsConstants } from '../Home/StockAndNewsBlock/NewsConstants';
import { NewsCard } from '../ui/Cards/NewsCard';
import { InputWithButton } from '../ui/Inputs/InputWithButton';

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  tags: {
    display: 'flex',
    justifyContent: 'left',
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      gap: '10px',
      justifyContent: 'center',
      display: '-webkit-box',
      overflow: 'scroll',
      margin: '0 -1rem 0',
      paddingLeft: '1rem',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  button: {
    '&.MuiButton-root': {
      height: '36px',
      width: '92px',
      padding: '9px 20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      fontFamily: 'Raleway-SemiBold',
      fontSize: '14px',
      lineHeight: '16.44px',
    },
  },
  input: {
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  grid: {
    marginTop: '1.625rem',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const tags: string[] = ['Скидки', 'Атаки', 'Рейтинги', 'Компания', 'Проекты'];

export const UpperNewsBlock: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderTags = () => {
    return tags.map((item, index) => {
      return (
        <Button className={classes.button} variant="outlined" key={index}>
          {t(item)}
        </Button>
      );
    });
  };

  return (
    <>
      <Grid spacing={5} container className={classes.search}>
        <Grid item xs={12} sm={10} md={7} lg={6}>
          <div className={classes.tags}>{renderTags()}</div>
        </Grid>
        <Grid item xs={12} sm={8} md={7} lg={6}>
          <div className={classes.input}>
            <InputWithButton onFindButtonClick={() => {}} />
          </div>
        </Grid>
      </Grid>
      <Grid spacing={5} container className={classes.grid}>
        {newsConstants.map((val, index) => {
          if (index < 3) {
            return (
              <Grid item xs={12} sm={8} md={7} lg={4} key={index}>
                <NewsCard item={val} />
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
    </>
  );
};
