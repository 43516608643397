import React from 'react';
import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { directionsConstants } from './DirectionsConstants';
import { DirectionItem } from './DirectionItem';
import { redirectToProject } from '../../../utils/redirectToProject';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'flex',
    justifyContent: 'center',
  },
  subtitle: {
    marginTop: '1.25rem',
    marginRight: '61px',
    color: Colors.lightBlack,
    [theme.breakpoints.down('md')]: {
      marginRight: '0',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
      marginRight: '0',
    },
  },
  desktopButton: {
    marginTop: '1.25rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobileButton: {
    width: '100%',
    marginTop: '1.25rem',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

export const DirectionsBlock: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid spacing={5} container className={classes.grid}>
      <Grid item xs={12} lg={4}>
        <Typography variant="h2">{t('Направления')}</Typography>
        <Typography variant="h6" component="p" className={classes.subtitle}>
          {t(
            'У нас востребованный продукт, и активно ведудтся работы по расширению услуг.\n' +
              '\nКаждый привлеченный клиент, будет приносить Вам пассивный ежемесячный доход в размере 50%* от каждого платежа.\n' +
              '\n' +
              'Средства на счету партнерской программы и средства на счету пользовательского аккаунта являются общими, вы можете потратить их на полную или частичную оплату наших услуг',
          )}
        </Typography>
        <Button variant="outlined" className={classes.desktopButton} onClick={redirectToProject}>
          {t('Все услуги')}
        </Button>
      </Grid>
      {directionsConstants.map((item) => {
        return (
          <Grid item xs={12} sm={8} md={7} lg={4} key={item.id}>
            <DirectionItem item={item} />
          </Grid>
        );
      })}
      <div className={classes.mobileButton}>
        <Button variant="outlined">{t('Все услуги')}</Button>
      </div>
    </Grid>
  );
};
