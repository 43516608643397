import React from 'react';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { redirectToProject } from '../../../utils/redirectToProject';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    padding: '1.875rem',
    backgroundColor: Colors.white,
    ...GlobalStyles.lightBorder,
    ...GlobalStyles.boxShadow,
    ...GlobalStyles.borderRadius,
    [theme.breakpoints.down('xs')]: {
      padding: '30px 30px 27px 30px',
    },
  },
  purpleRoot: {
    backgroundColor: 'rgba(69, 119, 250, 0.12)',
    boxShadow: 'none',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  number: {
    color: Colors.accent,
    marginRight: '1.25rem',
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontFamily: 'Raleway-SemiBold',
      fontSize: '16px',
      lineHeight: '18.78px',
    },
  },
  subtitle: {
    marginTop: '1.625rem',
    color: Colors.lightBlack,
  },
  buttonContainer: {
    marginTop: '0.25rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    [theme.breakpoints.down('xs')]: {
      marginTop: '17px',
    },
  },
}));

export type THowPartnersWorksCardProps = {
  id: number;
  title: string;
  subtitle: string;
};

type TProps = {
  item: THowPartnersWorksCardProps;
};

export const HowPartnersWorksCard: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { item } = props;
  const { t } = useTranslation();

  return (
    <div className={`${classes.root} ${item.id === 2 && classes.purpleRoot}`}>
      <div className={classes.header}>
        <Typography variant="h1" className={classes.number}>
          {item.id + 1}
        </Typography>
        <Typography variant="h3" component="div" className={classes.title}>
          {t(item.title)}
        </Typography>
      </div>
      <Typography variant="body2" component="p" className={classes.subtitle}>
        {t(item.subtitle)}
      </Typography>
      {item.id === 2 && (
        <div className={classes.buttonContainer}>
          <Button variant="contained" onClick={redirectToProject}>
            {t('Получить ссылку')}
          </Button>
        </div>
      )}
    </div>
  );
};
