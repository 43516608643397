import React from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { CustomLink, CustomLinkProps } from '../Link/CustomLink';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    maxWidth: '28.375rem',
    /* marginRight: '1.25rem', */
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.backgroundSecondary}`,
    padding: '1.875rem 1.875rem 1.625rem 1.875rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...GlobalStyles.boxShadow,
    ...GlobalStyles.borderRadius,
    zIndex: 2,
    [theme.breakpoints.down(1850)]: {
      padding: '1.875rem 1.875rem 1.625rem 1.875rem',
    },
    [theme.breakpoints.down(1000)]: {
      marginRight: '0',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'initial',
      marginRight: '0',
    },
    /* [theme.breakpoints.down(400)]: {
      maxWidth: '310px',
      marginRight: '0',
    }, */
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonHover: {
    '&.MuiButtonBase-root.MuiButton-root.MuiButton-outlined': {
      color: Colors.white,
      opacity: 1,
      backgroundColor: Colors.primary,
    },
  },
  header: {
    display: 'flex',
    marginBottom: '0.875rem',
  },
  icon: {
    height: '1.5rem',
    width: '1.5rem',
    marginRight: '0.75rem',
    ...GlobalStyles.icon,
  },
  content: {
    minHeight: '8rem',
    '& > p': {
      color: Colors.lightBlack,
    },
  },
  links: {
    alignItems: 'end',
  },
  link: {
    paddingTop: '0.375rem',
  },
  button: {
    [theme.breakpoints.down(1600)]: {
      width: '100% !important',
      marginTop: '1.5rem',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

type TProps = {
  title: string;
  subtitle: string;
  icon: string;
  links: CustomLinkProps[];
  url: string;
};

export const ProtectionCard: React.FC<TProps> = (props: TProps) => {
  const { title, icon, links, url, subtitle } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobileLink = useMediaQuery(theme.breakpoints.down(1600));
  const desktopLink = useMediaQuery(theme.breakpoints.down(1920));
  const buttonSelector = '.MuiButtonBase-root.MuiButton-root.MuiButton-outlined';

  const onButtonClick = () => {
    history.push(url);
  };

  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <div
      className={classes.root}
      onClick={onButtonClick}
      onKeyDown={onButtonClick}
      onMouseEnter={(e) => {
        const button = e.currentTarget.querySelector(buttonSelector);

        if (button) {
          button.className += ` ${classes.buttonHover}`;
        }
      }}
      onMouseLeave={(e) => {
        const button = e.currentTarget.querySelector(buttonSelector);

        if (button) {
          const items = button.className.split(' ');
          const index = items.indexOf(classes.buttonHover);

          items.splice(index, 1);
          button.className = items.join(' ');
        }
      }}
    >
      <div>
        <div className={classes.header}>
          <div className={classes.icon} style={{ backgroundImage: `url(${icon})` }} />
          <Typography variant="h3" component="div">
            {t(title)}
          </Typography>
        </div>
        <div className={classes.content}>
          <Typography variant="h6" component="p">
            {t(subtitle)}
          </Typography>
        </div>
      </div>
      <Grid container className={classes.links}>
        <Grid item xs={12} md={mobileLink ? 12 : 5} lg={desktopLink ? 6 : 8}>
          {links.map((item, index) => {
            return (
              <CustomLink
                key={index}
                className={classes.link}
                item={item}
                textColor={Colors.fontColor}
                onMouseLeave={(e) => {
                  const button =
                    e.currentTarget.parentElement.parentElement.parentElement.querySelector(
                      buttonSelector,
                    );

                  if (button) {
                    button.className += ` ${classes.buttonHover}`;
                  }
                }}
                onMouseEnter={(e) => {
                  const button =
                    e.currentTarget.parentElement.parentElement.parentElement.querySelector(
                      buttonSelector,
                    );

                  if (button) {
                    const items = button.className.split(' ');
                    const position = items.indexOf(classes.buttonHover);
                    items.splice(position, 1);
                    button.className = items.join(' ');
                  }
                }}
              />
            );
          })}
        </Grid>
        <Grid
          item
          xs={12}
          md={mobileLink ? 12 : 7}
          lg={desktopLink ? 6 : 4}
          className={classes.buttonContainer}
        >
          <Button variant="outlined" className={classes.button} onClick={onButtonClick}>
            {t('Подробнее')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
