import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { CompanyCardProps } from './HugeCompanyCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '10rem',
      width: '100%',
      display: 'inline-flex',
      backgroundColor: Colors.backgroundSecondary,
      overflow: 'hidden',
      ...GlobalStyles.borderRadius,
      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
        height: 'auto',
      },
    },
    content: {
      padding: '1.875rem 0.5rem 1.875rem 0',
      [theme.breakpoints.down('xs')]: {
        padding: '13.5px 30px 13.5px 0',
      },
    },
    title: {
      color: Colors.primary,
      [theme.breakpoints.down(1400)]: {
        fontSize: '34px',
      },
      [theme.breakpoints.down(1200)]: {
        fontSize: '24px',
      },
    },
    subtitle: {
      color: Colors.lightBlack,
      marginTop: '0.625rem',
      [theme.breakpoints.down(1200)]: {
        fontSize: '14px',
      },
    },
    image: {
      [theme.breakpoints.down('xs')]: {
        height: '100px',
        width: '100px',
      },
    },
  }),
);

type TProps = {
  item: CompanyCardProps;
};

export const SmallCompanyCard: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { item } = props;

  return (
    <div className={classes.root}>
      <img src={item.img} alt="" className={classes.image} />
      <div className={classes.content}>
        <Typography variant="h1" className={classes.title}>
          {t(item.title)}
        </Typography>
        <Typography variant="h4" component="div" className={classes.subtitle}>
          {t(item.subtitle)}
        </Typography>
      </div>
    </div>
  );
};
