import React from 'react';
import { createStyles, IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import DotsIcon from '../../../assets/icons/dots.svg';
import DotsWhiteIcon from '../../../assets/icons/dots-white.svg';
import { redirectToProject } from '../../../utils/redirectToProject';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // width: '28.375rem',
      padding: '1.875rem',
      backgroundColor: Colors.white,
      ...GlobalStyles.boxShadow,
      ...GlobalStyles.borderRadius,
      ...GlobalStyles.lightBorder,
      '&:hover': {
        cursor: 'pointer',
      },
      '&:hover button.MuiIconButton-root': {
        color: Colors.white,
        opacity: 1,
        backgroundColor: Colors.primary,

        '& span div': {
          backgroundImage: `url(${DotsWhiteIcon})`,
        },
      },
    },
    title: {
      marginBottom: '0.875rem',
      color: Colors.primary,
    },
    subtitle: {
      marginRight: '3.75rem',
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: '-1.125rem',
      backgroundColor: Colors.backgroundSecondary,
      '&:hover': {
        backgroundColor: Colors.primary,

        '& span div': {
          backgroundImage: `url(${DotsWhiteIcon})`,
        },
      },
    },
    dots: {
      height: '1rem',
      width: '1rem',
      backgroundImage: `url(${DotsIcon})`,
      ...GlobalStyles.icon,
    },
  }),
);

export type StockSmCardProps = {
  title: string;
  subtitle: string;
};

type TProps = {
  item?: StockSmCardProps;
};

export const StockSmCard: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { item } = props;
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={classes.root} onClick={redirectToProject}>
      <Typography variant="h3" component="div" className={classes.title}>
        {t('Акция «Скидка 50% за первый месяц»')}
      </Typography>
      <Typography variant="body2" component="p" className={classes.subtitle}>
        {t(
          'Мы готовы протянуть Вам руку помощи и дарим Вам промокод на скидку 50% при оплате за первый месяц.\n',
        )}
      </Typography>
      <div className={classes.buttonContainer}>
        <IconButton className={classes.button}>
          <div className={classes.dots} />
        </IconButton>
      </div>
    </div>
  );
};
