import React from 'react';

const SiteProtectionIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.25C4.62 0.25 0.25 4.62 0.25 10C0.25 15.38 4.62 19.75 10 19.75C15.38 19.75 19.75 15.38 19.75 10C19.75 4.62 15.38 0.25 10 0.25ZM18.21 9.25H13.74C13.66 6.49 13.17 3.79 12.28 2.08C15.49 3 17.9 5.83 18.21 9.25ZM10 18.25C9.27 18.25 7.89 15.61 7.76 10.75H12.23C12.1 15.61 10.72 18.25 9.99 18.25H10ZM7.76 9.25C7.89 4.39 9.27 1.75 10 1.75C10.73 1.75 12.11 4.39 12.24 9.25H7.77H7.76ZM7.72 2.08C6.83 3.79 6.34 6.49 6.26 9.25H1.79C2.1 5.82 4.51 3 7.72 2.08ZM1.79 10.75H6.26C6.34 13.51 6.83 16.21 7.72 17.92C4.51 17 2.1 14.17 1.79 10.75ZM12.28 17.92C13.17 16.21 13.66 13.51 13.74 10.75H18.21C17.9 14.18 15.49 17 12.28 17.92Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SiteProtectionIcon;
