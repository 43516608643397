import React from 'react';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { Colors } from '../../../constants/Colors';
import { RateRow, TRateRow } from '../RateRow';
import { redirectToProject } from '../../../utils/redirectToProject';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    position: 'relative',
    padding: '1.875rem 1.75rem 1.875rem 1.75rem',
    backgroundColor: Colors.white,
    ...GlobalStyles.borderRadius,
    ...GlobalStyles.boxShadow,
    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover button.MuiButton-contained': {
      color: Colors.white,
      opacity: 1,
      backgroundColor: Colors.primary,
    },
    [theme.breakpoints.down(1270)]: {
      padding: '1.875rem 1.75rem 1rem 1.75rem',
    },
  },
  rootDark: {
    backgroundColor: 'rgba(43, 43, 43, 0.03)',
  },
  icon: {
    height: '24px',
    width: '24px',
    marginRight: '10px',
    ...GlobalStyles.icon,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '0.875rem',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(1900)]: {
      width: '100%',
    },
  },
  prices: {
    display: 'flex',
    // margin: '-10px 0',
  },
  oldPrice: {
    marginTop: '1px',
    marginRight: '0.5rem',
    fontFamily: 'Raleway-SemiBold',
    textDecoration: 'line-through',
    color: Colors.darkGray,
  },
  price: {
    color: Colors.accent,
  },
  middle: {
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 -1.75rem 1.25rem -1.75rem',
    padding: '1.5rem 1.875rem',
    backgroundColor: 'rgba(69, 119, 250, 0.12)',
  },
  footer: {
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(1270)]: {
      marginBottom: '1.875rem',
    },
  },
  button: {
    '&.MuiButton-root': {
      fontSize: '14px',
      lineHeight: '16.44px',
    },
    [theme.breakpoints.up(1270)]: {
      position: 'absolute',
      bottom: '14px',
      right: '22px',
    },
    [theme.breakpoints.down(1270)]: {
      '&.MuiButton-root': {
        width: '100% !important',
        fontSize: '14px',
        lineHeight: '16.44px',
      },
    },
  },
}));

export type TPortsRateCard = {
  icon: string;
  title: string;
  price?: string[];
  oldPrice?: string;
  middleRows: TRateRow[];
  footerRows: TRateRow[];
};

type TProps = {
  isPopup?: boolean;
  item: TPortsRateCard;
  activeMonth: number;
};

export const PortsRateCard: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { item, isPopup, activeMonth } = props;
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <div
      className={[classes.root, isPopup ? classes.rootDark : ''].join(' ')}
      onClick={redirectToProject}
    >
      <div className={classes.header}>
        <div className={classes.title}>
          <div className={classes.icon} style={{ backgroundImage: `url(${item.icon})` }} />
          <Typography variant="h3" component="div">
            {t(item.title)}
          </Typography>
        </div>
        <div className={`${item?.oldPrice && classes.prices}`}>
          {item?.oldPrice && (
            <Typography variant="body1" component="p" className={classes.oldPrice}>
              {t(item.oldPrice)}
            </Typography>
          )}
          {item?.price && (
            <Typography variant="h4" component="div" className={classes.price}>
              {t(item.price[activeMonth])}
            </Typography>
          )}
        </div>
      </div>
      <div className={classes.middle}>
        {item.middleRows.map((row, index) => {
          return <RateRow index={index} item={row} />;
        })}
      </div>
      <div className={classes.footer}>
        {item.footerRows.map((row, index) => {
          return <RateRow index={index} item={row} />;
        })}
      </div>
      {!isPopup ? (
        <Button className={classes.button}>{t('Подключиться')}</Button>
      ) : (
        <Button className={classes.button} variant="contained">
          {t('Выбрать')}
        </Button>
      )}
    </div>
  );
};
