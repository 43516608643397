import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    padding: '1.875rem',
    overflow: 'hidden',
    backgroundColor: Colors.backgroundSecondary,
    ...GlobalStyles.lightBorder,
    ...GlobalStyles.borderRadius,
    [theme.breakpoints.down('xs')]: {
      height: '333px',
    },
  },
  header: {
    margin: '-1.875rem -1.875rem 0 0',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {},
  subtitle: {
    marginTop: '1.625rem',
    color: Colors.lightBlack,
    [theme.breakpoints.down('xs')]: {
      marginTop: '14px',
    },
  },
  image: {
    marginBottom: '-20px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
      marginTop: '6px',
      height: '83.85px',
    },
  },
}));

export type TDirectionItemProps = {
  id: number;
  img: string;
  title: string;
  subtitle: string;
};

type TProps = {
  item: TDirectionItemProps;
};

export const DirectionItem: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { item } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <img alt={item.title} src={item.img} className={classes.image} />
      </div>
      <Typography variant="h3" component="div">
        {t(item.title)}
      </Typography>
      <Typography variant="h6" component="p" className={classes.subtitle}>
        {t(item.subtitle)}
      </Typography>
    </div>
  );
};
