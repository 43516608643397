import { CustomLinkProps } from '../../../Link/CustomLink';
import RocketIcon from '../../../Icons/RocketIcon';
import KeyIcon from '../../../Icons/KeyIcon';
import { ProjectPath } from '../../../../../constants/ProjectPath';
import SiteProtectionIcon from '../../../Icons/SiteProtectionIcon';
import TcpProtectionIcon from '../../../Icons/TcpProtectionIcon';
import ProfileIcon from '../../../Icons/ProfileIcon';
import HugeIdeaIcon from '../../../Icons/HugeIdeaIcon';
import ChatIcon from '../../../Icons/ChatIcon';
import StocksIcon from '../../../Icons/StocksIcon';
import LogoIcon from '../../../Icons/LogoIcon';
import PartnersIcon from '../../../Icons/PartnersIcon';
import NewsIcon from '../../../Icons/NewsIcon';

export const protectLinks: CustomLinkProps[] = [
  {
    title: 'Защита веб-сайтов',
    url: ProjectPath.SiteProtection,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <SiteProtectionIcon />,
    isIconFixed: true,
  },
  {
    title: 'Защита TCP-сервиса',
    url: ProjectPath.PortsProtection,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <TcpProtectionIcon />,
    isIconFixed: true,
  },
  {
    title: 'Защищенный DNS',
    url: ProjectPath.ProtectedDns,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <RocketIcon />,
    isIconFixed: true,
  },
  {
    title: 'Сертификаты',
    url: ProjectPath.Certificates,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <KeyIcon />,
    isIconFixed: true,
  },
];

export const clientLinks: CustomLinkProps[] = [
  {
    title: 'Личный кабинет',
    url: ProjectPath.Profile,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <ProfileIcon />,
    isIconFixed: true,
  },
  {
    title: 'База знаний',
    url: ProjectPath.KnowledgeBase,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <HugeIdeaIcon />,
    isIconFixed: true,
  },
  {
    title: 'Чат-поддержка',
    url: ProjectPath.TechnicalSupport,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <ChatIcon />,
    isIconFixed: true,
  },
  {
    title: 'Акции',
    url: ProjectPath.Stocks,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <StocksIcon />,
    isIconFixed: true,
  },
];

export const aboutLinks: CustomLinkProps[] = [
  {
    title: 'О компании',
    url: ProjectPath.About,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <LogoIcon />,
  },
  {
    title: 'Партнерам',
    url: ProjectPath.Partners,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <PartnersIcon />,
    isIconFixed: true,
  },
  {
    title: 'Новости',
    url: ProjectPath.News,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <NewsIcon />,
    isIconFixed: true,
  },
];
