import React, { useState } from 'react';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-reveal';
import { useHistory } from 'react-router';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { NewsSwiper } from './NewsSwiper';
import { StocksSwiper } from './StocksSwiper';
import { Colors } from '../../../constants/Colors';
import { SwiperButtonsContainer } from '../../ui/Buttons/SwiperButtonsContainer';
import { ProjectPath } from '../../../constants/ProjectPath';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    margin: '0 1.25rem',
    ...GlobalStyles.grayCircle,
    height: '5px',
    width: '5px',
    [theme.breakpoints.down('xs')]: {
      margin: '0 10px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.625rem',
  },
  button: {
    padding: '0 !important',
    backgroundColor: 'transparent !important',
  },
  disabledButton: {
    color: Colors.borderColor,
  },
  footer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  desktopAllButton: {
    position: 'absolute',
    top: '-2rem',
    zIndex: 2,
  },
  mobileAllButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export const StockAndNewsBlock: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isNews, setIsNews] = useState<boolean>(false);
  const { t } = useTranslation();

  const newsPrevButtonClass = 'news-prev-button';
  const newsNextButtonClass = 'news-next-button';
  const stocksPrevButtonClass = 'stocks-prev-button';
  const stocksNextButtonClass = 'stocks-next-button';

  const renderNavButtons = () => {
    if (isNews) {
      return (
        <SwiperButtonsContainer
          nextButtonClass={newsNextButtonClass}
          prevButtonClass={newsPrevButtonClass}
        />
      );
    }
    return (
      <SwiperButtonsContainer
        nextButtonClass={stocksNextButtonClass}
        prevButtonClass={stocksPrevButtonClass}
      />
    );
  };

  const renderSwiper = () => {
    if (isNews) {
      return (
        <NewsSwiper prevButtonClass={newsPrevButtonClass} nextButtonClass={newsNextButtonClass} />
      );
    }
    return (
      <StocksSwiper
        prevButtonClass={stocksPrevButtonClass}
        nextButtonClass={stocksNextButtonClass}
      />
    );
  };

  const onStateButtonClick = (news: boolean) => {
    if (news !== isNews) {
      setIsNews(news);
    }
  };

  const onAllButtonClick = () => {
    history.push(isNews ? ProjectPath.News : ProjectPath.Stocks);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.title}>
          <Button
            className={classes.button}
            disableRipple
            onClick={() => onStateButtonClick(false)}
          >
            <Typography
              variant="h2"
              component="div"
              className={`${isNews && classes.disabledButton}`}
            >
              {t('Акции')}
            </Typography>
          </Button>
          <div className={classes.dot} />
          <Button className={classes.button} disableRipple onClick={() => onStateButtonClick(true)}>
            <Typography
              variant="h2"
              component="div"
              className={`${!isNews && classes.disabledButton}`}
            >
              {t('Новости')}
            </Typography>
          </Button>
        </div>
        {renderNavButtons()}
        <Button className={classes.mobileAllButton} variant="outlined" onClick={onAllButtonClick}>
          {t(isNews ? 'Все новости' : 'Все акции')}
        </Button>
      </div>
      <Fade>
        {renderSwiper()}
        <div className={classes.footer}>
          <Button
            className={classes.desktopAllButton}
            variant="outlined"
            onClick={onAllButtonClick}
          >
            {t(isNews ? 'Все новости' : 'Все акции')}
          </Button>
        </div>
      </Fade>
    </div>
  );
};
