import React from 'react';
import { Button, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { GlobalStyles } from '../../styles/GlobalStyles';
import { Colors } from '../../constants/Colors';
import { ProjectPath } from '../../constants/ProjectPath';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    height: '37.5rem',
    padding: '0 4.375rem 0 6.25rem',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: Colors.backgroundSecondary,
    overflow: 'hidden',
    ...GlobalStyles.borderRadius,
    ...GlobalStyles.lightBorder,
    [theme.breakpoints.down('xs')]: {
      height: '30.875rem',
      padding: '22px 1rem 56px 1rem',
      margin: '0 -1rem',
    },
  },
  container: {
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  title: {
    maxWidth: '564px',
  },
  subtitle: {
    maxWidth: '450px',
    fontFamily: 'Raleway-Regular',
    color: Colors.lightBlack,
    marginTop: '0.625rem',
    [theme.breakpoints.down('xs')]: {
      fontFamily: 'Inter-Regular',
      fontSize: '13px',
      lineHeight: '15.73px',
      marginTop: '19px',
    },
  },
  button: {
    marginTop: '1.625rem',
  },
  image: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.down('xs')]: {
      right: 0,
      bottom: 0,
    },
  },
  doubleImage: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.down('md')]: {
      left: 0,
      bottom: 0,
    },
  },
}));

type TProps = {
  title: string;
  subtitle: string;
  buttonText: string;
  img: string;
  imgXs?: string;
};

export const NotFoundBanner: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { title, subtitle, buttonText, img, imgXs } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const onHomeButtonClick = () => {
    history.push(ProjectPath.Index);
  };

  return (
    <div className={classes.error}>
      <div className={classes.container}>
        <div>
          <Typography variant="h1" className={classes.title}>
            {t(title)}
          </Typography>
          <Typography variant="h3" component="div" className={classes.subtitle}>
            {t(subtitle)}
          </Typography>
        </div>
        <Button variant="contained" className={classes.button} onClick={onHomeButtonClick}>
          {t(buttonText)}
        </Button>
      </div>
      <img
        alt="404"
        src={isXs && imgXs ? imgXs : img}
        className={`${img === imgXs ? classes.doubleImage : classes.image}`}
      />
    </div>
  );
};
