import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Fade } from 'react-reveal';
import { Layout } from '../../components/ui/Layout/Layout';
import { NotFoundBanner } from '../../components/Error/NotFoundBanner';
import StopErrorBox from '../../assets/errors/stop.png';

const StopErrorPage: React.FC<RouteComponentProps> = () => {
  return (
    <Layout>
      <Fade>
        <NotFoundBanner
          title="Ошибка шлюза"
          subtitle="Ошибка. Попробуйте перезагрузить страницу или зайти позже"
          buttonText="На главную"
          img={StopErrorBox}
          imgXs={StopErrorBox}
        />
      </Fade>
    </Layout>
  );
};

export default withRouter(StopErrorPage);
