import React from 'react';
import { IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import DotsIcon from '../../../assets/icons/dots.svg';
import DotsWhiteIcon from '../../../assets/icons/dots-white.svg';
import { redirectToProject } from '../../../utils/redirectToProject';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    ...GlobalStyles.boxShadow,
    ...GlobalStyles.borderRadius,
    ...GlobalStyles.lightBorder,
    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover button.MuiIconButton-root': {
      color: Colors.white,
      opacity: 1,
      backgroundColor: Colors.primary,

      '& span div': {
        backgroundImage: `url(${DotsWhiteIcon})`,
      },
    },
  },
  image: {
    width: '100%',
    height: '13.5rem',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '9.375rem',
    },
    ...GlobalStyles.borderRadius,
  },
  content: {
    padding: '1.25rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 2.25rem 12px 20px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  title: {
    color: Colors.primary,
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '18.78px',
    },
  },
  date: {
    marginLeft: '0.5rem',
    marginTop: '0.25rem',
    color: Colors.darkGray,
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
      marginLeft: 0,
    },
  },
  subtitle: {
    width: '84%',
    color: Colors.lightBlack,
    marginTop: '0.875rem',
    '-webkit-line-clamp': 2,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      marginTop: '21px',
    },
  },
  footer: {},
  dotsButton: {
    position: 'absolute',
    right: '1.25rem',
    bottom: '1.25rem',
    height: '2.5rem',
    width: '2.5rem',
    backgroundColor: Colors.backgroundSecondary,
    color: Colors.white,
    opacity: 1,
    '&:hover': {
      backgroundColor: Colors.primary,

      '& span div': {
        backgroundImage: `url(${DotsWhiteIcon})`,
      },
    },
  },
  dotsIcon: {
    height: '1rem',
    width: '1rem',
    backgroundImage: `url(${DotsIcon})`,
    ...GlobalStyles.icon,
  },
}));

export type NewsCardProps = {
  title: string;
  subtitle: string;
  date: string;
  imageUrl: string;
};

type TProps = {
  item: NewsCardProps;
};

export const NewsCard: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { item } = props;
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={classes.root} onClick={redirectToProject}>
      <img src={item.imageUrl} alt="" className={classes.image} />
      <div className={classes.content}>
        <div className={classes.header}>
          <Typography variant="h3" component="div" className={classes.title}>
            {t(item.title)}
          </Typography>
          <Typography variant="body2" component="p" className={classes.date}>
            {item.date}
          </Typography>
        </div>
        <div className={classes.footer}>
          <Typography variant="body2" component="p" className={classes.subtitle}>
            {t(item.subtitle)}
          </Typography>
        </div>
        <IconButton className={classes.dotsButton}>
          <div className={classes.dotsIcon} />
        </IconButton>
      </div>
    </div>
  );
};
