import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-reveal';
import { Colors } from '../../constants/Colors';
import {
  LAYOUT_LEFT_RIGHT_PADDING_LG,
  LAYOUT_LEFT_RIGHT_PADDING_MD,
  LAYOUT_LEFT_RIGHT_PADDING_XL,
  LAYOUT_LEFT_RIGHT_PADDING_XS,
} from '../../constants/UI';
import { StocksSwiper } from '../Home/StockAndNewsBlock/StocksSwiper';
import { SwiperButtonsContainer } from '../ui/Buttons/SwiperButtonsContainer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      '& .swiper-container .swiper-pagination': {
        display: 'none',
      },
      '& .swiper-container .swiper-wrapper .swiper-slide': {
        padding: '0 0 2.5rem 0',
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& .swiper-container .swiper-pagination': {
        bottom: '1.375rem',
      },
      '& .swiper-container .swiper-wrapper .swiper-slide': {
        padding: '0 0 3.5rem 0',
      },
    },
  },
  header: {
    padding: '2rem 0 1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rootBackground: {
    position: 'absolute',
    backgroundColor: Colors.backgroundSecondary,
    top: 0,
    bottom: 0,
    left: `-${LAYOUT_LEFT_RIGHT_PADDING_XL}`,
    right: `-${LAYOUT_LEFT_RIGHT_PADDING_XL}`,
    [theme.breakpoints.down('lg')]: {
      left: `-${LAYOUT_LEFT_RIGHT_PADDING_LG}`,
      right: `-${LAYOUT_LEFT_RIGHT_PADDING_LG}`,
    },
    [theme.breakpoints.down('md')]: {
      left: `-${LAYOUT_LEFT_RIGHT_PADDING_MD}`,
      right: `-${LAYOUT_LEFT_RIGHT_PADDING_MD}`,
    },
    [theme.breakpoints.down('xs')]: {
      left: `-${LAYOUT_LEFT_RIGHT_PADDING_XS}`,
      right: `-${LAYOUT_LEFT_RIGHT_PADDING_XS}`,
    },
  },
}));

type TProps = {
  isReversed?: boolean;
};

export const StocksBlock: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { isReversed } = props;
  const { t } = useTranslation();
  const stocksPrevButton = 'stocks-prev-button';
  const stocksNextButton = 'stocks-next-button';

  return (
    <div className={classes.root}>
      <div className={classes.rootBackground} />
      <div className={classes.header}>
        <Typography variant="h2">{t('Топ акции')}</Typography>
        <SwiperButtonsContainer
          prevButtonClass={stocksPrevButton}
          nextButtonClass={stocksNextButton}
        />
      </div>
      <Fade>
        <StocksSwiper
          nextButtonClass={stocksNextButton}
          prevButtonClass={stocksPrevButton}
          isReversed={isReversed}
        />
      </Fade>
    </div>
  );
};
