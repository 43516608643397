import React from 'react';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import ClockIcon from '../../../assets/icons/clock.svg';
import {
  LAYOUT_LEFT_RIGHT_CONTENT_PADDING_LG,
  LAYOUT_LEFT_RIGHT_CONTENT_PADDING_MD,
  LAYOUT_LEFT_RIGHT_CONTENT_PADDING_XL,
} from '../../../constants/UI';
import Video from '../../../assets/main-banner-video.mp4';
import { Colors } from '../../../constants/Colors';
import { redirectToProject } from '../../../utils/redirectToProject';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `0 -${LAYOUT_LEFT_RIGHT_CONTENT_PADDING_XL}`,
    height: '30.875rem',
    position: 'relative',
    overflow: 'hidden',
    background: `linear-gradient(93.07deg, rgba(255, 255, 255, 0.68) 27.96%, rgba(255, 255, 255, 0) 65.26%))`,
    backgroundPosition: 'right',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    ...GlobalStyles.borderRadius,
    ...GlobalStyles.lightBorder,
    [theme.breakpoints.down('lg')]: {
      margin: `0 -${LAYOUT_LEFT_RIGHT_CONTENT_PADDING_LG}`,
    },
    [theme.breakpoints.down('md')]: {
      margin: `0 -${LAYOUT_LEFT_RIGHT_CONTENT_PADDING_MD}`,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      margin: '0 -1rem',
    },
  },
  video: {
    height: '115%',
    top: '-42px',
    left: '-2px',
    position: 'absolute',
    clipPath: 'inset(1px 1px)',
    '@media (max-width: 1780px)': {
      left: '-166px',
    },
    '@media (max-width: 1400px)': {
      left: '-366px',
    },
    '@media (max-width: 1140px)': {
      left: '-466px',
    },
    [theme.breakpoints.up(2400)]: {
      width: '-webkit-fill-available',
      height: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      left: 'auto',
      right: '-900px',
      top: '-2px',
    },
  },
  content: {
    position: 'relative',
    maxWidth: '43rem',
    padding: '6.375rem 0 9.25rem 8.125rem',
    [theme.breakpoints.down('lg')]: {
      padding: '6.375rem 0 9.25rem 4.125rem',
    },
    [theme.breakpoints.down('md')]: {
      padding: '6.375rem 0 9.25rem 2.125rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '1.875rem 1rem 115px 1rem',
    },
  },
  subtitle: {
    margin: '0.625rem 0 3.25rem',
    color: Colors.lightBlack,
    [theme.breakpoints.down('xs')]: {
      margin: '19px 117px 0 0',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  clockContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  clockIcon: {
    margin: '0 0.375rem 0 1.5rem',
    height: '1.25rem',
    width: '1.25rem',
    backgroundImage: `url(${ClockIcon})`,
    ...GlobalStyles.icon,
    [theme.breakpoints.down('xs')]: {
      margin: '0 6px 0 11px',
    },
  },
}));

export const MainBanner: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onConnectButtonClick = () => {
    redirectToProject();
  };

  return (
    <div className={classes.root}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video className={classes.video} autoPlay muted playsInline>
        <track kind="captions" />
        <source src={Video} type="video/mp4" />
      </video>
      <div className={classes.content}>
        <div>
          <Typography variant="h1">{t('Защита от DDoS, взлома и ботов')}</Typography>
          <Typography variant="subtitle1" component="p" className={classes.subtitle}>
            {t(
              'Надежная защита от всех видов DDoS-атак без переноса сайта. Фильтруем атаки более 1.5Tbps.',
            )}
          </Typography>
        </div>

        <div className={classes.container}>
          <Button onClick={onConnectButtonClick}>{t('Подключиться')}</Button>
          <div className={classes.container}>
            <div className={classes.clockIcon} />
            <Typography variant="body2" component="p">
              {t('За 5 минут')}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
