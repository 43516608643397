import React from 'react';

const RotateIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 9.25C18.59 9.25 18.25 9.59 18.25 10C18.25 14.55 14.55 18.25 10 18.25C5.45 18.25 1.75 14.55 1.75 10C1.75 5.45 5.45 1.75 10 1.75C11.98 1.75 13.89 2.46 15.39 3.76C15.31 3.99 15.25 4.24 15.25 4.5C15.25 5.74 16.26 6.75 17.5 6.75C18.74 6.75 19.75 5.74 19.75 4.5C19.75 3.26 18.74 2.25 17.5 2.25C17.07 2.25 16.67 2.38 16.32 2.59C14.56 1.08 12.32 0.25 10 0.25C4.62 0.25 0.25 4.62 0.25 10C0.25 15.38 4.62 19.75 10 19.75C15.38 19.75 19.75 15.38 19.75 10C19.75 9.59 19.41 9.25 19 9.25ZM17.5 3.75C17.91 3.75 18.25 4.09 18.25 4.5C18.25 4.91 17.91 5.25 17.5 5.25C17.09 5.25 16.75 4.91 16.75 4.5C16.75 4.09 17.09 3.75 17.5 3.75Z"
        fill="currentColor"
      />
      <path
        d="M10 4.25C6.83 4.25 4.25 6.83 4.25 10C4.25 13.17 6.83 15.75 10 15.75C13.17 15.75 15.75 13.17 15.75 10C15.75 6.83 13.17 4.25 10 4.25ZM10 14.25C7.66 14.25 5.75 12.34 5.75 10C5.75 7.66 7.66 5.75 10 5.75C12.34 5.75 14.25 7.66 14.25 10C14.25 12.34 12.34 14.25 10 14.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RotateIcon;
