import React from 'react';
import { Button, Grid, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BasePopup from '../BasePopup';
import { tcpServiceLinks, webServiceLinks } from '../../../Home/UpperBlock/UpperBlockConstants';
import ProtectIcon from '../../../../assets/icons/protect-black.svg';
import { ProjectPath } from '../../../../constants/ProjectPath';
import EarthIcon from '../../../../assets/icons/earth-black.svg';
import { ServiceCard } from './ServiceCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    gap: '1.25rem',
    flexWrap: 'nowrap',
    minWidth: '53rem',
    [theme.breakpoints.down(850)]: {
      minWidth: 'auto',
      flexWrap: 'wrap',
      width: '100%',
    },
  },
}));

type TProps = {
  onClose: () => void;
  onDontKnowButtonClick: () => void;
  onClickSite: () => void;
  onClickService: () => void;
};

const ServicePopup: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { onClose, onDontKnowButtonClick, onClickSite, onClickService } = props;
  const { t } = useTranslation();

  return (
    <BasePopup
      title="Выберите услугу"
      onClose={onClose}
      content={
        <Grid className={classes.root} container>
          <Grid item xs={12} sm={12}>
            <ServiceCard
              onClose={onClickService}
              title="Защита TCP-сервиса"
              subtitle={
                'Удаленная защита для ваших TCP сервисов.\nМоментально блокирует любые DDoS-атаки.\nЗначительно повышает отказоустойчивость вашего сервиса.\n\nТочки фильтрации на выбор: Россия или Европа.\n'
              }
              links={tcpServiceLinks}
              icon={ProtectIcon}
              url={ProjectPath.PortsProtection}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ServiceCard
              onClose={onClickSite}
              title="Защита веб-сайтов"
              subtitle={
                'Надежная защита от всех видов DDoS-атак без переноса сайта. Благодаря машинному обучению блокируются только вредоносные запросы.\n\nУскоряет сайт, защищает от ботов, снижает нагрузку.\nПодходит для малого, среднего и крупного бизнеса.\nТочки фильтрации на выбор: Россия или Европа.\n'
              }
              links={webServiceLinks}
              icon={EarthIcon}
              url={ProjectPath.SiteProtection}
            />
          </Grid>
        </Grid>
      }
      leftButton={
        <Button variant="outlined" onClick={onDontKnowButtonClick}>
          {t('Не знаю')}
        </Button>
      }
      rightButton={<Button onClick={onClose}>{t('Далее')}</Button>}
    />
  );
};

export default ServicePopup;
