import React from 'react';

// not current color
const DownArrowIcon: React.FC = () => {
  return (
    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 1L6.5 6L1.5 1"
        stroke="#3485FF"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownArrowIcon;
