import React from 'react';

const KeyIcon: React.FC = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0.75C4.62 0.75 0.25 5.12 0.25 10.5C0.25 15.88 4.62 20.25 10 20.25C15.38 20.25 19.75 15.88 19.75 10.5C19.75 5.12 15.38 0.75 10 0.75ZM10 18.75C5.45 18.75 1.75 15.05 1.75 10.5C1.75 5.95 5.45 2.25 10 2.25C14.55 2.25 18.25 5.95 18.25 10.5C18.25 15.05 14.55 18.75 10 18.75Z"
        fill="#4577FA"
      />
      <path
        d="M15 9.75H9.63C9.3 8.6 8.25 7.75 7 7.75C5.48 7.75 4.25 8.98 4.25 10.5C4.25 12.02 5.48 13.25 7 13.25C8.25 13.25 9.3 12.4 9.63 11.25H11.25V12.5C11.25 12.91 11.59 13.25 12 13.25C12.41 13.25 12.75 12.91 12.75 12.5V11.25H14.25V12.5C14.25 12.91 14.59 13.25 15 13.25C15.41 13.25 15.75 12.91 15.75 12.5V10.5C15.75 10.09 15.41 9.75 15 9.75ZM7 11.75C6.31 11.75 5.75 11.19 5.75 10.5C5.75 9.81 6.31 9.25 7 9.25C7.69 9.25 8.25 9.81 8.25 10.5C8.25 11.19 7.69 11.75 7 11.75Z"
        fill="#4577FA"
      />
    </svg>
  );
};

export default KeyIcon;
