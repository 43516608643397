import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Colors } from '../../../constants/Colors';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    zIndex: 1,
    borderRadius: '0.625rem',
    padding: '0.25rem 0.5rem',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    backgroundColor: Colors.backgroundSecondary,
  },
  indicator: {
    width: '0.375rem',
    height: '0.375rem',
    marginRight: '0.375rem',
    borderRadius: '50%',
  },
  success: {
    background: '#27AE60',
    boxShadow: '0px 0px 4px 1px #27AE60',
  },
  error: {
    background: '#EB5757',
    boxShadow: '0px 0px 4px 1px #EB5757',
  },
  none: {
    background: '#E0E0E0',
    boxShadow: '0px 0px 4px 1px #D9D9D9',
  },
  title: {
    color: Colors.darkGray,
    fontSize: '13px',
    lineHeight: '15px',
    letterSpacing: '0.01em',
  },
});

export enum StatusEnum {
  Error = 'Ошибка',
  Success = 'OK',
  None = 'н/д',
}

type TStatusProps = {
  status: StatusEnum;
};

export const Status: React.FC<TStatusProps> = (props: TStatusProps) => {
  const classes = useStyles();
  const { status } = props;

  const getIndicatorColor = () => {
    switch (status) {
      case StatusEnum.Success:
        return classes.success;
      case StatusEnum.Error:
        return classes.error;
      default:
        return classes.none;
    }
  };

  return (
    <div className={classes.root}>
      <div className={`${classes.indicator} ${getIndicatorColor()}`} />
      <Typography variant="h5" component="div" className={classes.title}>
        {status}
      </Typography>
    </div>
  );
};
