import React, { useRef, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { Colors } from '../../../../constants/Colors';
import { GlobalStyles } from '../../../../styles/GlobalStyles';
import Video from '../../../../assets/target-video.mp4';
import { XsHowProtectionWorksMainBanner } from './XsHowProtectionWorksMainBanner';
import ProtectionBanner from '../../../../assets/backgrounds/target.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '16.625rem',
    backgroundColor: Colors.backgroundSecondary,
    overflow: 'hidden',
    ...GlobalStyles.borderRadius,
  },
  videoRoot: {
    height: 'auto',
    position: 'relative',
  },
  videoDesktop: {
    width: '100%',
    clipPath: 'inset(1px 1px)',
  },
  grid: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  desktopVideoContainer: {
    height: '200%',
    width: '120%',
    margin: '-130px 0 0 -170px',
    borderRadius: '1500px',
    backgroundPosition: 'right',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${ProtectionBanner})`,
  },

  mobileVideoContainer: {
    height: '115px',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    ...GlobalStyles.borderRadius,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  videoMobile: {
    top: '-2px',
    left: 0,
    position: 'absolute',
  },
  content: {
    height: '100%',
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    justifyContent: 'space-between',
    padding: '1.875rem 2.5rem 1.875rem 1rem',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  subtitle: {
    color: Colors.lightBlack,
    marginTop: '0.625rem',
    maxWidth: '309px',
  },
  desktopButton: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mobileButton: {
    position: 'absolute',
    bottom: '1rem',
    right: '1rem',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  closeButton: {
    zIndex: 2,
    position: 'absolute',
    top: '2rem',
    right: '2rem',
    backgroundColor: Colors.white,
    color: Colors.primary,
    '&:hover': {
      color: '#ffffff',
      opacity: 1,
      backgroundColor: Colors.primary,
    },
  },
  videoHidden: {
    visibility: 'hidden',
    position: 'absolute',
    top: '-10000px',
    width: '100%',
  }
}));

export const LgHowProtectionWorksBanner: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const laptop = useMediaQuery(theme.breakpoints.down('sm'));
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [videoHeight, setVideoHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const ref = useRef(null);
  const [hasAnimationClass, setHasAnimationClass] = useState<boolean>(false);

  const onWatchButtonClick = () => {
    setHasAnimationClass(true);
    setTimeout(() => {
      setIsVisible(true);
    }, 500);
  };

  const onModalCloseButtonClick = () => {
    setHasAnimationClass(false);
    setIsVisible(false);
  };

  if (mobile || laptop) {
    return <XsHowProtectionWorksMainBanner />;
  }

  return (
    <div
      className={`${classes.root} ${isVisible ? classes.videoRoot : ''} ${hasAnimationClass ? 'how-works-animation' : ''}`}
      style={{ height: `${hasAnimationClass ? videoHeight : 266}px` }}
      ref={(el) => {setWidth(el?.clientWidth ?? 0)}} >
      <video
        src={Video}
        className={classes.videoHidden}
        controls
        muted
        style={{ width: `${width}px` }}
        ref={(el) => {setVideoHeight(el?.clientHeight ?? 0)}} />
      {isVisible ? (
        <>
          <IconButton className={classes.closeButton} onClick={onModalCloseButtonClick}>
            <CloseIcon />
          </IconButton>
          <video src={Video} className={classes.videoDesktop} autoPlay muted controls />
        </>
      ) : (
        <Grid container className={classes.grid}>
          <Grid item xs={12} sm={7} md={8}>
            <div className={`${classes.desktopVideoContainer} video-banner`} />
          </Grid>
          <Grid item xs={12} sm={5} md={4} className={classes.content}>
            <div>
              <Typography variant="h2" component="div">
                {t('Как работает защита')}
              </Typography>
              <Typography variant="body2" component="p" className={classes.subtitle}>
                {t('Видео презентация наших технологий и подхода. А так же зачем вам защита')}
              </Typography>
            </div>
            <Button
              className={`${classes.desktopButton} action-button`}
              variant="contained"
              onClick={onWatchButtonClick}
            >
              {t('Смотреть')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={7} md={9}>
            <div className={classes.mobileVideoContainer}>
              <video src={Video} className={classes.videoMobile} autoPlay muted />
            </div>
            <Button
              className={classes.mobileButton}
              variant="contained"
              onClick={onWatchButtonClick}
            >
              {t('Смотреть')}
            </Button>
          </Grid>
        </Grid>
      )}
      {/* <VideoModal isVisible={isVisible} onCloseButtonClick={onModalCloseButtonClick} /> */}
    </div>
  );
};
