import React, { ReactNode } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Colors } from '../../constants/Colors';
import { GlobalStyles } from '../../styles/GlobalStyles';
import { ProjectPath } from '../../constants/ProjectPath';
import {
  LAYOUT_LEFT_RIGHT_CONTENT_PADDING_LG,
  LAYOUT_LEFT_RIGHT_CONTENT_PADDING_MD,
} from '../../constants/UI';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  banner: {
    overflow: 'hidden',
    padding: '48px 196px 48px 100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Colors.backgroundSecondary,
    ...GlobalStyles.borderRadius,
    ...GlobalStyles.lightBorder,
    [theme.breakpoints.down('xl')]: {
      margin: `0 -100px`,
    },
    [theme.breakpoints.down(1200)]: {
      margin: `0 -${LAYOUT_LEFT_RIGHT_CONTENT_PADDING_LG}`,
      flexDirection: 'column',
    },
    [theme.breakpoints.down('md')]: {
      margin: `0 -${LAYOUT_LEFT_RIGHT_CONTENT_PADDING_MD}`,
      flexDirection: 'column',
      padding: '48px 50px 48px 50px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      margin: '0 -1rem',
      padding: '22px 16px',
      flexDirection: 'column',
    },
  },
  container: {
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  title: {
    maxWidth: '564px',
  },
  subtitle: {
    fontSize: '19px',
    maxWidth: '456px',
    fontFamily: 'Raleway-Regular',
    color: Colors.lightBlack,
    marginTop: '0.625rem',
    [theme.breakpoints.down(1200)]: {
      marginBottom: '42px',
    },
    [theme.breakpoints.down('xs')]: {
      fontFamily: 'Inter-Regular',
      fontSize: '13px',
      lineHeight: '15.73px',
      marginTop: '19px',
      marginBottom: '42px',
    },
  },
  button: {
    marginTop: '1.625rem',
    [theme.breakpoints.down(1200)]: {
      marginTop: '47px',
    },
  },
  description: {
    marginTop: '77px',
    [theme.breakpoints.down('md')]: {
      marginTop: '50px',
    },
  },
  leftTitle: {
    maxWidth: '414px',
  },
  rightTitle: {
    maxWidth: '305px',
  },
  leftSubtitle: {
    margin: 0,
    paddingTop: '17px',
    maxWidth: '503px',
    padding: '0 0 0 1rem',
    color: Colors.lightBlack,
  },
  rightSubtitle: {
    margin: 0,
    paddingTop: '17px',
    maxWidth: '535px',
    padding: '0 0 0 1rem',
    color: Colors.lightBlack,
  },
  text: {
    color: Colors.lightBlack,
  },
  leftButton: {
    marginRight: '1.5rem',
  },
}));

type TProps = {
  title: string;
  subtitle: string;
  buttonText: string;
  leftButtonText?: string;
  leftTitle: string;
  leftSubtitle: string[];
  rightTitle: string;
  rightSubtitle: string[];
  content?: ReactNode;
};

const ErrorWithDescriptionBanner: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const {
    title,
    subtitle,
    leftButtonText,
    buttonText,
    leftTitle,
    leftSubtitle,
    rightTitle,
    rightSubtitle,
    content,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down(1200));

  const onHomeButtonClick = () => {
    history.push(ProjectPath.Index);
  };

  const renderSubtitles = (array: string[], isLeft: boolean) => {
    if (array.length === 1) {
      return (
        <Typography
          variant="h6"
          component="p"
          className={`${isLeft ? classes.leftSubtitle : classes.rightSubtitle} ${classes.text}`}
        >
          {t(array[0])}
        </Typography>
      );
    }
    return (
      <ol className={`${isLeft ? classes.leftSubtitle : classes.rightSubtitle}`}>
        {leftSubtitle.map((item) => {
          return (
            <li key={item}>
              <Typography variant="h6" component="p" className={classes.text}>
                {t(item)}
              </Typography>
            </li>
          );
        })}
      </ol>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.banner}>
        <div className={classes.container}>
          <div>
            <Typography variant="h1" className={classes.title}>
              {t(title)}
            </Typography>
            <Typography variant="h3" component="div" className={classes.subtitle}>
              {t(subtitle)}
            </Typography>
          </div>
          {!downLg && leftButtonText && (
            <Button
              variant="text"
              className={`${classes.button} ${classes.leftButton}`}
              onClick={onHomeButtonClick}
            >
              {t(leftButtonText)}
            </Button>
          )}
          {!downLg && (
            <Button variant="contained" className={classes.button} onClick={onHomeButtonClick}>
              {t(buttonText)}
            </Button>
          )}
        </div>
        {content}
        {downLg && leftButtonText && (
          <Button
            variant="text"
            className={`${classes.button} ${classes.leftButton}`}
            onClick={onHomeButtonClick}
          >
            {t(leftButtonText)}
          </Button>
        )}
        {downLg && (
          <Button variant="contained" className={classes.button} onClick={onHomeButtonClick}>
            {t(buttonText)}
          </Button>
        )}
      </div>
      <div className={classes.description}>
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" component="div" className={classes.leftTitle}>
              {t(leftTitle)}
            </Typography>
            {renderSubtitles(leftSubtitle, true)}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" component="div" className={classes.rightTitle}>
              {t(rightTitle)}
            </Typography>
            {renderSubtitles(rightSubtitle, false)}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ErrorWithDescriptionBanner;
