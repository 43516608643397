import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { Fade } from 'react-reveal';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/ui/Layout/Layout';
import { HowPartnersWorksBlock } from '../../components/Partners/HowParternsWorksBlock/HowPartnersWorksBlock';
import { FaqBlock } from '../../components/Home/FaqBlock/FaqBlock';
import { partnersFaqData } from '../../components/Home/FaqBlock/FaqBlockConstants';
import { DirectionsBlock } from '../../components/Partners/DirectionsBlock/DirectionsBlock';
import { AboutBrandBlock } from '../../components/Partners/AboutBrandBlock/AboutBrandBlock';
import { ProfitSwiper } from '../../components/Partners/ProfitBlock/ProfitSwiper';
import PartnersBackground from '../../assets/partners/partners-background.png';
import { PartnersBanner } from '../../components/ui/Banners/PartnersBanner';
import { redirectToProject } from '../../utils/redirectToProject';

const useStyles = makeStyles((theme: Theme) => ({
  howItWorksBlock: {
    marginTop: '5.875rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '50px',
    },
  },
  directionsBlock: {
    marginTop: '8.125rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '60px',
    },
  },
  profitBlock: {
    marginTop: '8.125rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '50px',
    },
  },
  aboutBrandBlock: {
    marginTop: '8.125rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '50px',
    },
  },
  faqBlock: {
    marginTop: '8.125rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '50px',
    },
  },
}));

const PartnersPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onPartnersButtonClick = () => {
    redirectToProject();
  };

  return (
    <Layout>
      <PartnersBanner
        title={t('Реферальная программа')}
        subtitle={t(
          'Рекомендуйте наши сервисы  новым клиентам и зарабатывайте. Продвигайте сервисы, делитесь реферальными ссылками и получайте ______ от оплат ваших клиентов.',
        )}
        background={PartnersBackground}
        leftButton={<Button onClick={onPartnersButtonClick}>{t('Стать партнером')}</Button>}
        rightButton={<></>}
      />
      <div className={classes.howItWorksBlock}>
        <Fade>
          <HowPartnersWorksBlock />
        </Fade>
      </div>
      <div className={classes.directionsBlock}>
        <Fade>
          <DirectionsBlock />
        </Fade>
      </div>
      <div className={classes.profitBlock}>
        <Fade>
          <ProfitSwiper />
        </Fade>
      </div>
      <div className={classes.aboutBrandBlock}>
        <Fade>
          <AboutBrandBlock />
        </Fade>
      </div>
      <div className={classes.faqBlock}>
        <Fade>
          <FaqBlock items={partnersFaqData} />
        </Fade>
      </div>
    </Layout>
  );
};

export default withRouter(PartnersPage);
