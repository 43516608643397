import React, { useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-reveal';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { Colors } from '../../../constants/Colors';
import { portsRatesConstants } from './PortsRatesConstans';
import { PortsRateCard } from './PortsRateCard';
import { PortsSwiper } from './PortsRatesSwiper';
import RateCard from '../RateCard';
import { certificates, protectedDns } from '../../Home/UpperBlock/UpperBlockConstants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(760)]: {
      alignItems: 'initial',
      flexDirection: 'column',
    },
  },
  button: {
    '&.MuiButton-root': {
      height: '2.25rem',
      width: '7.875rem',
      padding: '0.625rem 1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      fontFamily: 'Inter-Regular',
      fontSize: '15px',
      lineHeight: '18.15px',
      '&:hover': {
        opacity: 1,
        color: Colors.primary,
        backgroundColor: 'rgba(69, 119, 250, 0.12)',
        ...GlobalStyles.lightBorder,
      },
      '&.MuiButton-outlined': {
        color: Colors.darkGray,
        ...GlobalStyles.lightBorder,
        '&:hover': {
          opacity: 1,

          color: Colors.primary,
          backgroundColor: 'rgba(69, 119, 250, 0.12)',
          ...GlobalStyles.lightBorder,
        },
      },
    },
  },
  activeButton: {
    '&.MuiButton-root': {
      '&.MuiButton-outlined': {
        color: Colors.primary,
        backgroundColor: 'rgba(69, 119, 250, 0.12)',
      },
    },
  },
  buttonText: {
    color: Colors.accent,
  },
  buttonsGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.625rem',
    [theme.breakpoints.down(760)]: {
      marginTop: '2rem',
      display: '-webkit-inline-box',
      overflow: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '2rem',
      display: '-webkit-inline-box',
      overflow: 'scroll',
      margin: '2rem -1rem 0',
      paddingLeft: '1rem',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  ratesBlock: {
    overflow: 'hidden',
    marginTop: '1.625rem',
    padding: '1.875rem 1.875rem 58px 1.875rem',
    backgroundColor: Colors.backgroundSecondary,
    ...GlobalStyles.borderRadius,
    [theme.breakpoints.down('xs')]: {
      margin: '1.625rem -2rem 0',
      borderRadius: 0,
    },
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'unset',
    gap: '1.25rem',
  },
  gridInfoItem: {
    display: 'flex',
    marginTop: '3rem',
    gap: '1.25rem',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  bottomGrid: {
    marginTop: '1.875rem',
    display: 'flex',
    flexWrap: 'unset',
    gap: '1.25rem',
    [theme.breakpoints.down(900)]: {
      flexWrap: 'wrap',
    },
  },
  list: {
    fontFamily: 'Inter-Regular',
    padding: '0 0 0 1rem',
    color: Colors.lightBlack,
    [theme.breakpoints.down('md')]: {
      margin: '1em 0',
    },
    [theme.breakpoints.down('xs')]: {
      '& li': {
        fontSize: '0.6em',
        marginBottom: '4px',
        color: Colors.fontColor,
      },
    },
  },
  subtitle: {
    fontFamily: 'Inter-Regular',
    color: Colors.newBlack,
    [theme.breakpoints.down('xs')]: {
      color: Colors.fontColor,
      fontSize: '15px',
      lineHeight: '17.61px',
    },
  },
  rateCard: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

type TRateButton = {
  month: number;
  title: string;
  percent?: string;
};

const rateButtonsList: TRateButton[] = [
  {
    month: 0,
    title: 'Месяц',
  },
  {
    month: 1,
    title: '3 мес.',
    percent: '-5%',
  },
  {
    month: 2,
    title: '6 мес.',
    percent: '-15%',
  },
  {
    month: 3,
    title: 'За год',
    percent: '-29%',
  },
];

const subtitles: string[] = [
  'Нет ограничений по типам, количеству и мощности DDoS-атак',
  'Выделенный защищенный IP-адрес',
  'Оптимизация TCP соединений',
];

export const PortsRatesBlock: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeMonth, setActiveMonth] = useState<number>(1);
  const theme = useTheme();
  const isSwiperRendered = useMediaQuery(theme.breakpoints.down(1072));

  const renderButton = (item: TRateButton, index: number) => {
    return (
      <Button
        className={`${classes.button} ${activeMonth === item.month && classes.activeButton}`}
        variant="outlined"
        key={index}
        onClick={() => setActiveMonth(item.month)}
      >
        {t(item.title)}
        {item.percent && (
          <Typography variant="h5" component="div" className={classes.buttonText}>
            {item.percent}
          </Typography>
        )}
      </Button>
    );
  };

  const renderSwiper = () => {
    if (isSwiperRendered) {
      return <PortsSwiper activeMonth={activeMonth} />;
    }
    return portsRatesConstants.map((card, index) => {
      return (
        <Grid item xs={4} key={index}>
          <PortsRateCard item={card} activeMonth={activeMonth} />
        </Grid>
      );
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h2">{t('Тарифы')}</Typography>
        <div className={classes.buttonsGroup}>
          {rateButtonsList.map((item, index) => renderButton(item, index))}
        </div>
      </div>
      <div className={classes.ratesBlock}>
        <Fade>
          <Grid container className={classes.grid}>
            {renderSwiper()}
          </Grid>
          <Grid container className={classes.bottomGrid}>
            <Grid item xs={12} md={4}>
              <div>
                <Typography variant="h3" component="div">
                  {t('Во всех тарифах:')}
                </Typography>
                <ul className={classes.list}>
                  {subtitles.map((item, index) => {
                    return (
                      <li key={index}>
                        <Typography variant="h6" component="p" className={classes.subtitle}>
                          {t(item)}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} md={4} className={classes.rateCard}>
              <RateCard
                link={certificates}
                subtitle="Поддержка SSL/TLS 1.2&1.3 шифрования Поддержка HTTP/2 и SPDY. Установка собственного сертификата. Бесплатный сертификат от Let`s Encrypt "
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.rateCard}>
              <RateCard
                link={protectedDns}
                subtitle="Управление черными/белыми списками
  Кеширование статического контента
  Оптимизация соединений и общее ускорение
  "
              />
            </Grid>
          </Grid>
        </Fade>
      </div>
    </div>
  );
};
