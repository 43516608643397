import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { SwiperButtonsContainer } from '../../ui/Buttons/SwiperButtonsContainer';
import { Colors } from '../../../constants/Colors';
import { ProfitCard } from './ProfitCard';
import {
  LAYOUT_LEFT_RIGHT_PADDING_LG,
  LAYOUT_LEFT_RIGHT_PADDING_MD,
  LAYOUT_LEFT_RIGHT_PADDING_XL,
  LAYOUT_LEFT_RIGHT_PADDING_XS,
} from '../../../constants/UI';
import { profitConstants } from './ProfitConstants';
import { GlobalStyles } from '../../../styles/GlobalStyles';

SwiperCore.use([Navigation, Pagination]);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '26px',
        lineHeight: '29px',
      },
    },
    subtitle: {
      color: Colors.lightBlack,
      maxWidth: '27.25rem',
      marginTop: '0.25rem',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    swiperContainer: {
      marginTop: '1.625rem',
      position: 'relative',
      '& .swiper-pagination': {
        backgroundColor: Colors.backgroundSecondary,
      },
    },
    swiperBackground: {
      position: 'absolute',
      backgroundColor: Colors.backgroundSecondary,
      top: 0,
      bottom: 0,
      left: `-${LAYOUT_LEFT_RIGHT_PADDING_XL}`,
      right: `-${LAYOUT_LEFT_RIGHT_PADDING_XL}`,
      [theme.breakpoints.down('lg')]: {
        left: `-${LAYOUT_LEFT_RIGHT_PADDING_LG}`,
        right: `-${LAYOUT_LEFT_RIGHT_PADDING_LG}`,
      },
      [theme.breakpoints.down('md')]: {
        left: `-${LAYOUT_LEFT_RIGHT_PADDING_MD}`,
        right: `-${LAYOUT_LEFT_RIGHT_PADDING_MD}`,
      },
      [theme.breakpoints.down('xs')]: {
        left: `-${LAYOUT_LEFT_RIGHT_PADDING_XS}`,
        right: `-${LAYOUT_LEFT_RIGHT_PADDING_XS}`,
      },
    },
    swiper: {
      overflow: 'initial',
      '& .swiper-wrapper': {
        boxSizing: 'inherit',
      },
      '& .swiper-pagination': {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
          display: 'block',
          left: 0,
          bottom: '1.875rem',
          backgroundColor: 'transparent',
        },
      },
      '& .swiper-pagination-bullet': {
        height: '0.75rem',
        width: '0.75rem',
        backgroundColor: Colors.borderColor,
        opacity: 0.3,
      },
      '& .swiper-pagination-bullet-active': {
        height: '0.75rem',
        width: '0.75rem',
        backgroundColor: Colors.borderColor,
        opacity: 1,
      },
      ...GlobalStyles.slides,
    },
    slide: {
      height: '100%',
      padding: '1.875rem 0 1.875rem 0',
      [theme.breakpoints.down('xs')]: {
        padding: '1.875rem 0 4.5rem 0',
      },
    },
  }),
);

export const ProfitSwiper: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const profitPrevButtonClass = 'profit-prev-button';
  const profitNextButtonClass = 'profit-next-button';

  const renderSlides = () => {
    return profitConstants.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <div className={classes.slide}>
            <ProfitCard item={item} />
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          <Typography variant="h2" className={classes.title}>
            {t('Выгоды')}
          </Typography>
          <Typography variant="body2" component="p" className={classes.subtitle}>
            {t(
              'Каждый привлеченный клиент, будет приносить Вам пассивный ежемесячный доход в размере 50%* от каждого платежа',
            )}
          </Typography>
        </div>
        <SwiperButtonsContainer
          prevButtonClass={profitPrevButtonClass}
          nextButtonClass={profitNextButtonClass}
        />
      </div>
      <div className={classes.swiperContainer}>
        <div className={classes.swiperBackground} />
        <Swiper
          className={classes.swiper}
          spaceBetween={20}
          slidesPerView={3}
          navigation={{
            prevEl: `.${profitPrevButtonClass}`,
            nextEl: `.${profitNextButtonClass}`,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            800: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
          }}
          direction="horizontal"
          pagination={{ clickable: true }}
        >
          {renderSlides()}
        </Swiper>
      </div>
    </div>
  );
};
