import { TechnologyCardProps } from './TechnologyCard';
import FingerPrintIcon from '../../ui/Icons/FingerPrintIcon';
import SpeedIcon from '../../ui/Icons/SpeedIcon';
import DiamondIcon from '../../ui/Icons/DiamondIcon';

export const technologiesConstants: TechnologyCardProps[] = [
  {
    subtitle:
      'Осуществляет постоянный мониторинг всего поступающего HTTP-трафика, при помощи машинного обучения формирует «образы» сайтов, на основании собранной статистики —  управляет фильтрами DDoS-защиты\n',
    link: {
      title: 'Анализ GreatEey',
      url: '',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <FingerPrintIcon />,
    },
  },
  {
    subtitle:
      'Технология ускорения загрузки сайтов, кеширование и сжатие. Все статические файлы раздаваемые с наших серверов - сжимаются с помощью gzip, Brotli, помещаются в оперативный кеш сервера точки фильтрации',
    link: {
      title: 'FastL',
      url: '',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <SpeedIcon />,
    },
  },
  {
    subtitle:
      'Плагин, позволяющих проводить комплексный анализ и блокировку вредоносных HTTP запросов по различным критериям: соответствие RFC, наличие IP в блек-листах, запросы от неизвестных роботов, аномальная активность, превышение заданных клиентом лимитов...',
    link: {
      title: 'Adamant',
      url: '',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <DiamondIcon />,
    },
  },
  {
    subtitle:
      'Осуществляет постоянный мониторинг всего поступающего HTTP-трафика, при помощи машинного обучения формирует «образы» сайтов, на основании собранной статистики —  управляет фильтрами DDoS-защиты\n',
    link: {
      title: 'Анализ GreatEey',
      url: '',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <FingerPrintIcon />,
    },
  },
  {
    subtitle:
      'Технология ускорения загрузки сайтов, кеширование и сжатие. Все статические файлы раздаваемые с наших серверов - сжимаются с помощью gzip, Brotli, помещаются в оперативный кеш сервера точки фильтрации',
    link: {
      title: 'FastL',
      url: '',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <SpeedIcon />,
    },
  },
];
