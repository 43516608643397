import { CustomLinkProps } from '../ui/Link/CustomLink';
import FingerPrintIcon from '../ui/Icons/FingerPrintIcon';
import SpeedIcon from '../ui/Icons/SpeedIcon';
import DiamondIcon from '../ui/Icons/DiamondIcon';
import ClockIcon from '../ui/Icons/ClockIcon';
import OracleIcon from '../ui/Icons/OracleIcon';
import HttpIcon from '../ui/Icons/HttpIcon';
import EncryptIcon from '../ui/Icons/EncryptIcon';
import NginxIcon from '../ui/Icons/NginxIcon';
import SslIcon from '../ui/Icons/SslIcon';
import RotateIcon from '../ui/Icons/RotateIcon';
import LockIcon from '../ui/Icons/LockIcon';
import ArborIcon from '../ui/Icons/ArborIcon';
import CiscoIcon from '../ui/Icons/CiscoIcon';

export const siteActiveProtectionLinks: CustomLinkProps[] = [
  {
    title: 'Анализ GreatEey',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <FingerPrintIcon />,
  },
  {
    title: 'FastL',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <SpeedIcon />,
  },
  {
    title: 'Adamant',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <DiamondIcon />,
  },
  {
    title: 'Пробный период бесплатно',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <ClockIcon />,
  },
];

export const portsActiveProtectionLinks: CustomLinkProps[] = [
  {
    title: 'SafeWall',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <LockIcon />,
  },
  {
    title: 'QResp',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <RotateIcon />,
  },
];

export const siteInactiveProtectionLinks: CustomLinkProps[] = [
  {
    title: 'OracleLinux',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <OracleIcon />,
  },
  {
    title: 'HTTP2/0',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <HttpIcon />,
  },
  {
    title: 'Let`s Encrypt',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <EncryptIcon />,
  },
  {
    title: 'Nginx',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <NginxIcon />,
  },
  {
    title: 'OpenSSL',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <SslIcon />,
  },
];

export const portsInactiveProtectionLinks: CustomLinkProps[] = [
  {
    title: 'OracleLinux',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <OracleIcon />,
  },
  {
    title: 'Arbor',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <ArborIcon />,
  },
  {
    title: 'Cisco',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <CiscoIcon />,
  },
];
