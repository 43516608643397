export enum ProjectPath {
  Index = '/',
  ProtectedDns = '/protected-dns',
  Certificates = '/certificates',
  About = '/about',
  SiteProtection = '/site-protection',
  PortsProtection = '/ports-protection',
  Tariffs = '/tariffs',
  Profile = '/profile',
  TechnicalSupport = '/technical-support',
  KnowledgeBase = '/knowledge-base',
  Blog = '/blog',
  Stocks = '/blog',
  News = '/blog',
  Faq = '/faq',
  Partners = '/partners',
  Politics = '/politics',
  ServerError = '/505',
  GatewayError = '/502',
  AnotherGatewayError = '/502-description',
  StopError = '/507',
  AnotherError = '/417',
  AnotherServerError = '/505-description',
  AccessError = '/508',
  RequirementError = '/requirement',
}
