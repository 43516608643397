import React, { useState } from 'react';
import { makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import TooltipIcon from '../../../assets/icons/tooltip.svg';
import { Colors } from '../../../constants/Colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '11.38px',
    width: '11.38px',
    marginLeft: '0.5rem',
    backgroundImage: `url(${TooltipIcon})`,
    position: 'relative',
    ...GlobalStyles.icon,
    '&:hover': {
      '& div': {
        display: 'block',
      },
      cursor: 'pointer',
    },
  },
  tooltip: {
    width: '18.25rem',
    display: 'none',
    bottom: '1.25rem',
    position: 'absolute',
    zIndex: 2,
    padding: '1.25rem',
    border: '1px solid #FD9A3F',
    borderRadius: '0.625rem',
    backgroundColor: Colors.white,
    [theme.breakpoints.down(1400)]: {
      width: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      left: '-8rem',
    },
  },
}));

type TProps = {
  isDisabled?: boolean;
  text: string;
};

export const Tooltip: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { text, isDisabled } = props;
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState<boolean>(false);
  const theme = useTheme();
  const isUnderLaptop = useMediaQuery(theme.breakpoints.down('sm'));

  if (isUnderLaptop) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className={classes.root} onClick={() => setIsShow(true)}>
        {!isDisabled && isShow && (
          <div className={classes.tooltip}>
            <Typography variant="body2" component="p">
              {text}
            </Typography>
          </div>
        )}
      </div>
    );
  }
  return (
    <div className={classes.root}>
      {!isDisabled && (
        <div className={classes.tooltip}>
          <Typography variant="body2" component="p">
            {text}
          </Typography>
        </div>
      )}
    </div>
  );
};
