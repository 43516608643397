import React from 'react';

const NewsIcon: React.FC = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.25 18.08V7.51999C20.37 7.38999 21.25 6.44999 21.25 5.29999C21.25 4.05999 20.24 3.04999 19 3.04999H6C4.76 3.04999 3.75 4.05999 3.75 5.29999C3.75 6.44999 4.63 7.39999 5.75 7.51999V18.07C4.63 18.2 3.75 19.14 3.75 20.29C3.75 21.53 4.76 22.54 6 22.54H19C20.24 22.54 21.25 21.53 21.25 20.29C21.25 19.14 20.37 18.19 19.25 18.07V18.08ZM7.25 18.05V7.54999H17.75V18.05H7.25ZM5.25 5.29999C5.25 4.88999 5.59 4.54999 6 4.54999H19C19.41 4.54999 19.75 4.88999 19.75 5.29999C19.75 5.70999 19.41 6.04999 19 6.04999H6C5.59 6.04999 5.25 5.70999 5.25 5.29999ZM19 21.05H6C5.59 21.05 5.25 20.71 5.25 20.3C5.25 19.89 5.59 19.55 6 19.55H19C19.41 19.55 19.75 19.89 19.75 20.3C19.75 20.71 19.41 21.05 19 21.05Z"
        fill="#4577FA"
      />
      <path
        d="M9.5 11.55H15.5C15.91 11.55 16.25 11.21 16.25 10.8C16.25 10.39 15.91 10.05 15.5 10.05H9.5C9.09 10.05 8.75 10.39 8.75 10.8C8.75 11.21 9.09 11.55 9.5 11.55Z"
        fill="#4577FA"
      />
      <path
        d="M15.5 14.05H9.5C9.09 14.05 8.75 14.39 8.75 14.8C8.75 15.21 9.09 15.55 9.5 15.55H15.5C15.91 15.55 16.25 15.21 16.25 14.8C16.25 14.39 15.91 14.05 15.5 14.05Z"
        fill="#4577FA"
      />
    </svg>
  );
};

export default NewsIcon;
