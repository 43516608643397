import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { requisitesConstants } from './ContactsRequisitesConstants';
import { Colors } from '../../../constants/Colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  requisiteItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0.875rem',
  },
  header: {
    marginBottom: '1rem',
  },
  title: {
    color: Colors.darkGray,
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
      lineHeight: '17.61px',
    },
  },
  subtitle: {
    textAlign: 'end',
    color: Colors.lightBlack,
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
      lineHeight: '17.61px',
    },
  },
}));

export const RequisitesItem: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderRequisites = () => {
    return requisitesConstants.map((item, index) => {
      return (
        <div key={index} className={classes.requisiteItem}>
          <Typography className={classes.title} variant="h6" component="p">
            {t(item.title)}:
          </Typography>
          <Typography className={classes.subtitle} variant="h6" component="p">
            {t(item.subtitle)}
          </Typography>
        </div>
      );
    });
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.header} variant="h4" component="div">
        {t('Реквизиты')}:
      </Typography>
      {renderRequisites()}
    </div>
  );
};
