import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HowPartnersWorksCard } from './HowPartnersWorksCard';
import { howPartnersWorksBlockConstants } from './HowPartnersWorksBlockConstants';

const useStyles = makeStyles({
  grid: {
    marginTop: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
  },
});

export const HowPartnersWorksBlock: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h2">{t('Как работает')}</Typography>
      <Grid spacing={5} container className={classes.grid}>
        {howPartnersWorksBlockConstants.map((item) => {
          return (
            <Grid item xs={12} sm={8} md={7} lg={4} key={item.id}>
              <HowPartnersWorksCard item={item} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
