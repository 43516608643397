import React from 'react';

const ProfileIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0.25C4.62 0.25 0.25 4.62 0.25 10C0.25 15.38 4.62 19.75 10 19.75C15.38 19.75 19.75 15.38 19.75 10C19.75 4.62 15.38 0.25 10 0.25ZM5.69 17.02C6.67 15.61 8.28 14.75 10 14.75C11.72 14.75 13.33 15.6 14.32 17.02C13.06 17.8 11.59 18.25 10 18.25C8.41 18.25 6.95 17.8 5.69 17.02ZM15.51 16.12C14.24 14.32 12.2 13.25 10 13.25C7.8 13.25 5.75 14.34 4.49 16.12C2.81 14.61 1.75 12.43 1.75 10C1.75 5.45 5.45 1.75 10 1.75C14.55 1.75 18.25 5.45 18.25 10C18.25 12.43 17.19 14.61 15.51 16.12Z"
        fill="currentColor"
      />
      <path
        d="M10 4.25C7.93 4.25 6.25 5.93 6.25 8C6.25 10.07 7.93 11.75 10 11.75C12.07 11.75 13.75 10.07 13.75 8C13.75 5.93 12.07 4.25 10 4.25ZM10 10.25C8.76 10.25 7.75 9.24 7.75 8C7.75 6.76 8.76 5.75 10 5.75C11.24 5.75 12.25 6.76 12.25 8C12.25 9.24 11.24 10.25 10 10.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ProfileIcon;
