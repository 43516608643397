import React, { useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { Colors } from '../../../../constants/Colors';
import { GlobalStyles } from '../../../../styles/GlobalStyles';
import { XsHowProtectionWorksBanner } from './XsHowProtectionBanner';
import ProtectionBanner from '../../../../assets/backgrounds/target.png';
import Video from '../../../../assets/target-video.mp4';

const useStyles = makeStyles({
  root: {
    marginTop: '12px',
    height: '5rem',
    backgroundColor: Colors.backgroundSecondary,
    overflow: 'hidden',
    ...GlobalStyles.borderRadius,
  },
  videoRoot: {
    height: 'auto',
    position: 'relative',
  },
  videoDesktop: {
    width: '100%',
    clipPath: 'inset(1px 1px)',
  },
  grid: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  animation: {
    width: '48%',
    height: '100%',
    position: 'absolute',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${ProtectionBanner})`,
    right: 0,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '2.5rem',
  },
  subtitle: {
    color: Colors.lightBlack,
    marginTop: '0.625rem',
  },
  buttonContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: '1.75rem',
  },
  closeButton: {
    zIndex: 2,
    position: 'absolute',
    top: '2rem',
    right: '2rem',
    backgroundColor: Colors.white,
    color: Colors.primary,
  },
});

export const SmHowProtectionWorksBanner: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const onWatchButtonClick = () => {
    setIsVisible(true);
  };

  const onModalCloseButtonClick = () => {
    setIsVisible(false);
  };

  if (mobile) {
    return <XsHowProtectionWorksBanner />;
  }
  return (
    <div className={`${classes.root} ${isVisible ? classes.videoRoot : ''}`}>
      {isVisible ? (
        <>
          <IconButton className={classes.closeButton} onClick={onModalCloseButtonClick}>
            <CloseIcon />
          </IconButton>
          <video src={Video} className={classes.videoDesktop} autoPlay muted controls />
        </>
      ) : (
        <Grid container className={classes.grid}>
          <Grid item sm={5} className={classes.content}>
            <div>
              <Typography variant="h3" component="div">
                {t('Как работает защита')}
              </Typography>
            </div>
          </Grid>
          <Grid item sm={7} className={classes.buttonContainer}>
            <div className={classes.animation} />
            <Button variant="contained" onClick={onWatchButtonClick}>
              {t('Смотреть')}
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
