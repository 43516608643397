import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../constants/Colors';
import { Tooltip } from '../ui/Tooltip/Tooltip';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
  },
  column: {
    display: 'flex',
    alignItems: 'center',
  },
  disabledColumn: {
    opacity: 0.2,
  },
  activeLeft: {
    color: `${Colors.grayLink} !important`,
  },
  left: {
    color: Colors.darkGray,
  },
  right: {},
});

export type TRateRow = {
  left: string;
  leftTooltip?: string;
  right?: string;
  rightTooltip?: string;
  isActive?: boolean;
  isDisabled?: boolean;
};

type TProps = {
  index: number;
  item: TRateRow;
};

export const RateRow: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { index, item } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root} key={index}>
      <div className={`${classes.column} ${item.isDisabled && classes.disabledColumn}`}>
        <Typography
          variant="h5"
          component="div"
          className={`${classes.left} ${item.isActive && classes.activeLeft}`}
        >
          {t(item.left)}
        </Typography>
        {item.leftTooltip && <Tooltip text={item.leftTooltip} isDisabled={item?.isDisabled} />}
      </div>
      {item.right && (
        <div className={classes.column}>
          <Typography variant="h5" component="div" className={classes.right}>
            {t(item.right)}
          </Typography>
          {item.rightTooltip && <Tooltip text={item.rightTooltip} />}
        </div>
      )}
    </div>
  );
};
