export type RequisiteProp = {
  title: string;
  subtitle: string;
};

export const requisitesConstants: RequisiteProp[] = [
  {
    title: 'Наименование',
    subtitle: 'ИП Гаврилов Максим Вячеславович',
  },
  {
    title: 'ОГРНИП',
    subtitle: '315746000012032',
  },
  {
    title: 'ИНН',
    subtitle: '745084377558',
  },
  {
    title: 'Банк',
    subtitle: 'МОСКОВСКИЙ ФИЛИАЛ АО КБ «МОДУЛЬБАНК»',
  },
  {
    title: 'Счет',
    subtitle: '40802810070810000262',
  },
  {
    title: 'БИК',
    subtitle: '044525092',
  },
  {
    title: 'К/с',
    subtitle: '30101810645250000092',
  },
  {
    title: 'Адрес',
    subtitle: '454081, Россия, г.Челябинск, ул. Валдайская 17,\n офис 5',
  },
];
