import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Fade } from 'react-reveal';
import { Layout } from '../../components/ui/Layout/Layout';
import { NotFoundBanner } from '../../components/Error/NotFoundBanner';
import ErrorBox from '../../assets/errors/box.png';
import XsErrorBox from '../../assets/backgrounds/not-found-banner-background-xs.png';

const NotFoundPage: React.FC<RouteComponentProps> = () => {
  return (
    <Layout>
      <Fade>
        <NotFoundBanner
          title="Страницы не существует"
          subtitle="Ошибка в адресе, страница удалена или она была перемещена"
          buttonText="На главную"
          img={ErrorBox}
          imgXs={XsErrorBox}
        />
      </Fade>
    </Layout>
  );
};

export default withRouter(NotFoundPage);
