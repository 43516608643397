import React from 'react';
import { makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../constants/Colors';
import { GlobalStyles } from '../../styles/GlobalStyles';
import { Status, StatusEnum } from '../ui/Status/Status';
import Logo from '../../assets/errors/logo.png';

const useStyles = makeStyles((theme: Theme) => ({
  errorCard: {
    padding: '9px 10px 14px 10px',
    backgroundColor: Colors.white,
    borderRadius: '20px',
    ...GlobalStyles.boxShadow,
    [theme.breakpoints.down(680)]: {
      padding: '9px 7px 14px 7px',
    },
  },
  image: {
    marginTop: '-2px',
    [theme.breakpoints.down(680)]: {
      height: '77px',
      width: '92px',
    },
  },
  title: {
    marginTop: '6px',
    textAlign: 'center',
  },
  logo: {
    margin: '8px auto 0 auto',
    width: '52.8px',
    height: '14.4px',
    backgroundImage: `url(${Logo})`,
    ...GlobalStyles.icon,
  },
}));

export type TErrorCard = {
  status: StatusEnum;
  image: string;
  title: string;
};

type TErrorCardProps = {
  item: TErrorCard;
};

const ErrorCard: React.FC<TErrorCardProps> = (props: TErrorCardProps) => {
  const classes = useStyles();
  const { item } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down(680));

  return (
    <div
      className={classes.errorCard}
      style={{
        border:
          item.status === StatusEnum.Error
            ? '1px solid #EB5757'
            : `1px solid ${Colors.backgroundSecondary}`,
      }}
    >
      <Status status={item.status} />
      <img className={classes.image} src={item.image} alt="error" />
      {item.title ? (
        <Typography
          variant="h4"
          component="div"
          className={classes.title}
          style={{ color: item.title === 'Сайт' && !isXs ? Colors.darkGray : Colors.newBlack }}
        >
          {t(item.title)}
        </Typography>
      ) : (
        <div className={classes.logo} />
      )}
    </div>
  );
};

export default ErrorCard;
