import React from 'react';

const TcpProtectionIcon: React.FC = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.1098 4.34C16.8598 3.56 16.2498 2.92 15.4398 2.59L10.1898 0.469995C9.42976 0.169995 8.56976 0.169995 7.80976 0.469995L2.55976 2.59C1.74976 2.92 1.13976 3.55 0.889758 4.34C0.209758 6.47 -0.530242 10.65 2.08976 14.74C3.58976 17.08 5.65976 18.5 7.12976 19.29C7.70976 19.6 8.34976 19.75 8.99976 19.75C9.64976 19.75 10.2898 19.6 10.8698 19.29C12.3398 18.51 14.4098 17.08 15.9098 14.74C18.5298 10.65 17.7898 6.47 17.1098 4.34ZM14.6398 13.93C13.3198 16 11.4698 17.26 10.1598 17.96C9.43976 18.34 8.54976 18.34 7.82976 17.96C6.51976 17.26 4.66976 16 3.34976 13.93C1.05976 10.35 1.71976 6.67 2.30976 4.79C2.41976 4.44 2.70976 4.14 3.10976 3.98L8.35976 1.86C8.75976 1.7 9.21976 1.7 9.61976 1.86L14.8698 3.98C15.2698 4.14 15.5598 4.44 15.6698 4.79C16.2698 6.67 16.9198 10.35 14.6298 13.93H14.6398Z"
        fill="currentColor"
      />
      <path
        d="M6.96724 11.9796L5.65329 9.21615C4.94342 7.72314 6.03212 6 7.6853 6L10.3132 6C11.9664 6 13.0551 7.72314 12.3452 9.21615L11.0313 11.9796C10.2174 13.6914 7.78114 13.6914 6.96724 11.9796Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default TcpProtectionIcon;
