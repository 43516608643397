import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import CompanyLogoIcon from '../../../assets/logo.svg';
import { ProjectPath } from '../../../constants/ProjectPath';

const useStyles = makeStyles({
  root: {
    '&.MuiButton-root': {
      width: '6.25rem',
      height: '2.5rem',
      backgroundColor: 'transparent',
    },
    backgroundImage: `url(${CompanyLogoIcon})`,
    ...GlobalStyles.icon,
  },
});

export const CompanyLogo: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const onLogoClick = () => {
    if (history.location.pathname !== ProjectPath.Index) {
      history.push(ProjectPath.Index);
    }
  };

  return <Button className={classes.root} onClick={onLogoClick} />;
};
