import React, { PropsWithChildren } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { Colors } from '../../../constants/Colors';
import { ProjectPath } from '../../../constants/ProjectPath';
import { redirectToProject } from '../../../utils/redirectToProject';

const useStyles = makeStyles({
  root: ({ textColor }: TProps) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter-Regular',
    color: textColor || Colors.fontColor,
    '&:hover': {
      color: Colors.primary,
      '& div': {
        color: Colors.primary,
      },
    },
    textAlign: 'left',
  }),
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    marginRight: '0.5rem',
    ...GlobalStyles.icon,
  },
  orangeIcon: {
    color: Colors.accent,
  },
  blueIcon: {
    color: Colors.primary,
  },
  mdIcon: {
    height: '1.5rem',
  },
  smIcon: ({ textColor }: TProps) => ({
    color: textColor || Colors.fontColor,
    height: '0.875rem',
  }),
  prefix: {
    transition: 'all 0.5s ease-in-out',
    marginLeft: '0.5rem',
  },
  fixedIcon: {
    width: '2.1rem',
  },
  activeIcon: {
    marginTop: '0.3rem',
    transform: 'rotate(180deg)',
  },
  activeTitle: {
    '&.MuiLink-root': {
      color: Colors.primary,
    },
  },
});

export type CustomLinkProps = {
  icon?: React.ReactNode;
  title: string;
  url?: string;
  isIconFixed?: boolean;
};

type TProps = {
  item: CustomLinkProps;
  textColor?: string;
  isBlueIcon?: boolean;
  isPhone?: boolean;
  isLg?: boolean;
  isSm?: boolean;
  onClick?: (link: string) => void;
  onMouseLeave?: (event: any) => void;
  onMouseEnter?: (event: any) => void;
  prefix?: React.ReactNode;
  autoWidth?: boolean;
  isIconFixed?: boolean;
  isEmail?: boolean;
  isMenuActive?: boolean;
  className?: string;
};

export const CustomLink: React.FC<PropsWithChildren<TProps>> = (
  props: PropsWithChildren<TProps>,
) => {
  const {
    item,
    isSm = false,
    isLg = false,
    isBlueIcon = false,
    onClick,
    prefix,
    onMouseLeave,
    onMouseEnter,
    autoWidth = false,
    isPhone = false,
    isIconFixed = false,
    isEmail = false,
    isMenuActive = false,
    className = '',
  } = props;
  const classes = useStyles(props);
  const history = useHistory();

  const renderLink = () => {
    if (isPhone) {
      return (
        <Link
          className={[classes.root, className].join(' ')}
          underline="none"
          component="a"
          variant={isLg ? 'h6' : 'body2'}
          href={`tel:${item.title.split(' ').join('')}`}
        >
          {item.icon && (
            <div
              className={`${classes.icon} ${isBlueIcon ? classes.blueIcon : classes.orangeIcon} ${
                isSm ? classes.smIcon : classes.mdIcon
              } ${isIconFixed ? classes.fixedIcon : ''}`}
            >
              {item.icon}
            </div>
          )}
          {item.title}
        </Link>
      );
    }

    if (isEmail) {
      return (
        <Link
          className={[classes.root, className].join(' ')}
          underline="none"
          component="a"
          variant={isLg ? 'h6' : 'body2'}
          href={`mailto:${item.title}`}
        >
          {item.icon && (
            <div
              className={`${classes.icon} ${isBlueIcon ? classes.blueIcon : classes.orangeIcon} ${
                isSm ? classes.smIcon : classes.mdIcon
              } ${isIconFixed ? classes.fixedIcon : ''}`}
            >
              {item.icon}
            </div>
          )}
          {item.title}
        </Link>
      );
    }

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        className={[classes.root, isMenuActive ? classes.activeTitle : '', className].join(' ')}
        underline="none"
        component="button"
        variant={isLg ? 'h6' : 'body2'}
        onMouseEnter={(e) => {
          if (onMouseEnter) {
            onMouseEnter(e);
          }
        }}
        onMouseLeave={(e) => {
          if (onMouseLeave) {
            onMouseLeave(e);
          }
        }}
        onClick={(event) => {
          if (item && item.url) {
            if (item.url === ProjectPath.Profile || item.url === ProjectPath.TechnicalSupport) {
              redirectToProject();
              return;
            }
            history.push(item.url);
          }
          onClick?.(item.title);
          event.stopPropagation();
        }}
      >
        {item.icon && (
          <div
            className={`${classes.icon} ${isBlueIcon ? classes.blueIcon : classes.orangeIcon} ${
              isSm ? classes.smIcon : classes.mdIcon
            } ${isIconFixed ? classes.fixedIcon : ''}`}
          >
            {item.icon}
          </div>
        )}
        {item.title}
        {prefix && (
          <div className={[classes.prefix, isMenuActive ? classes.activeIcon : ''].join(' ')}>
            {prefix}
          </div>
        )}
      </Link>
    );
  };

  return <>{renderLink()}</>;
};
