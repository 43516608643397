import React from 'react';

const UserIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.1875C3.965 0.1875 0.6875 3.465 0.6875 7.5C0.6875 11.535 3.965 14.8125 8 14.8125C12.035 14.8125 15.3125 11.535 15.3125 7.5C15.3125 3.465 12.035 0.1875 8 0.1875ZM4.7675 12.765C5.5025 11.7075 6.71 11.0625 8 11.0625C9.29 11.0625 10.4975 11.7 11.24 12.765C10.295 13.35 9.1925 13.6875 8 13.6875C6.8075 13.6875 5.7125 13.35 4.7675 12.765ZM12.1325 12.09C11.18 10.74 9.65 9.9375 8 9.9375C6.35 9.9375 4.8125 10.755 3.8675 12.09C2.6075 10.9575 1.8125 9.3225 1.8125 7.5C1.8125 4.0875 4.5875 1.3125 8 1.3125C11.4125 1.3125 14.1875 4.0875 14.1875 7.5C14.1875 9.3225 13.3925 10.9575 12.1325 12.09Z"
        fill="currentColor"
        fillOpacity="0.8"
      />
      <path
        d="M8 3.1875C6.4475 3.1875 5.1875 4.4475 5.1875 6C5.1875 7.5525 6.4475 8.8125 8 8.8125C9.5525 8.8125 10.8125 7.5525 10.8125 6C10.8125 4.4475 9.5525 3.1875 8 3.1875ZM8 7.6875C7.07 7.6875 6.3125 6.93 6.3125 6C6.3125 5.07 7.07 4.3125 8 4.3125C8.93 4.3125 9.6875 5.07 9.6875 6C9.6875 6.93 8.93 7.6875 8 7.6875Z"
        fill="currentColor"
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default UserIcon;
