import React from 'react';

// not current color
const EncryptIcon: React.FC = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4448 11.2949H13.8956V9.99837C13.8956 8.96285 13.0449 8.12049 11.9996 8.12049C10.9542 8.12049 10.1039 8.96316 10.1039 9.99837V11.2949H7.55469V9.99837C7.55469 7.57053 9.54869 5.59497 11.9999 5.59497C14.4511 5.59497 16.4451 7.57022 16.4451 9.99837V11.2949H16.4448Z"
        fill="#F9A11D"
      />
      <path
        d="M17.474 11.2949H6.52746C6.06011 11.2949 5.67773 11.6737 5.67773 12.1367V20.1583C5.67773 20.6212 6.06011 21 6.52746 21H17.474C17.9414 21 18.3238 20.6212 18.3238 20.1583V12.1367C18.3238 11.6737 17.9414 11.2949 17.474 11.2949ZM12.5554 16.5617V17.6488C12.5554 17.9522 12.3069 18.1981 12.0009 18.1981C11.6949 18.1981 11.4464 17.9518 11.4464 17.6488V16.562C11.1052 16.3701 10.8747 16.008 10.8747 15.5915C10.8747 14.9753 11.3791 14.4759 12.0009 14.4759C12.623 14.4759 13.1271 14.9756 13.1271 15.5915C13.1271 16.0077 12.8966 16.3698 12.5554 16.5617Z"
        fill="#2C3C69"
      />
      <path
        d="M5.41698 10.0989H2.77553C2.34718 10.0989 2 9.75494 2 9.33062C2 8.90629 2.34718 8.56238 2.77553 8.56238H5.41698C5.84533 8.56238 6.19251 8.9063 6.19251 9.33062C6.19251 9.75494 5.84501 10.0989 5.41698 10.0989Z"
        fill="#F9A11D"
      />
      <path
        d="M7.27852 5.98291C7.1054 5.98291 6.9307 5.92575 6.78644 5.80797L4.697 4.10597C4.3663 3.83649 4.31842 3.35249 4.59078 3.02459C4.86282 2.69701 5.35141 2.64958 5.68243 2.91906L7.77187 4.62106C8.10256 4.89054 8.15044 5.37454 7.87808 5.70244C7.72494 5.88743 7.50268 5.98291 7.27852 5.98291V5.98291Z"
        fill="#F9A11D"
      />
      <path
        d="M12.0001 4.17381C11.5718 4.17381 11.2246 3.82989 11.2246 3.40557V0.768239C11.2246 0.343917 11.5718 0 12.0001 0C12.4285 0 12.7757 0.343917 12.7757 0.768239V3.40557C12.7757 3.82989 12.4285 4.17381 12.0001 4.17381Z"
        fill="#F9A11D"
      />
      <path
        d="M16.7215 5.98291C16.4973 5.98291 16.2754 5.88743 16.1219 5.70244C15.8499 5.37485 15.8974 4.89054 16.2281 4.62106L18.3176 2.91906C18.6486 2.64958 19.1372 2.69701 19.4092 3.02459C19.6812 3.35218 19.6337 3.83649 19.303 4.10597L17.2135 5.80797C17.0693 5.92575 16.8949 5.98291 16.7215 5.98291Z"
        fill="#F9A11D"
      />
      <path
        d="M21.2252 10.0989H18.5587C18.1304 10.0989 17.7832 9.75494 17.7832 9.33062C17.7832 8.90629 18.1304 8.56238 18.5587 8.56238H21.2252C21.6536 8.56238 22.0008 8.9063 22.0008 9.33062C22.0008 9.75494 21.6536 10.0989 21.2252 10.0989Z"
        fill="#F9A11D"
      />
    </svg>
  );
};

export default EncryptIcon;
