import { THistoryCard } from './HistoryCard';

export const historyBlockConstants: THistoryCard[] = [
  {
    title: 'Начало',
    subtitle:
      'Каждая история компании начинается с истории ее основателя. Я основатель и руководитель компании ddosa.net Гаврилов Максим Вячеславович, будем знакомы!',
    size: 'sm',
  },
  {
    title: 'Рост',
    subtitle:
      'Сервис антиддос.рф. Наработанный опыт позволял отбивать даже сложные DDoS-атаки, но до идеала было еще далеко, к тому же, насмотревшись на аналоги (а они тогда уже',
    size: 'sm',
  },
  {
    title: 'DDosa.net',
    subtitle:
      'В 2016 году мы перезапустили проект под новым называнием, чтобы расширить географию пользователей мы перешли на латинское название ddosa.net, а так-же приняли очень важное решение сосредоточится на единственном продукте: удаленная защита от DDoS-атак для сайтов и сервисов, исключив из списка: оказание хостинг услуг, VPS сервера, оказание услуг операторам связи и другие. \n' +
      '\n' +
      'Мы сосредоточились исключительно на защите сайтов. Это то с чего мы начинали свой путь и умеем',
    size: 'md',
  },
  {
    title: 'Сейчас',
    subtitle:
      'В 2022 году принято решение вновь перезапустить проект, для того чтобы предложить людям надежную и доступную защиту для сайтов и сервисов от всех видов DDoS-атак. Мы считаем наши компетенции достигли уровня соответсвуюшего самым высоким мировым стандартам отрасли.\n' +
      '\n' +
      'Все это время мы готовили для Вас сервис доступный по качеству и цене, это достигнуто благодаря высокой степени автоматизации и оптимизации всех процессов внутри компании.\n' +
      '\n' +
      'Качественный антиDDoS еще никогда не был таким доступным. Мы сделаем все чтобы ваши проекты работали стабильно, вы спали  спокойно, а вопрос выбора компании защиты от DDoS-атак остался навсегда в прошлом.\n',
    size: 'lg',
  },
  {
    title: 'Завтра',
    subtitle:
      'Мы продолжаем активные инвестиции в расширение географии присутствия, постоянную модернизацию оборудования, разработку программного обеспечения и улучшение пользовательского опыта. \n' +
      '\n' +
      'Наши инженеры, основываясь на постоянно получаемом опыте, разрабатывают новый программно-аппаратный комплекс для внедрения на узлах операторов связи и инфраструктурных проектах крупных компаний. \n' +
      '\n' +
      'Наша ближайшая задача — задавать тренды рынка ',
    size: 'md',
  },
];
