import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { SwiperButtonsContainer } from '../../ui/Buttons/SwiperButtonsContainer';
import { technologiesConstants } from '../../About/TechnologiesBlock/TechnologiesConstants';
import { TechnologyCard } from '../../About/TechnologiesBlock/TechnologyCard';

SwiperCore.use([Navigation, Pagination]);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 -1.75rem',
      [theme.breakpoints.down(1200)]: {
        margin: '0 -1rem 0 0',
      },
      [theme.breakpoints.down('xs')]: {
        margin: '40px -1rem 0 0',
      },
    },
    header: {
      padding: '0 1.75rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    swiper: {
      padding: '0 1.75rem',
      marginTop: '1.625rem',
      ...GlobalStyles.pagination,
      ...GlobalStyles.slides,
      [theme.breakpoints.down('xs')]: {
        padding: '0 0.75rem',
      },
    },
    slide: {
      padding: '0 0 3rem 0',
      height: '100%',
    },
  }),
);

export const AnimationSwiper: React.FC = () => {
  const classes = useStyles();
  const animationPrevButtonClass = 'animation-prev-button';
  const animationNextButtonClass = 'animation-next-button';

  const renderSlides = () => {
    return technologiesConstants.map((val, index) => {
      return (
        <SwiperSlide key={index}>
          <div className={classes.slide}>
            <TechnologyCard item={val} isWhite />
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <SwiperButtonsContainer
          prevButtonClass={animationPrevButtonClass}
          nextButtonClass={animationNextButtonClass}
        />
      </div>
      <Swiper
        className={classes.swiper}
        slidesPerView={3}
        navigation={{
          prevEl: `.${animationPrevButtonClass}`,
          nextEl: `.${animationNextButtonClass}`,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          800: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        direction="horizontal"
        pagination={{ clickable: true }}
      >
        {renderSlides()}
      </Swiper>
    </div>
  );
};
