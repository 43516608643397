import React from 'react';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { Colors } from '../../../constants/Colors';
import { RateRow, TRateRow } from '../RateRow';
import { CustomLink } from '../../ui/Link/CustomLink';
import { redirectToProject } from '../../../utils/redirectToProject';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '17.875rem',
    position: 'relative',
    padding: '1.875rem 1.75rem 1rem 1.75rem',
    backgroundColor: Colors.white,
    ...GlobalStyles.borderRadius,
    ...GlobalStyles.boxShadow,
    [theme.breakpoints.down(1072)]: {
      width: 'auto',
    },
    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover button.MuiButton-contained': {
      opacity: 1,
      backgroundColor: Colors.backgroundSecondary,
      color: Colors.primary,
    },
    '&:hover button.MuiButton-outlined': {
      opacity: 1,
      backgroundColor: Colors.backgroundSecondary,
      color: Colors.primary,
    },
  },
  rootDark: {
    backgroundColor: 'rgba(43, 43, 43, 0.03)',
  },
  link: {
    margin: '1rem auto',
  },
  icon: {
    height: '24px',
    width: '24px',
    marginRight: '10px',
    ...GlobalStyles.icon,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  prices: {
    margin: '-10px 0',
  },
  oldPrice: {
    fontFamily: 'Raleway-SemiBold',
    textDecoration: 'line-through',
    color: Colors.darkGray,
  },
  price: {
    color: Colors.accent,
  },
  subtitle: {
    marginTop: '1.5rem',
    color: Colors.lightBlack,
  },
  middle: {
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    margin: '1.125rem -1.75rem 1.25rem -1.75rem',
    padding: '1.5rem 1.875rem',
    backgroundColor: 'rgba(69, 119, 250, 0.12)',
  },
  footer: {
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1.875rem',
  },
  button: {
    width: '100% !important',
    [theme.breakpoints.down('xs')]: {
      '&.MuiButton-root': {
        fontSize: '14px',
        lineHeight: '16.44px',
      },
    },
  },
  chooseButton: {
    '&.MuiButton-root': {
      '&.MuiButton-outlined': {
        '&:hover': {
          opacity: 1,
          backgroundColor: Colors.backgroundSecondary,
          color: Colors.primary,
        },
      },
      '&.MuiButton-contained': {
        '&:hover': {
          opacity: 1,
          backgroundColor: Colors.backgroundSecondary,
          color: Colors.primary,
        },
      },
    },
  },
}));

export type TRateCard = {
  icon: string;
  title: string;
  subtitle: string;
  price?: string[];
  oldPrice?: string;
  middleRows: TRateRow[];
  footerRows: TRateRow[];
};

type TProps = {
  activeMonth: number;
  item: TRateCard;
  isPressed?: boolean;
  hideDetails?: boolean;
  onDetailsShow?: () => void;
  onChooseClick?: (name: string) => void;
};

export const SitesRateCard: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { item, hideDetails, onDetailsShow, onChooseClick, isPressed, activeMonth } = props;
  const { t } = useTranslation();

  const onButtonClick = () => {
    redirectToProject();
  };

  const renderFooter = () => {
    if (hideDetails) {
      return (
        <CustomLink
          item={{ title: 'Дополнительно' }}
          className={classes.link}
          textColor={Colors.primary}
          onClick={() => {
            if (onDetailsShow) {
              onDetailsShow();
            }
          }}
        />
      );
    }

    return (
      <div className={classes.footer}>
        {item.footerRows.map((row, index) => {
          return <RateRow index={index} item={row} />;
        })}
      </div>
    );
  };

  const renderButton = () => {
    if (onDetailsShow) {
      return (
        <Button
          variant={isPressed ? 'outlined' : 'contained'}
          className={[classes.button, classes.chooseButton].join(' ')}
          onClick={() => onChooseClick?.(item.title)}
        >
          {t(isPressed ? 'Выбран' : 'Выбрать')}
        </Button>
      );
    }

    return (
      <Button className={classes.button} onClick={onButtonClick}>
        {t('Подключиться')}
      </Button>
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <div
      className={[classes.root, onDetailsShow ? classes.rootDark : ''].join(' ')}
      onClick={() => {
        if (onChooseClick) {
          onChooseClick(item.title);
          return;
        }
        onButtonClick();
      }}
    >
      <div className={classes.header}>
        <div className={classes.title}>
          <div className={classes.icon} style={{ backgroundImage: `url(${item.icon})` }} />
          <Typography variant="h3" component="div">
            {t(item.title)}
          </Typography>
        </div>
        <div className={`${item?.oldPrice && classes.prices}`}>
          {item?.oldPrice && (
            <Typography variant="body1" component="p" className={classes.oldPrice}>
              {t(item.oldPrice)}
            </Typography>
          )}
          {item?.price && (
            <Typography variant="h4" component="div" className={classes.price}>
              {t(item.price[activeMonth])}
            </Typography>
          )}
        </div>
      </div>
      <Typography variant="body2" component="p" className={classes.subtitle}>
        {t(item.subtitle)}
      </Typography>
      <div className={classes.middle}>
        {item.middleRows.map((row, index) => {
          return <RateRow index={index} item={row} />;
        })}
      </div>
      {renderFooter()}
      {renderButton()}
    </div>
  );
};
