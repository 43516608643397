import React from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GlobalStyles } from '../../../../styles/GlobalStyles';
import { Colors } from '../../../../constants/Colors';
import { CustomLink } from '../../../ui/Link/CustomLink';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '11.25rem',
    backgroundColor: Colors.white,
    padding: '1.375rem 1.375rem 1.875rem 1.875rem',
    ...GlobalStyles.boxShadow,
    ...GlobalStyles.borderRadius,
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    height: '3.625rem',
    width: '3.625rem',
    borderRadius: '50%',
    ...GlobalStyles.icon,
  },
  content: {
    marginTop: '0.625rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-1.575rem',
    },
  },
  title: {
    marginBottom: '0.625rem',
  },
}));

export type SocialCardProps = {
  title: string;
  link: string;
  icon: string;
};

type TProps = {
  item: SocialCardProps;
};

export const SocialCard: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { item } = props;

  return (
    <Grid item xs={12} md={6}>
      <div className={classes.root}>
        <div className={classes.iconContainer}>
          <div className={classes.icon} style={{ backgroundImage: `url(${item.icon})` }} />
        </div>
        <div className={classes.content}>
          <Typography variant="h3" component="div" className={classes.title}>
            {t(item.title)}
          </Typography>
          <CustomLink item={{ title: item.link, url: item.link }} textColor={Colors.primary} />
        </div>
      </div>
    </Grid>
  );
};
