import React from 'react';

const FingerPrintIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0.25H5C2.38 0.25 0.25 2.38 0.25 5V15C0.25 17.62 2.38 19.75 5 19.75H15C17.62 19.75 19.75 17.62 19.75 15V5C19.75 2.38 17.62 0.25 15 0.25ZM18.25 15C18.25 16.79 16.79 18.25 15 18.25H5C3.21 18.25 1.75 16.79 1.75 15V5C1.75 3.21 3.21 1.75 5 1.75H15C16.79 1.75 18.25 3.21 18.25 5V15Z"
        fill="currentColor"
      />
      <path
        d="M16 9.25C15.59 9.25 15.25 9.59 15.25 10C15.25 12.9 12.89 15.25 10 15.25C7.11 15.25 4.75 12.9 4.75 10C4.75 7.1 7.11 4.75 10 4.75C10.41 4.75 10.75 4.41 10.75 4C10.75 3.59 10.41 3.25 10 3.25C6.28 3.25 3.25 6.28 3.25 10C3.25 13.72 6.28 16.75 10 16.75C13.72 16.75 16.75 13.72 16.75 10C16.75 9.59 16.41 9.25 16 9.25Z"
        fill="currentColor"
      />
      <path
        d="M7.75 10C7.75 8.76 8.76 7.75 10 7.75C10.41 7.75 10.75 7.41 10.75 7C10.75 6.59 10.41 6.25 10 6.25C7.93 6.25 6.25 7.93 6.25 10C6.25 12.07 7.93 13.75 10 13.75C11.06 13.75 12.08 13.29 12.79 12.5C13.07 12.19 13.04 11.72 12.73 11.44C12.42 11.16 11.95 11.19 11.67 11.5C11.24 11.98 10.63 12.25 9.99 12.25C8.75 12.25 7.74 11.24 7.74 10H7.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FingerPrintIcon;
