import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import Mask from '../../../assets/protected/animation/mask.png';
import Spider from '../../../assets/protected/animation/spider.png';
import Virus from '../../../assets/protected/animation/virus.png';
import Person from '../../../assets/protected/animation/person.png';
import Basket from '../../../assets/protected/animation/basket.png';
import Disk from '../../../assets/protected/animation/disk.png';
import Panel from '../../../assets/protected/animation/panel.png';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import Key from '../../../assets/protected/animation/key.svg';
import Scan from '../../../assets/protected/animation/scan.svg';
import Indicator from '../../../assets/protected/animation/indicator.svg';
import Diamond from '../../../assets/protected/animation/diamond.svg';

const useStyles = makeStyles({
  root: {
    padding: '0 0.625rem',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
  },
  panelsContainer: {
    top: -20,
    left: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  panels: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  panel: {
    opacity: 0.1,
    position: 'absolute',
    // marginLeft: '-116px',
  },
  footer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: 'center',
  },
  icons: {
    margin: '2.5rem 0 0.75rem',
    gap: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    height: '19.5px',
    width: '19.5px',
    opacity: 0.4,
    ...GlobalStyles.icon,
  },
  leftGridItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6.75rem',
  },
  rightGridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    gap: '1rem',
  },
  firstItem: {
    marginLeft: '2rem',
  },
  firstItemText: {
    marginLeft: '1.5rem',
  },
  horizontal: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    maxWidth: '12.5rem',
    marginLeft: '2.5rem',
  },
  subtitle: {
    marginTop: '0.375rem',
    fontFamily: 'Inter-Regular',
    color: Colors.darkGray,
  },
  smDot: {
    height: '7.87px',
    width: '7.87px',
    borderRadius: '50%',
  },
  mdDot: {
    height: '15.92px',
    width: '15.92px',
    borderRadius: '50%',
  },
  lgDot: {
    height: '25.63px',
    width: '25.63px',
    borderRadius: '50%',
  },
  smLine: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    position: 'absolute',
  },
  mdLine: {
    position: 'absolute',
    display: 'flex',
    gap: '19.1px',
    left: '-200px',
  },
  lgLine: {
    position: 'absolute',
    display: 'flex',
    gap: '35px',
    top: '44.5%',
    left: '196px',
  },
});

const PcAnimationContainer: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderMdLine = (color: string) => {
    return new Array(6).fill(0).map((val, index) => {
      return (
        <div
          className={classes.mdDot}
          key={index}
          style={{
            backgroundColor: color,
            animation:
              index % 2 === 0 ? 'dot-opacity-from 1.25s infinite' : 'dot-opacity-to 1.25s infinite',
          }}
        />
      );
    });
  };

  const renderSmLine = (color: string) => {
    return new Array(3).fill(0).map((val, index) => {
      return (
        <div
          className={classes.smDot}
          key={index}
          style={{
            opacity: index % 2 === 0 ? 0.3 : 1,
            backgroundColor: color,
          }}
        />
      );
    });
  };

  const renderLgLine = () => {
    return new Array(7).fill(0).map((val, index) => {
      return (
        <>
          {index === 0 && (
            <div
              className={classes.smLine}
              style={{
                top: '-45px',
                left: '9px',
              }}
            >
              {renderSmLine(Colors.lightBlack)}
            </div>
          )}
          <div
            className={classes.lgDot}
            key={index}
            style={{
              animation:
                index % 2 === 0
                  ? 'dot-accent-to-black 1.25s infinite'
                  : 'dot-black-to-accent 1.25s infinite',
            }}
          />
          {index === 1 && (
            <div
              className={classes.smLine}
              style={{
                left: '70px',
                top: '36px',
              }}
            >
              {renderSmLine(Colors.accent)}
            </div>
          )}
        </>
      );
    });
  };

  return (
    <Grid container className={classes.root}>
      <div className={classes.panelsContainer}>
        <div className={classes.panels}>
          <img
            src={Panel}
            alt="panel"
            className={classes.panel}
            style={{ right: '-20px', animation: 'first-panel 1.25s infinite' }}
          />
          <img
            src={Panel}
            alt="panel"
            className={classes.panel}
            style={{ right: '-60px', animation: 'second-panel 1.25s infinite' }}
          />
          <img
            src={Panel}
            alt="panel"
            className={classes.panel}
            style={{ right: '-100px', animation: 'third-panel 1.25s infinite' }}
          />
          <img
            src={Panel}
            alt="panel"
            className={classes.panel}
            style={{ right: '-140px', animation: 'four-panel 1.25s infinite' }}
          />
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.icons}>
          <div className={classes.icon} style={{ backgroundImage: `url(${Key})` }} />
          <div className={classes.icon} style={{ backgroundImage: `url(${Scan})` }} />
          <div className={classes.icon} style={{ backgroundImage: `url(${Diamond})` }} />
          <div className={classes.icon} style={{ backgroundImage: `url(${Indicator})` }} />
        </div>
        <Typography variant="h6" component="p">
          {t('Многоуровневая защита от DDosa.net')}
        </Typography>
      </div>
      <Grid item xs={5} className={classes.leftGridItem}>
        <div className={classes.firstItem}>
          <Typography variant="h6" component="p" className={classes.firstItemText}>
            {t('Боты, атаки и вирусы')}
          </Typography>
          <img src={Virus} alt="virus" />
          <img src={Mask} alt="mask" />
          <img src={Spider} alt="spider" />
        </div>
        <div className={classes.lgLine}>{renderLgLine()}</div>
        <div className={classes.horizontal}>
          <img src={Person} alt="person" />
          <div className={classes.description} style={{ marginLeft: 0, maxWidth: '18rem' }}>
            <Typography variant="h6" component="p">
              {t('Пользователи и сервисы')}
            </Typography>
            <Typography variant="body1" component="p" className={classes.subtitle}>
              {t(
                'Целевые пользователи сайтом и легальные сервисы работающие с вашим сайтом или сервером',
              )}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4} className={classes.rightGridItem}>
        <div className={classes.horizontal}>
          <div className={classes.mdLine}>{renderMdLine(Colors.lightBlack)}</div>
          <img src={Basket} alt="basket" style={{ marginLeft: '10px' }} />
          <div className={classes.description}>
            <Typography variant="h6" component="p">
              {t('Боты, спам-сервисы и др.')}
            </Typography>
            <Typography variant="body1" component="p" className={classes.subtitle}>
              {t('Все атаки, спам-сервисы не получат доступ к сайту или портам сервера.')}
            </Typography>
          </div>
        </div>
        <div className={classes.horizontal}>
          <div className={classes.mdLine} style={{ top: '50px' }}>
            {renderMdLine(Colors.accent)}
          </div>
          <img src={Disk} alt="disk" />
          <div className={classes.description}>
            <Typography variant="h6" component="p">
              {t('Ваш сайт или сервер')}
            </Typography>
            <Typography variant="body1" component="p" className={classes.subtitle}>
              {t('На сервер попадают только реальные пользователи и сервисы.')}
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default PcAnimationContainer;
