import React, { useState } from 'react';
import { Button, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { Colors } from '../../../../constants/Colors';
import { GlobalStyles } from '../../../../styles/GlobalStyles';
import ProtectionBanner from '../../../../assets/backgrounds/target.png';
import Video from '../../../../assets/target-video.mp4';

const useStyles = makeStyles({
  root: {
    height: '235px',
    backgroundColor: Colors.backgroundSecondary,
    overflow: 'hidden',
    ...GlobalStyles.borderRadius,
  },
  videoRoot: {
    height: 'auto',
    position: 'relative',
  },
  grid: {
    height: '100%',
    width: '100%',
    position: 'relative',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.25rem 1.25rem 2.25rem 1.25rem',
  },
  videoDesktop: {
    width: '100%',
    clipPath: 'inset(1px 1px)',
  },
  subtitle: {
    color: Colors.lightBlack,
    marginTop: '0.5rem',
  },
  buttonContainer: {
    height: '115px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${ProtectionBanner})`,
    padding: '1.25rem 1.125rem 1.25rem 1.125rem',
    ...GlobalStyles.borderRadius,
  },
  closeButton: {
    padding: '4px',
    zIndex: 2,
    position: 'absolute',
    top: '16px',
    right: '16px',
    backgroundColor: Colors.white,
    color: Colors.primary,
  },
});

export const XsHowProtectionWorksMainBanner: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const onWatchButtonClick = () => {
    setIsVisible(true);
  };

  const onModalCloseButtonClick = () => {
    setIsVisible(false);
  };

  return (
    <div className={`${classes.root} ${isVisible ? classes.videoRoot : ''}`}>
      {isVisible ? (
        <>
          <IconButton className={classes.closeButton} onClick={onModalCloseButtonClick}>
            <CloseIcon />
          </IconButton>
          <video src={Video} className={classes.videoDesktop} autoPlay muted controls />
        </>
      ) : (
        <Grid container className={classes.grid}>
          <Grid item xs={12} className={classes.content}>
            <Typography variant="h3" component="div">
              {t('Как работает защита')}
            </Typography>
            <Typography variant="body2" component="p" className={classes.subtitle}>
              {t('Видео презентация наших технологий и подхода. А так же зачем вам защита')}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button variant="contained" onClick={onWatchButtonClick}>
              {t('Смотреть')}
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
