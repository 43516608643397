import React from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import ClockIcon from '../../../assets/icons/clock.svg';
import {
  LAYOUT_LEFT_RIGHT_CONTENT_PADDING_LG,
  LAYOUT_LEFT_RIGHT_CONTENT_PADDING_MD,
  LAYOUT_LEFT_RIGHT_CONTENT_PADDING_XL,
} from '../../../constants/UI';
import Logo from '../../../assets/icons/ddos.png';
import { Colors } from '../../../constants/Colors';
import RuIcon from '../../../assets/icons/ru.svg';
import IdeaIcon from '../../../assets/icons/idea.svg';
import PadlockIcon from '../../../assets/icons/padlock.svg';
import RocketIcon from '../../../assets/icons/feature-rocket.svg';
import AboutBannerBackground from '../../../assets/backgrounds/about-banner-background.jpeg';
import AboutBannerXsBackground from '../../../assets/backgrounds/about-banner-xs.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '26.25rem',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'end',
    backgroundImage: `url(${AboutBannerBackground})`,
    backgroundPosition: 'right',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    ...GlobalStyles.borderRadius,
    ...GlobalStyles.lightBorder,
    margin: `0 -${LAYOUT_LEFT_RIGHT_CONTENT_PADDING_XL}`,
    padding: '9.25rem 0 2rem 8.125rem',
    [theme.breakpoints.down('lg')]: {
      padding: '9.25rem 0 2rem 6.25rem',
      margin: `0 -${LAYOUT_LEFT_RIGHT_CONTENT_PADDING_LG}`,
    },
    [theme.breakpoints.down('md')]: {
      padding: '3.75rem 0 1.25rem 2rem',
      margin: `0 -${LAYOUT_LEFT_RIGHT_CONTENT_PADDING_MD}`,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '59px 16px 21px 16px',
      width: '100vw',
      margin: '0 -1rem',
      backgroundImage: `url(${AboutBannerXsBackground})`,
      height: 'auto',
    },
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    position: 'relative',
    maxWidth: '25rem',
  },
  logo: {},
  subtitle: {
    maxWidth: '323px',
    margin: '12px 0 3.25rem',
    color: Colors.newBlack,
    [theme.breakpoints.down('xs')]: {
      fontFamily: 'Raleway-SemiBold',
      margin: '12px 0 151px',
      fontSize: '16px',
      lineHeight: '18.78px',
      maxWidth: '239px',
    },
  },
  hintsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  clockContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  clockIcon: {
    margin: '0 0.375rem 0 1.5rem',
    height: '1.25rem',
    width: '1.25rem',
    backgroundImage: `url(${ClockIcon})`,
    ...GlobalStyles.icon,
  },
  hint: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Colors.white,
    padding: '1rem 1rem 1rem 1.25rem',
    borderRadius: '1.875rem',
    ...GlobalStyles.boxShadow,
  },
  icon: {
    height: '1.875rem',
    width: '1.875rem',
    marginLeft: '1rem',
    ...GlobalStyles.icon,
  },
}));

type THint = {
  title: string;
  url: string;
};

const hints: THint[] = [
  {
    title: 'Надежно',
    url: PadlockIcon,
  },
  {
    title: 'Быстро',
    url: RocketIcon,
  },
  {
    title: 'Доступно',
    url: RuIcon,
  },
  {
    title: 'Просто',
    url: IdeaIcon,
  },
];

export const AboutBanner: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderHints = () => {
    return hints.map((item, index) => {
      return (
        <Grid item key={index}>
          <div className={classes.hint}>
            <Typography variant="h4" component="div">
              {t(item.title)}
            </Typography>
            <div className={classes.icon} style={{ backgroundImage: `url(${item.url})` }} />
          </div>
        </Grid>
      );
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.header}>
          <img alt="logo" src={Logo} />
          <Typography variant="subtitle1" component="p" className={classes.subtitle}>
            {t('Команда экспертов информационнной безобасности')}
          </Typography>
        </div>
        <Grid container spacing={5} className={classes.hintsContainer}>
          {renderHints()}
        </Grid>
      </div>
    </div>
  );
};
