import React from 'react';

const PartnersIcon: React.FC = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5 14.05C21.09 14.05 20.75 14.39 20.75 14.8V17.8C20.75 19.59 19.29 21.05 17.5 21.05H7.5C5.71 21.05 4.25 19.59 4.25 17.8V14.8C4.25 14.39 3.91 14.05 3.5 14.05C3.09 14.05 2.75 14.39 2.75 14.8V17.8C2.75 20.42 4.88 22.55 7.5 22.55H17.5C20.12 22.55 22.25 20.42 22.25 17.8V14.8C22.25 14.39 21.91 14.05 21.5 14.05Z"
        fill="#4577FA"
      />
      <path
        d="M18.5 6.04999H16.25V4.79999C16.25 3.83999 15.46 3.04999 14.5 3.04999H10.5C9.54 3.04999 8.75 3.83999 8.75 4.79999V6.04999H6.5C4.43 6.04999 2.75 7.72999 2.75 9.79999V11.15C2.75 11.85 3.16 12.49 3.8 12.76C6.52 13.93 9.53 14.55 12.5 14.55C15.47 14.55 18.47 13.93 21.19 12.76C21.83 12.48 22.25 11.85 22.25 11.15V9.79999C22.25 7.72999 20.57 6.04999 18.5 6.04999V6.04999ZM10.25 4.79999C10.25 4.65999 10.36 4.54999 10.5 4.54999H14.5C14.64 4.54999 14.75 4.65999 14.75 4.79999V6.04999H10.25V4.79999ZM20.75 11.15C20.75 11.25 20.69 11.34 20.6 11.38C15.52 13.56 9.47 13.56 4.4 11.38C4.31 11.34 4.25 11.25 4.25 11.15V9.79999C4.25 8.55999 5.26 7.54999 6.5 7.54999H18.5C19.74 7.54999 20.75 8.55999 20.75 9.79999V11.15Z"
        fill="#4577FA"
      />
    </svg>
  );
};

export default PartnersIcon;
