import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Fade } from 'react-reveal';
import { Layout } from '../../components/ui/Layout/Layout';
import { NotFoundBanner } from '../../components/Error/NotFoundBanner';
import ErrorOff from '../../assets/errors/off.png';

const ServerErrorPage: React.FC<RouteComponentProps> = () => {
  return (
    <Layout>
      <Fade>
        <NotFoundBanner
          title="Внутренняя ошибка сервера"
          subtitle="Мы уже работаем над этим, попробуйте зайти позже"
          buttonText="Обновить"
          img={ErrorOff}
        />
      </Fade>
    </Layout>
  );
};

export default withRouter(ServerErrorPage);
