import React from 'react';

const DiamondIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2406 4.93999L16.9306 1.08999C16.6206 0.569991 16.0406 0.23999 15.4306 0.23999H4.57059C3.96059 0.23999 3.38059 0.569991 3.07059 1.08999L0.760593 4.93999C0.350593 5.62999 0.460593 6.50999 1.02059 7.07999L8.76059 14.82C9.10059 15.16 9.55059 15.33 10.0006 15.33C10.4506 15.33 10.9006 15.16 11.2406 14.82L18.9806 7.07999C19.5506 6.50999 19.6606 5.62999 19.2406 4.93999ZM5.51059 6.74999L7.67059 11.61L2.81059 6.74999H5.51059ZM11.4906 1.74999L12.8906 5.24999H7.10059L8.50059 1.74999H11.4806H11.4906ZM12.8406 6.74999L9.99059 13.15L7.14059 6.74999H12.8306H12.8406ZM14.4806 6.74999H17.1806L12.3206 11.61L14.4806 6.74999ZM15.6406 1.86999L17.6706 5.24999H14.5006L13.1006 1.74999H15.4306C15.5206 1.74999 15.6006 1.79999 15.6406 1.86999ZM4.35059 1.86999C4.39059 1.78999 4.48059 1.74999 4.56059 1.74999H6.89059L5.49059 5.24999H2.32059L4.35059 1.86999Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DiamondIcon;
