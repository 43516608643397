import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { ProtectionCard } from '../../ui/Cards/ProtectionCard';
import { tcpServiceLinks, webServiceLinks } from './UpperBlockConstants';
import ProtectIcon from '../../../assets/icons/protect-blue.svg';
import EarthIcon from '../../../assets/icons/earth-blue.svg';
import { ProjectPath } from '../../../constants/ProjectPath';
import { GlobalStyles } from '../../../styles/GlobalStyles';

SwiperCore.use([Navigation, Pagination]);
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '0 -1rem 0 0',
      overflow: 'hidden',
    },
    swiper: {
      alignItems: 'center',
      overflow: 'initial',
      margin: '0 1rem 0 0',
      ...GlobalStyles.pagination,
      ...GlobalStyles.slides,
    },
    slide: {
      height: '100%',
      padding: '0 0 2.5rem 0',
    },
    horizontalCards: {
      zIndex: 1,
      margin: '4rem 0 0 -3.75rem',
    },
  }),
);

export const UpperBlockSwiper: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const laptop = useMediaQuery(theme.breakpoints.down(1000));

  const renderSlides = () => {
    return new Array(1).fill(1).map((val, index) => {
      return (
        <>
          <SwiperSlide key={index + 1}>
            <div className={classes.slide}>
              <ProtectionCard
                title="Защита TCP-сервиса"
                subtitle={
                  'Удаленная защита для ваших TCP сервисов.\nМоментально блокирует любые DDoS-атаки.\nЗначительно повышает отказоустойчивость вашего сервиса.\n\nТочки фильтрации на выбор: Россия или Европа.\n'
                }
                links={tcpServiceLinks}
                icon={ProtectIcon}
                url={ProjectPath.PortsProtection}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide key={index + 2}>
            <div className={classes.slide}>
              <ProtectionCard
                title="Защита веб-сайтов"
                subtitle={
                  'Надежная защита от всех видов DDoS-атак без переноса сайта. Благодаря машинному обучению блокируются только вредоносные запросы.\n\nУскоряет сайт, защищает от ботов, снижает нагрузку.\nПодходит для малого, среднего и крупного бизнеса.\nТочки фильтрации на выбор: Россия или Европа.\n'
                }
                links={webServiceLinks}
                icon={EarthIcon}
                url={ProjectPath.SiteProtection}
              />
            </div>
          </SwiperSlide>
        </>
      );
    });
  };

  return (
    <div className={classes.root}>
      <Swiper
        className={classes.swiper}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 2,
          },
        }}
        spaceBetween={laptop ? 10 : 0}
        direction="horizontal"
        pagination={{ clickable: true }}
      >
        {renderSlides()}
      </Swiper>
    </div>
  );
};
