import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { SwiperNavButton } from './SwiperNavButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '5.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

type TProps = {
  prevButtonClass: string;
  nextButtonClass: string;
  onNextClick?: () => void;
  onPrevClick?: () => void;
};

export const SwiperButtonsContainer: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { prevButtonClass, nextButtonClass, onPrevClick, onNextClick } = props;
  return (
    <div className={classes.root}>
      <div className={prevButtonClass}>
        <SwiperNavButton
          isLeft
          click={() => {
            if (onPrevClick) {
              onPrevClick();
            }
          }}
        />
      </div>
      <div className={nextButtonClass}>
        <SwiperNavButton
          isLeft={false}
          click={() => {
            if (onNextClick) {
              onNextClick();
            }
          }}
        />
      </div>
    </div>
  );
};
