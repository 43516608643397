import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '19.375rem',
      position: 'relative',
      backgroundColor: Colors.backgroundSecondary,
      overflow: 'hidden',
      ...GlobalStyles.borderRadius,
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
    },
    image: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        height: '158px',
      },
    },
    content: {
      position: 'absolute',
      bottom: '1.375rem',
      left: '1.375rem',
      [theme.breakpoints.down('xs')]: {
        position: 'initial',
        marginLeft: '22px',
        marginBottom: '22px',
      },
    },
    title: {
      color: Colors.primary,
      [theme.breakpoints.down(1400)]: {
        fontSize: '34px',
      },
      [theme.breakpoints.down(1200)]: {
        fontSize: '26px',
      },
    },
    subtitle: {
      color: Colors.lightBlack,
      marginTop: '0.625rem',
      [theme.breakpoints.down(1200)]: {
        fontSize: '14px',
      },
    },
  }),
);

export type CompanyCardProps = {
  title: string;
  subtitle: string;
  img: string;
};

type TProps = {
  item: CompanyCardProps;
};

export const HugeCompanyCard: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { item } = props;

  return (
    <div className={classes.root}>
      <div className={classes.image}>
        <img src={item.img} alt="" />
      </div>
      <div className={classes.content}>
        <Typography variant="h1" className={classes.title}>
          {t(item.title)}
        </Typography>
        <Typography variant="h4" component="div" className={classes.subtitle}>
          {t(item.subtitle)}
        </Typography>
      </div>
    </div>
  );
};
