import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Fade } from 'react-reveal';
import 'swiper/swiper-bundle.css';
import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { SwiperButtonsContainer } from '../../ui/Buttons/SwiperButtonsContainer';
import { technologiesConstants } from './TechnologiesConstants';
import { TechnologyCard } from './TechnologyCard';
import { ProjectPath } from '../../../constants/ProjectPath';

SwiperCore.use([Navigation, Pagination]);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    swiper: {
      marginTop: '1.625rem',
      ...GlobalStyles.pagination,
      ...GlobalStyles.slides,
    },
    slide: {
      height: '100%',
      padding: '0 0 3.5rem 0',
    },
    footer: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    desktopKnowledgeButton: {
      position: 'absolute',
      top: '-2rem',
      zIndex: 2,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    mobileKnowledgeButton: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  }),
);

export const TechnologiesSwiper: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const technologiesPrevButtonClass = 'technologies-prev-button';
  const technologiesNextButtonClass = 'technologies-next-button';

  const renderSlides = () => {
    return technologiesConstants.map((val, index) => {
      return (
        <SwiperSlide key={index}>
          <div className={classes.slide}>
            <TechnologyCard item={val} />
          </div>
        </SwiperSlide>
      );
    });
  };

  const onKnowledgeButtonClick = () => {
    history.push(ProjectPath.KnowledgeBase);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h2">{t('Технологии')}</Typography>
        <SwiperButtonsContainer
          prevButtonClass={technologiesPrevButtonClass}
          nextButtonClass={technologiesNextButtonClass}
        />
        <Button
          className={classes.mobileKnowledgeButton}
          variant="outlined"
          onClick={onKnowledgeButtonClick}
        >
          {t('База знаний')}
        </Button>
      </div>
      <Fade>
        <Swiper
          className={classes.swiper}
          spaceBetween={20}
          slidesPerView={3}
          navigation={{
            prevEl: `.${technologiesPrevButtonClass}`,
            nextEl: `.${technologiesNextButtonClass}`,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            800: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
          }}
          direction="horizontal"
          pagination={{ clickable: true }}
        >
          {renderSlides()}
        </Swiper>
      </Fade>
      <Fade>
        <div className={classes.footer}>
          <Button
            className={classes.desktopKnowledgeButton}
            variant="outlined"
            onClick={onKnowledgeButtonClick}
          >
            {t('База знаний')}
          </Button>
        </div>
      </Fade>
    </div>
  );
};
