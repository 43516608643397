import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { createStyles, makeStyles } from '@material-ui/core';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { PortsRateCard } from './PortsRateCard';
import { portsRatesConstants } from './PortsRatesConstans';

SwiperCore.use([Navigation, Pagination]);
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '0 -3.875rem',
      overflow: 'hidden',
    },
    popup: {
      width: '100vw',
      overflow: 'initial',
    },
    swiper: {
      margin: '0 4.875rem',
      minHeight: '353px',
      overflow: 'initial',
      ...GlobalStyles.pagination,
    },
    sliderContainer: {
      height: 'auto',
    },
    slide: {
      height: '100%',
      padding: '0 0 3.5rem 0',
    },
  }),
);

type TProps = {
  isPopup?: boolean;
  activeMonth: number;
};

export const PortsSwiper: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { isPopup, activeMonth } = props;

  const renderSlides = () => {
    return portsRatesConstants.map((val, index) => {
      return (
        <SwiperSlide key={index} className={classes.sliderContainer}>
          <div className={classes.slide}>
            <PortsRateCard item={val} activeMonth={activeMonth} />
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className={`${classes.root} ${isPopup ? classes.popup : ''}`}>
      <Swiper
        className={classes.swiper}
        spaceBetween={20}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          720: {
            slidesPerView: 2,
          },
        }}
        direction="horizontal"
        pagination={{ clickable: false }}
      >
        {renderSlides()}
      </Swiper>
    </div>
  );
};
