import React from 'react';
import { makeStyles } from '@material-ui/core';
import ProofCompany from '../../../../assets/companies/proof-company.png';
import FlowersCompany from '../../../../assets/companies/flowers-company.png';
import TeploCompany from '../../../../assets/companies/teplo-company.png';
import FlexCompany from '../../../../assets/companies/flex-company.png';
import AviaCompany from '../../../../assets/companies/avia-company.png';
import MainClubCompany from '../../../../assets/companies/main-club-company.png';
import MonoCompany from '../../../../assets/companies/mono-company.png';
import RustersCompany from '../../../../assets/companies/rusters-company.png';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    gap: '36px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '60px 15px 15px',
    // backgroundColor: Colors.backgroundSecondary,
  },
  content: {
    width: 'fit-content',
  },
  threeLine: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  midLine: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '36px 0',
  },
  first: {
    height: '29.11px',
    width: '117.01px',
  },
  second: {
    height: '41.93px',
    width: '59.2px',
  },
  third: {
    height: '34.2px',
    width: '122.5px',
  },
  four: {
    width: '142.56px',
    height: '30.75px',
  },
  five: {
    width: '123.92px',
    height: '22.51px',
  },
  six: {
    width: '150.59px',
    height: '34.88px',
  },
  seven: {
    width: '73.35px',
    height: '47.79px',
  },
  eight: {
    width: '61.03px',
    height: '37.84px',
  },
});

const SecondPartnersSlide: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.threeLine}>
          <img src={ProofCompany} alt="" className={classes.first} />
          <img src={MonoCompany} alt="" className={classes.second} />
          <img src={FlexCompany} alt="" className={classes.third} />
        </div>
        <div className={classes.midLine}>
          <img src={FlowersCompany} alt="" className={classes.four} />
          <img src={MainClubCompany} alt="" className={classes.five} />
        </div>
        <div className={classes.threeLine}>
          <img src={RustersCompany} alt="" className={classes.seven} />
          <img src={TeploCompany} alt="" className={classes.six} />

          <img src={AviaCompany} alt="" className={classes.eight} />
        </div>
      </div>
    </div>
  );
};

export default SecondPartnersSlide;
