import React from 'react';

// not current color
const SslIcon: React.FC = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0627 11.5714C13.0627 12.904 12.8254 14.0638 12.3506 15.0508C11.8759 16.0336 11.2221 16.7957 10.3892 17.337C9.56047 17.8742 8.60682 18.1429 7.52824 18.1429C6.44966 18.1429 5.49393 17.8742 4.66105 17.337C3.83234 16.7957 3.18061 16.0336 2.70587 15.0508C2.23529 14.0638 2 12.904 2 11.5714C2 10.2388 2.23529 9.08111 2.70587 8.09831C3.18061 7.11135 3.83442 6.34927 4.6673 5.81206C5.50018 5.27069 6.45383 5 7.52824 5C8.60682 5 9.56047 5.27069 10.3892 5.81206C11.2221 6.34927 11.8759 7.11135 12.3506 8.09831C12.8254 9.08111 13.0627 10.2388 13.0627 11.5714ZM11.9259 11.5714C11.9259 10.4262 11.7343 9.4455 11.3512 8.62928C10.968 7.80889 10.4454 7.18215 9.78327 6.74905C9.12113 6.31595 8.36945 6.0994 7.52824 6.0994C6.6912 6.0994 5.94161 6.31595 5.27947 6.74905C4.61733 7.18215 4.09261 7.80681 3.70532 8.62303C3.3222 9.43925 3.13064 10.4221 3.13064 11.5714C3.13064 12.7166 3.3222 13.6974 3.70532 14.5136C4.08845 15.3298 4.61108 15.9565 5.27322 16.3938C5.93536 16.8269 6.68703 17.0434 7.52824 17.0434C8.36945 17.0434 9.12113 16.8269 9.78327 16.3938C10.4496 15.9607 10.9743 15.336 11.3574 14.5198C11.7405 13.6994 11.93 12.7166 11.9259 11.5714Z"
        fill="#721412"
      />
      <path
        d="M20.2544 8.92708C20.2048 8.42722 19.992 8.0389 19.6161 7.76211C19.2402 7.48533 18.73 7.34694 18.0855 7.34694C17.6477 7.34694 17.2779 7.40891 16.9764 7.53284C16.6748 7.65264 16.4434 7.81995 16.2823 8.03476C16.1253 8.24958 16.0469 8.49332 16.0469 8.76597C16.0386 8.99318 16.0861 9.19147 16.1894 9.36084C16.2968 9.53022 16.4434 9.67687 16.6293 9.8008C16.8152 9.92061 17.0301 10.0259 17.2738 10.1168C17.5175 10.2036 17.7778 10.2779 18.0546 10.3399L19.1947 10.6126C19.7483 10.7365 20.2564 10.9017 20.7191 11.1083C21.1818 11.3148 21.5825 11.5689 21.9213 11.8705C22.26 12.172 22.5223 12.5273 22.7082 12.9363C22.8983 13.3453 22.9953 13.8142 22.9995 14.3429C22.9953 15.1196 22.7971 15.7929 22.4046 16.363C22.0163 16.929 21.4544 17.369 20.7191 17.6829C19.9879 17.9927 19.1059 18.1477 18.0732 18.1477C17.0486 18.1477 16.1563 17.9907 15.3962 17.6767C14.6402 17.3628 14.0495 16.898 13.624 16.2825C13.2026 15.6628 12.9816 14.8965 12.9609 13.9835H15.5573C15.5862 14.409 15.7081 14.7643 15.9229 15.0493C16.1419 15.3303 16.4331 15.543 16.7966 15.6876C17.1643 15.8281 17.5795 15.8983 18.0422 15.8983C18.4966 15.8983 18.8911 15.8322 19.2257 15.7C19.5645 15.5678 19.8268 15.384 20.0127 15.1485C20.1986 14.913 20.2915 14.6424 20.2915 14.3367C20.2915 14.0517 20.2069 13.8121 20.0375 13.6179C19.8722 13.4238 19.6285 13.2585 19.3063 13.1222C18.9882 12.9859 18.5978 12.8619 18.1351 12.7504L16.7533 12.4034C15.6833 12.1431 14.8385 11.7362 14.2189 11.1827C13.5992 10.6291 13.2914 9.88343 13.2956 8.94567C13.2914 8.17729 13.4959 7.50599 13.909 6.93177C14.3263 6.35754 14.8984 5.90932 15.6255 5.5871C16.3526 5.26487 17.1788 5.10376 18.1041 5.10376C19.046 5.10376 19.8681 5.26487 20.5704 5.5871C21.2768 5.90932 21.8262 6.35754 22.2187 6.93177C22.6112 7.50599 22.8136 8.17109 22.826 8.92708H20.2544Z"
        fill="black"
      />
    </svg>
  );
};

export default SslIcon;
