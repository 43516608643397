import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import LeftArrowDisable from '../../../assets/icons/left-arrow-disable.svg';
import RightArrowActive from '../../../assets/icons/right-arrow-active.svg';

const useStyles = makeStyles({
  root: ({ isLeft }: TProps) => ({
    padding: '13px 15px',
    backgroundColor: Colors.backgroundSecondary,
    borderRadius: '50%',
    '& div': {
      backgroundImage: `url(${LeftArrowDisable})`,
      transform: isLeft ? 'rotate(0)' : 'rotate(180deg)',
    },
    '&:hover': {
      padding: '12px 14px',
      backgroundColor: Colors.white,
      ...GlobalStyles.lightBorder,
      ...GlobalStyles.boxShadow,
      '& div': {
        backgroundImage: `url(${RightArrowActive})`,
        transform: !isLeft ? 'rotate(0)' : 'rotate(180deg)',
      },
    },
  }),
  icon: {
    height: '12px',
    width: '7px',
  },
});

type TProps = {
  isLeft: boolean;
  click?: () => void;
};

export const SwiperNavButton: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles(props);

  return (
    <IconButton
      className={classes.root}
      onClick={() => {
        if (props.click) {
          props.click();
        }
      }}
    >
      <div className={classes.icon} />
    </IconButton>
  );
};
