import React from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RequisitesItem } from './RequisitesItem';
import { SocialsGrid } from './Socials/SocialsGrid';
import { CompanyContacts } from '../../ui/Layout/Footer/CompanyContacts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
    },
  },
  companyContainer: {
    margin: '0.875rem 0 2.75rem',
  },
  socials: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem',
    },
  },
  headerMobile: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  headerDesktop: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '26px',
      lineHeight: '29px',
    },
  },
}));

export const ContactsBlock: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderHeader = () => {
    return (
      <>
        <Typography variant="h2" className={classes.title}>
          {t('Контакты')}
        </Typography>
        <div className={classes.companyContainer}>
          <CompanyContacts />
        </div>
      </>
    );
  };

  return (
    <Grid container className={classes.root}>
      <Grid item sm={12} lg={4}>
        <div className={classes.headerDesktop}>{renderHeader()}</div>

        <RequisitesItem />
      </Grid>
      <Grid item xs={11} sm={8} lg={7} xl={6} className={classes.socials}>
        <SocialsGrid />
      </Grid>
      <div className={classes.headerMobile}>{renderHeader()}</div>
    </Grid>
  );
};
