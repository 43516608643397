import React from 'react';

const DatabaseIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 13.75C18.07 13.75 19.75 12.07 19.75 10C19.75 8.77 19.15 7.68 18.23 7C19.15 6.32 19.75 5.23 19.75 4C19.75 1.93 18.07 0.25 16 0.25H4C1.93 0.25 0.25 1.93 0.25 4C0.25 5.23 0.85 6.32 1.77 7C0.85 7.68 0.25 8.77 0.25 10C0.25 12.07 1.93 13.75 4 13.75H9.25V14.37C8.34 14.63 7.63 15.34 7.37 16.25H2C1.59 16.25 1.25 16.59 1.25 17C1.25 17.41 1.59 17.75 2 17.75H7.37C7.7 18.9 8.75 19.75 10 19.75C11.25 19.75 12.3 18.9 12.63 17.75H18C18.41 17.75 18.75 17.41 18.75 17C18.75 16.59 18.41 16.25 18 16.25H12.63C12.37 15.34 11.66 14.63 10.75 14.37V13.75H16ZM11.25 17C11.25 17.69 10.69 18.25 10 18.25C9.31 18.25 8.75 17.69 8.75 17C8.75 16.31 9.31 15.75 10 15.75C10.69 15.75 11.25 16.31 11.25 17ZM1.75 4C1.75 2.76 2.76 1.75 4 1.75H16C17.24 1.75 18.25 2.76 18.25 4C18.25 5.24 17.24 6.25 16 6.25H4C2.76 6.25 1.75 5.24 1.75 4ZM1.75 10C1.75 8.76 2.76 7.75 4 7.75H16C17.24 7.75 18.25 8.76 18.25 10C18.25 11.24 17.24 12.25 16 12.25H4C2.76 12.25 1.75 11.24 1.75 10Z"
        fill="currentColor"
      />
      <path
        d="M16.7695 4C16.7695 3.59 16.4395 3.25 16.0195 3.25C15.6095 3.25 15.2695 3.59 15.2695 4C15.2695 4.41 15.6095 4.75 16.0195 4.75C16.4295 4.75 16.7695 4.41 16.7695 4Z"
        fill="currentColor"
      />
      <path
        d="M16.0195 10.75C16.4395 10.75 16.7695 10.41 16.7695 10C16.7695 9.59 16.4395 9.25 16.0195 9.25C15.6095 9.25 15.2695 9.59 15.2695 10C15.2695 10.41 15.6095 10.75 16.0195 10.75Z"
        fill="currentColor"
      />
      <path
        d="M13.0195 3.25C12.6095 3.25 12.2695 3.59 12.2695 4C12.2695 4.41 12.6095 4.75 13.0195 4.75C13.4295 4.75 13.7695 4.41 13.7695 4C13.7695 3.59 13.4395 3.25 13.0195 3.25Z"
        fill="currentColor"
      />
      <path
        d="M13.0195 10.75C13.4395 10.75 13.7695 10.41 13.7695 10C13.7695 9.59 13.4395 9.25 13.0195 9.25C12.6095 9.25 12.2695 9.59 12.2695 10C12.2695 10.41 12.6095 10.75 13.0195 10.75Z"
        fill="currentColor"
      />
      <path
        d="M10.0195 3.25C9.60953 3.25 9.26953 3.59 9.26953 4C9.26953 4.41 9.60953 4.75 10.0195 4.75C10.4295 4.75 10.7695 4.41 10.7695 4C10.7695 3.59 10.4395 3.25 10.0195 3.25Z"
        fill="currentColor"
      />
      <path
        d="M10.7695 10C10.7695 9.59 10.4395 9.25 10.0195 9.25C9.60953 9.25 9.26953 9.59 9.26953 10C9.26953 10.41 9.60953 10.75 10.0195 10.75C10.4295 10.75 10.7695 10.41 10.7695 10Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DatabaseIcon;
