import React from 'react';
import { makeStyles } from '@material-ui/core';
import NicknameCompany from '../../../../assets/companies/nickname-company.png';
import MonolitCompany from '../../../../assets/companies/monolit-company.png';
import CarsCompany from '../../../../assets/companies/cars-company.png';
import MidCompany from '../../../../assets/companies/mid-company.png';
import MagneticCompany from '../../../../assets/companies/magnetic-company.png';
import FantasyCompany from '../../../../assets/companies/fantasy-company.png';
import AutoStatCompany from '../../../../assets/companies/autostat-company.png';
import DiaCompany from '../../../../assets/companies/dia-company.png';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    gap: '36px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '60px 15px 15px',
    // backgroundColor: Colors.backgroundSecondary,
  },
  content: {
    width: 'fit-content',
  },
  threeLine: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  midLine: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '36px 0',
  },
  first: {
    height: '29.11px',
    width: '117.01px',
  },
  second: {
    height: '41.93px',
    width: '59.2px',
  },
  third: {
    height: '11.2px',
    width: '122.5px',
  },
  four: {
    width: '142.56px',
    height: '30.75px',
  },
  five: {
    width: '123.92px',
    height: '13.51px',
  },
  six: {
    width: '121.59px',
    height: '28.88px',
  },
  seven: {
    width: '116.35px',
    height: '15.79px',
  },
  eight: {
    width: '61.03px',
    height: '37.84px',
  },
});

const FirstPartnersSlide: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.threeLine}>
          <img src={NicknameCompany} alt="" className={classes.first} />
          <img src={MonolitCompany} alt="" className={classes.second} />
          <img src={CarsCompany} alt="" className={classes.third} />
        </div>
        <div className={classes.midLine}>
          <img src={MidCompany} alt="" className={classes.four} />
          <img src={MagneticCompany} alt="" className={classes.five} />
        </div>
        <div className={classes.threeLine}>
          <img src={FantasyCompany} alt="" className={classes.six} />
          <img src={AutoStatCompany} alt="" className={classes.seven} />
          <img src={DiaCompany} alt="" className={classes.eight} />
        </div>
      </div>
    </div>
  );
};

export default FirstPartnersSlide;
