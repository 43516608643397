import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GlobalStyles } from '../../styles/GlobalStyles';
import { CustomLink, CustomLinkProps } from '../ui/Link/CustomLink';
import { Colors } from '../../constants/Colors';

const useStyles = makeStyles({
  root: {
    height: '100%',
    padding: '1.875rem',
    ...GlobalStyles.borderRadius,
    ...GlobalStyles.border,
  },
  header: {},
  content: {
    marginTop: '0.75rem',
  },
  subtitle: {
    color: Colors.newBlack,
  },
});

type TProps = {
  subtitle: string;
  link: CustomLinkProps;
};

const RateCard: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { link, subtitle } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <CustomLink isLg item={link} textColor={Colors.fontColor} isBlueIcon />
      </div>
      <div className={classes.content}>
        <Typography variant="body2" component="p" className={classes.subtitle}>
          {t(subtitle)}
        </Typography>
      </div>
    </div>
  );
};

export default RateCard;
