import React from 'react';
import { makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { AnimationSwiper } from './AnimationSwiper';
import PcAnimationContainer from './PcAnimationContainer';
import MobileAnimationContainer from './MobileAnimationContainer';
import TabletAnimationContainer from './TabletAnimationContainer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  container: {
    marginTop: '1.625rem',
    padding: '3.375rem 1.75rem 26px 1.75rem',
    backgroundColor: Colors.backgroundSecondary,
    ...GlobalStyles.borderRadius,
    [theme.breakpoints.down(1200)]: {
      marginTop: '15px',
      padding: '1.875rem 1rem 1.25rem 0',
    },
  },
}));

const AnimationBlock: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isTabletAnimation = useMediaQuery(theme.breakpoints.down(1200));
  const isMobileAnimation = useMediaQuery(theme.breakpoints.down(610));

  const renderAnimation = () => {
    if (isMobileAnimation) {
      return <MobileAnimationContainer />;
    }

    if (isTabletAnimation) {
      return <TabletAnimationContainer />;
    }

    return <PcAnimationContainer />;
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2">{t('Надежная защита от всех видов DDoS-атак ')}</Typography>
      <div className={classes.container}>
        {renderAnimation()}
        <AnimationSwiper />
      </div>
    </div>
  );
};

export default AnimationBlock;
