import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { Layout } from '../../components/ui/Layout/Layout';
import { KnowledgeBanner } from '../../components/ui/Banners/KnowledgeBanner';
import { CustomAccordion } from '../../components/ui/Accordion/CustomAccordion';
import { sidebarConstants } from '../../components/KnowlegeBase/Sidebar/SidebarConstants';
import { Colors } from '../../constants/Colors';
import { GlobalStyles } from '../../styles/GlobalStyles';
import { DefaultContent } from '../../components/KnowlegeBase/Content/DefaultContent';
import { AccordionContent } from '../../components/KnowlegeBase/Content/AccordionContent';
import { InputWithButton } from '../../components/ui/Inputs/InputWithButton';
import { LinkContent } from '../../components/KnowlegeBase/Content/LinkContent';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '3.75rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
    },
  },
  sidebar: {
    padding: '2.5rem 2.5rem 1.75rem 2.5rem',
    marginRight: '5.125rem',
    backgroundColor: Colors.backgroundSecondary,
    ...GlobalStyles.borderRadius,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      padding: '20px 19px 24px 14px',
      marginBottom: '2rem',
    },
  },
  input: {
    height: '2.5rem',
    marginTop: '3.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const KnowledgeBasePage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const [activeAccordion, setActiveAccordion] = useState<number>(-1);
  const [activeLink, setActiveLink] = useState<string>('');

  const onLinkClick = (linkTitle: string) => {
    if (activeLink !== linkTitle) {
      setActiveLink(linkTitle);
    }
  };

  const onAccordionStateChange = (id: number) => {
    setActiveAccordion(id);
    setActiveLink('');
  };

  const onKnowledgeBreadCrumbClick = () => {
    setActiveAccordion(-1);
    if (activeLink !== '') {
      setActiveLink('');
    }
  };

  const onLinkBreadCrumbClick = () => {
    setActiveLink('');
  };

  const onFindButtonClick = () => {};

  const renderContent = () => {
    if (activeAccordion === -1) {
      return <DefaultContent />;
    }
    if (activeLink !== '') {
      return <LinkContent />;
    }
    return <AccordionContent />;
  };

  return (
    <Layout>
      <KnowledgeBanner
        technology={activeAccordion}
        link={activeLink}
        onKnowledgeButtonClick={onKnowledgeBreadCrumbClick}
        onLinkButtonClick={onLinkBreadCrumbClick}
      />
      <Grid container className={classes.root}>
        <Grid item xs={12} md={5}>
          <div className={classes.sidebar}>
            {sidebarConstants.map((item, index) => {
              return (
                <CustomAccordion
                  onLinkClick={onLinkClick}
                  activeAccordion={activeAccordion}
                  key={index}
                  item={item}
                  isMenu
                  hasLine={index !== sidebarConstants.length - 1}
                  onAccordionStateChange={onAccordionStateChange}
                />
              );
            })}
            {activeAccordion !== -1 && (
              <div className={classes.input}>
                <InputWithButton onFindButtonClick={onFindButtonClick} />
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={7}>
          {renderContent()}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default withRouter(KnowledgeBasePage);
