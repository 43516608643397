import React from 'react';
import { Button, Grid, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { newsConstants } from '../Home/StockAndNewsBlock/NewsConstants';
import { NewsCard } from '../ui/Cards/NewsCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '100px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '22px',
    },
  },
  grid: {
    marginTop: '3.75rem',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
    },
  },
  button: {
    marginTop: '2.25rem',
  },
}));

export const DownNewsBlock: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const mdQuery = useMediaQuery(theme.breakpoints.down('md'));

  const onShowMoreButtonClick = () => {};

  const renderButton = () => {
    return (
      <Button variant="outlined" className={classes.button} onClick={onShowMoreButtonClick}>
        {t('Показать еще')}
      </Button>
    );
  };

  const renderNews = () => {
    if (!mdQuery) {
      return (
        <>
          <Grid spacing={5} container className={classes.grid}>
            {newsConstants.map((val, index) => {
              if (index < 3) {
                return (
                  <Grid item xs={12} sm={8} md={7} lg={4} key={index}>
                    <NewsCard item={val} />
                  </Grid>
                );
              }
              return null;
            })}
          </Grid>
          <Grid spacing={5} container className={classes.grid}>
            {newsConstants.map((val, index) => {
              if (index < 3) {
                return (
                  <Grid item xs={12} sm={8} md={7} lg={4} key={index}>
                    <NewsCard item={val} />
                  </Grid>
                );
              }
              return null;
            })}
          </Grid>
          {renderButton()}
        </>
      );
    }
    return (
      <>
        <Grid spacing={5} container className={classes.grid}>
          {newsConstants.map((val, index) => {
            if (index < 3) {
              return (
                <Grid item xs={12} sm={8} md={7} lg={4} key={index}>
                  <NewsCard item={val} />
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
        {renderButton()}
      </>
    );
  };

  return <div className={classes.root}>{renderNews()}</div>;
};
