import React from 'react';
import { makeStyles } from '@material-ui/core';
import ChehovCompany from '../../../../assets/companies/chehov-company.png';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    gap: '36px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '60px 15px 15px',
    // backgroundColor: Colors.backgroundSecondary,
  },
  content: {
    width: 'fit-content',
  },
  threeLine: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  midLine: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '36px 0',
  },
  first: {
    height: '39.11px',
    width: '137.01px',
  },
});

const ThirdPartnersSlide: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.midLine}>
          <img src={ChehovCompany} alt="" className={classes.first} />
        </div>
      </div>
    </div>
  );
};

export default ThirdPartnersSlide;
