import React from 'react';
import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { Colors } from '../../../constants/Colors';
import Person from '../../../assets/partners/person.png';
import { redirectToProject } from '../../../utils/redirectToProject';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  brandItem: {
    marginTop: '1.5rem',
  },
  brand: {
    position: 'relative',
    overflow: 'hidden',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '2.125rem 2.375rem',
    ...GlobalStyles.lightBorder,
    ...GlobalStyles.borderRadius,
    ...GlobalStyles.boxShadow,
    '& ul': {
      padding: '0 0 0 1rem',
      color: Colors.lightBlack,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '33px 14px 76px 22px',
    },
  },
  list: {
    zIndex: 2,
  },
  subtitle: {
    lineHeight: '26px',
    fontFamily: 'Raleway-SemiBold',
    color: Colors.lightBlack,
  },
  image: {
    margin: '-2.125rem -1.5rem -2.125rem 0.5rem',
    objectFit: 'contain',
    objectPosition: 'bottom',
    [theme.breakpoints.down(700)]: {
      margin: '-2.125rem -12.5rem -2.125rem -3.5rem',
      objectPosition: 'top',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '-7rem -12.5rem -2.125rem -3.5rem',
      objectPosition: 'top',
      position: 'absolute',
      right: '36px',
    },
  },
  whiteLabel: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2.125rem 2.375rem',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  title: {
    color: Colors.accent,
  },
  label: {
    color: Colors.lightBlack,
    margin: '0.625rem 0 1.875rem',
    [theme.breakpoints.down('xs')]: {
      margin: '10px 0 22px',
    },
  },
  button: {
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
    },
  },
  services: {
    [theme.breakpoints.down('xs')]: {
      fontFamily: 'Raleway-SemiBold',
      fontSize: '16px',
      lineHeight: '18.78px',
    },
  },
}));

const subtitles: string[] = [
  'Дополнительная часть партнерской программы.',
  'Позволяет использовать свой бренд в продвижении наших услуг.',
  'Ставка выплаты и другие параметры обсуждаются индивидуально.',
  'Возможно подключение индивидуального личного кабинета под вашим брендом.',
  'Защиту и консультационную поддержку мы возьмем на себя.',
];

export const AboutBrandBlock: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h2">{t('От своего бренда')}</Typography>
      <Grid spacing={10} container className={classes.grid}>
        <Grid item xs={12} sm={12} md={10} lg={8} className={classes.brandItem}>
          <div className={classes.brand}>
            <div className={classes.list}>
              <Typography variant="h3" component="div" className={classes.services}>
                {t('Свой бренд, наши услуги')}
              </Typography>
              <ul>
                {subtitles.map((item, index) => {
                  return (
                    <li key={index}>
                      <Typography variant="h4" component="div" className={classes.subtitle}>
                        {t(item)}
                      </Typography>
                    </li>
                  );
                })}
              </ul>
            </div>
            <img alt="Person" src={Person} className={classes.image} />
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={5} lg={4}>
          <div className={classes.whiteLabel}>
            <Typography variant="h4" component="div" className={classes.title}>
              WhiteLabel
            </Typography>
            <Typography variant="h6" component="p" className={classes.label}>
              {t(
                'это концепция, предусматривающая производство немарочных продуктов или услуг одной компанией и использование таких продуктов или услуг другой компанией под своим брендом (маркой)',
              )}
            </Typography>
            <Button variant="text" className={classes.button} onClick={redirectToProject}>
              {t('Стать партнером')}
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
