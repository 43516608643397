import React from 'react';
import ReactDOM from 'react-dom';
import { Box, CircularProgress } from '@material-ui/core';

import reportWebVitals from './reportWebVitals';
import App from './App';
import { Colors } from './constants/Colors';

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense
      fallback={
        <Box
          style={{
            margin: 0,
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="primary" style={{ color: Colors.accent }} />
        </Box>
      }
    >
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
