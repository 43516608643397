import React from 'react';
import { Button, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  LAYOUT_LEFT_RIGHT_PADDING_LG,
  LAYOUT_LEFT_RIGHT_PADDING_MD,
  LAYOUT_LEFT_RIGHT_PADDING_XL,
  LAYOUT_LEFT_RIGHT_PADDING_XS,
} from '../../../constants/UI';
import { InputWithButton } from '../Inputs/InputWithButton';
import { sidebarConstants } from '../../KnowlegeBase/Sidebar/SidebarConstants';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import HugeIdeaIcon from '../Icons/HugeIdeaIcon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '3.75rem',
    margin: `0 -${LAYOUT_LEFT_RIGHT_PADDING_XL}`,
    padding: `0.625rem ${LAYOUT_LEFT_RIGHT_PADDING_XL}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(69, 119, 250, 0.12)',
    ...GlobalStyles.lightBorder,
    [theme.breakpoints.down('lg')]: {
      margin: `0 -${LAYOUT_LEFT_RIGHT_PADDING_LG}`,
      padding: `0.625rem ${LAYOUT_LEFT_RIGHT_PADDING_LG}`,
    },
    [theme.breakpoints.down('md')]: {
      margin: `0 -${LAYOUT_LEFT_RIGHT_PADDING_MD}`,
      padding: `0.625rem ${LAYOUT_LEFT_RIGHT_PADDING_MD}`,
    },
    [theme.breakpoints.down('xs')]: {
      margin: `0 -${LAYOUT_LEFT_RIGHT_PADDING_XS}`,
      padding: `0.625rem ${LAYOUT_LEFT_RIGHT_PADDING_XS}`,
    },
  },
  inputContainer: {
    height: '100%',
    width: '55%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  breadCrumbsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumb: {
    height: '2.25rem !important',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      color: Colors.primary,
    },
    '& svg': {
      height: '11.61px',
      width: '14.63px',
      marginRight: '0.5rem',
    },
    '&:hover': {
      '& p': {
        color: Colors.white,
      },
    },
  },
  dot: {
    margin: '0 1.5rem',
    ...GlobalStyles.grayCircle,
  },
}));

type TProps = {
  technology: number;
  link: string;
  onKnowledgeButtonClick: () => void;
  onLinkButtonClick: () => void;
};

export const KnowledgeBanner: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { technology, link, onKnowledgeButtonClick, onLinkButtonClick } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const onFindButtonClick = () => {};

  const renderBreadcrumbs = () => {
    if (link !== '') {
      const technologyTitle = sidebarConstants.find((item) => item.id === technology)?.title;
      return (
        <div className={classes.breadCrumbsContainer}>
          <Button
            variant="contained"
            className={classes.breadcrumb}
            onClick={onKnowledgeButtonClick}
          >
            <HugeIdeaIcon />
            <Typography variant="body2" component="p">
              {t('База знаний')}
            </Typography>
          </Button>
          <div className={classes.dot} />
          <Button variant="contained" className={classes.breadcrumb} onClick={onLinkButtonClick}>
            <Typography variant="body2" component="p">
              {t(technologyTitle || '')}
            </Typography>
          </Button>
          {mobile && (
            <>
              <div className={classes.dot} />
              <Typography variant="h2">{t(link)}</Typography>
            </>
          )}
        </div>
      );
    }

    if (technology !== -1) {
      const technologyTitle = sidebarConstants.find((item) => item.id === technology)?.title;
      return (
        <div className={classes.breadCrumbsContainer}>
          <Button
            variant="contained"
            className={classes.breadcrumb}
            onClick={onKnowledgeButtonClick}
          >
            <HugeIdeaIcon />
            <Typography variant="body2" component="p">
              {t('База знаний')}
            </Typography>
          </Button>
          <div className={classes.dot} />
          <Typography variant="h2">{t(technologyTitle || '')}</Typography>
        </div>
      );
    }

    return <Typography variant="h2">{t('База знаний')}</Typography>;
  };

  return (
    <div className={classes.root}>
      {renderBreadcrumbs()}
      {technology === -1 && (
        <div className={classes.inputContainer}>
          {!isXs && <InputWithButton onFindButtonClick={onFindButtonClick} />}
        </div>
      )}
    </div>
  );
};
