import React from 'react';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import EarthIcon from '../../../assets/icons/earth.svg';
import ArrowIcon from '../../../assets/icons/down-arrow.svg';
import { Colors } from '../../../constants/Colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0 !important',
    backgroundColor: 'transparent !important',
    minWidth: 'auto  !important',
  },
  languageIcon: {
    height: '0.875rem',
    width: '0.875rem',
    backgroundImage: `url(${EarthIcon})`,
    marginRight: '0.5rem',
    ...GlobalStyles.icon,
    [theme.breakpoints.down('md')]: {
      height: '1.25rem',
      width: '1.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: '14.63px',
      width: '14.63px',
    },
  },
  arrowIcon: {
    height: '8px',
    width: '14px',
    backgroundImage: `url(${ArrowIcon})`,
    ...GlobalStyles.icon,
  },
  title: {
    marginRight: '0.25rem',
    color: Colors.lightBlack,
  },
}));

type TProps = {
  isFooter?: boolean;
};

export const LanguageButton: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isFooter } = props;
  // const theme = useTheme();
  // const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const onButtonClick = () => {};

  return (
    <Button className={classes.root} disableRipple onClick={onButtonClick}>
      <div className={classes.languageIcon} />
      {!isFooter && (
        <Typography variant="body1" component="p" className={classes.title}>
          {t('RU')}
        </Typography>
      )}
      <div className={classes.arrowIcon} />
    </Button>
  );
};
