import { CustomLinkProps } from '../../ui/Link/CustomLink';
import { TechnologyItemProps } from '../../ui/Cards/TechnologyItem';
import OracleIcon from '../../ui/Icons/OracleIcon';
import HttpIcon from '../../ui/Icons/HttpIcon';
import EncryptIcon from '../../ui/Icons/EncryptIcon';
import NginxIcon from '../../ui/Icons/NginxIcon';
import FingerPrintIcon from '../../ui/Icons/FingerPrintIcon';
import SpeedIcon from '../../ui/Icons/SpeedIcon';
import DiamondIcon from '../../ui/Icons/DiamondIcon';
import SslIcon from '../../ui/Icons/SslIcon';

export const popularThemes: CustomLinkProps[] = [
  {
    title: 'Услуги компании DDosa.net',
    url: '',
  },
  {
    title: 'Технологии',
    url: '',
  },
  {
    title: 'Помощь',
    url: '',
  },
  {
    title: 'Личный кабинет',
    url: '',
  },
];

export const popularArticles: CustomLinkProps[] = [
  {
    title: 'Как установить SSL',
    url: '',
  },
  {
    title: 'Что такое DNS',
    url: '',
  },
  {
    title: 'OracleLinux',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <OracleIcon />,
  },
  {
    title: 'HTTP2/0',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <HttpIcon />,
  },
  {
    title: 'Оплата на сайте',
    url: '',
  },
  {
    title: 'Let`s Encrypt',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <EncryptIcon />,
  },
  {
    title: 'Nginx',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <NginxIcon />,
  },
];

export const technologyItems: TechnologyItemProps[] = [
  {
    link: {
      title: 'Анализ GreatEey',
      url: '',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <FingerPrintIcon />,
    },
    subtitle:
      'Осуществляет постоянный мониторинг всего поступающего HTTP-трафика, при помощи машинного обучения формирует «образы» сайтов, на основании собранной статистики —  управляет фильтрами DDoS-защиты\n',
  },
  {
    link: {
      title: 'FastL',
      url: '',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <SpeedIcon />,
    },
    subtitle:
      'Технология ускорения загрузки сайтов, кеширование и сжатие. Все статические файлы раздаваемые с наших серверов - сжимаются с помощью gzip, Brotli, помещаются в оперативный кеш сервера точки фильтрации',
  },
  {
    link: {
      title: 'Adamant',
      url: '',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <DiamondIcon />,
    },
    subtitle:
      'Плагин, позволяющих проводить комплексный анализ и блокировку вредоносных HTTP запросов по различным критериям: соответствие RFC, наличие IP в блек-листах, запросы от неизвестных роботов, аномальная активность, превышение заданных клиентом лимитов...',
  },
];

export const technologyOpenSource: CustomLinkProps[] = [
  {
    title: 'OracleLinux',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <OracleIcon />,
  },
  {
    title: 'HTTP2/0',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <HttpIcon />,
  },
  {
    title: 'Let`s Encrypt',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <EncryptIcon />,
  },
  {
    title: 'Nginx',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <NginxIcon />,
  },
  {
    title: 'OpenSSL',
    url: '',
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <SslIcon />,
  },
];
