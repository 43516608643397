import { NewsCardProps } from '../../ui/Cards/NewsCard';
import NewsOne from '../../../assets/news/news-one.png';
import NewsTwo from '../../../assets/news/news-two.png';
import NewsThree from '../../../assets/news/news-three.png';

export const newsConstants: NewsCardProps[] = [
  {
    date: '06.07.2022',
    title: 'Поддержка от партнёров Ddosa.net',
    subtitle:
      'Новый вид поддержки позволит нашим клиентам  получить квалифицированную консультацию от специалистов компаний-партнёров.',
    imageUrl: NewsOne,
  },
  {
    date: '11.07.2022',
    title: 'Новости технологий безопасности данных',
    subtitle:
      'Немного описания новости Немного описания новости Немного описания новости Немного описания новости Немного описания новости',
    imageUrl: NewsTwo,
  },
  {
    date: '20.06.2022',
    title: 'Дайджест новостей за июнь',
    subtitle:
      'Новости сервисов, публикации в СМИ и блогах, бюллетени безопасности и обновление документации — в одном дайджесте.',
    imageUrl: NewsThree,
  },
  {
    date: '06.07.2022',
    title: 'Поддержка от партнёров Ddosa.net',
    subtitle:
      'Новый вид поддержки позволит нашим клиентам  получить квалифицированную консультацию от специалистов компаний-партнёров.',
    imageUrl: NewsOne,
  },
  {
    date: '11.07.2022',
    title: 'Новости технологий безопасности данных',
    subtitle:
      'Немного описания новости Немного описания новости Немного описания новости Немного описания новости Немного описания новости',
    imageUrl: NewsTwo,
  },
];
