import { TDirectionItemProps } from './DirectionItem';
import DirectionEarth from '../../../assets/partners/earth.png';
import DirectionShield from '../../../assets/partners/shield.png';

export const directionsConstants: TDirectionItemProps[] = [
  {
    id: 0,
    img: DirectionEarth,
    title: 'Защита веб-сайтов',
    subtitle:
      'Надежная защита от всех видов DDoS-атак без переноса сайта. Благодаря машинному обучению блокируются только вредоносные запросы.\n\nУскоряет сайт, защищает от ботов, снижает нагрузку.\nПодходит для малого, среднего и крупного бизнеса.\nТочки фильтрации на выбор: Россия или Европа.\n',
  },
  {
    id: 1,
    img: DirectionShield,
    title: 'Защита TCP-сервиса',
    subtitle:
      'Удаленная защита для ваших TCP сервисов.\nМоментально блокирует любые DDoS-атаки.\nЗначительно повышает отказоустойчивость вашего сервиса.\n\nТочки фильтрации на выбор: Россия или Европа.\n',
  },
];
