import { createTheme } from '@material-ui/core';
import { BORDER_RADIUS } from './sizes';
import { Colors } from '../constants/Colors';

const breakpoints = {
  values: {
    xs: 300,
    sm: 600,
    md: 1000,
    lg: 1200,
    xl: 1620,
  },
};

export const theme = createTheme({
  breakpoints,
  palette: {
    primary: {
      main: Colors.fontColor,
    },
    secondary: {
      main: Colors.fontColorSecondary,
    },
    error: {
      main: Colors.error,
    },
  },
  typography: {
    fontFamily: 'Raleway-Regular, Roboto, Arial, Helvetica',
    h1: {
      fontFamily: 'Raleway-Bold',
      fontSize: '45px',
      lineHeight: '51.75px',
      color: Colors.fontColor,
      fontFeatureSettings: `'pnum' on, 'lnum' on`,
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: '36px',
        lineHeight: '42.48px',
      },
    },
    h2: {
      fontFamily: 'Raleway-SemiBold',
      fontSize: '26px',
      lineHeight: '39px',
      color: Colors.fontColor,
      fontFeatureSettings: `'pnum' on, 'lnum' on`,
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: '20px',
        lineHeight: '25px',
      },
    },
    h3: {
      fontFamily: 'Raleway-SemiBold',
      fontSize: '20px',
      lineHeight: '25px',
      color: Colors.fontColor,
      fontFeatureSettings: `'pnum' on, 'lnum' on`,
      /* [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: '16px',
        lineHeight: '18.78px',
      }, */
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: '19px',
        lineHeight: '23.75px',
      },
    },
    h4: {
      fontFamily: 'Raleway-SemiBold',
      fontSize: '16px',
      lineHeight: '18.78px',
      color: Colors.fontColor,
      fontFeatureSettings: `'pnum' on, 'lnum' on`,
    },
    h5: {
      fontFamily: 'Raleway-SemiBold',
      fontSize: '14px',
      lineHeight: '16.44px',
      color: Colors.fontColor,
      fontFeatureSettings: `'pnum' on, 'lnum' on`,
    },
    h6: {
      fontFamily: 'Inter-Regular',
      fontSize: '15px',
      lineHeight: '17.61px',
      color: Colors.fontColor,
      fontFeatureSettings: `'pnum' on, 'lnum' on`,
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: '13px',
        lineHeight: '15.73px',
      },
    },
    body1: {
      fontFamily: 'Raleway-Medium',
      fontSize: '13px',
      lineHeight: '15.26px',
      color: Colors.fontColor,
      fontFeatureSettings: `'pnum' on, 'lnum' on`,
    },
    body2: {
      fontFamily: 'Inter-Regular',
      fontSize: '13px',
      lineHeight: '15.73px',
      color: Colors.fontColor,
      fontFeatureSettings: `'pnum' on, 'lnum' on`,
    },
    subtitle1: {
      fontFamily: 'Raleway-Regular',
      fontSize: '18px',
      lineHeight: '21.13px',
      color: Colors.fontColor,
      fontFeatureSettings: `'pnum' on, 'lnum' on`,
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: '13px',
        lineHeight: '15.73px',
      },
    },
    button: {
      textTransform: 'none',
      backgroundColor: Colors.primary,
      opacity: 1,
      '&.MuiButton-root': {
        backgroundColor: Colors.primary,
        height: '2.5rem',
        padding: '0.625rem 1.5rem',
        width: 'fit-content',
        color: Colors.white,
        fontFamily: 'Raleway-SemiBold',
        fontSize: '16px',
        lineHeight: 'initial',
        [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
          padding: '8.5px 20px',
        },
        '&.MuiButton-outlined': {
          backgroundColor: Colors.backgroundSecondary,
          border: 0,
          color: Colors.primary,
          '&:hover': {
            opacity: 1,
            color: Colors.white,
            backgroundColor: Colors.primary,
          },
        },
        '&.MuiButton-contained': {
          boxShadow: '8px 8px 24px rgba(0, 0, 0, 0.05)',
          backgroundColor: Colors.white,
          border: 0,
          color: Colors.primary,
          '&:hover': {
            opacity: 1,
            color: Colors.white,
            backgroundColor: Colors.primary,
          },
        },

        '&:hover': {
          opacity: 1,
          color: Colors.white,
          backgroundColor: Colors.primary,
        },
      },
    },
  },
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  spacing: 4,
  overrides: {
    MuiTypography: {
      root: {
        whiteSpace: 'pre-line',
      },
    },
  },
});
