import React from 'react';

// not current color
const ArborIcon: React.FC = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9701 3.00083H6.18355C5.99088 2.99427 5.79887 3.02655 5.61893 3.09575C5.439 3.16495 5.27484 3.26965 5.13622 3.40363C4.9976 3.5376 4.88736 3.69811 4.81208 3.87558C4.73679 4.05305 4.69799 4.24386 4.69799 4.43664C4.69799 4.62942 4.73679 4.82023 4.81208 4.9977C4.88736 5.17517 4.9976 5.33567 5.13622 5.46965C5.27484 5.60362 5.439 5.70833 5.61893 5.77753C5.79887 5.84673 5.99088 5.87901 6.18355 5.87244H6.20855H12.9717C16.295 5.87244 18.69 8.44906 18.69 11.8023C18.69 15.3022 16.295 17.9689 12.9717 17.9689C9.64848 17.9689 7.25186 15.3022 7.25186 11.8023C7.25186 9.73903 8.16185 7.96907 9.63515 6.91409H5.41357C4.47806 8.36996 3.98695 10.0668 4.00026 11.7973C4.00026 16.8772 7.71519 20.8338 12.9701 20.8338C18.225 20.8338 21.9382 16.8739 21.9382 11.794C21.9382 6.86409 18.2233 3.00083 12.9701 3.00083Z"
        fill="#97D700"
      />
      <path
        d="M20.7324 19.8223C20.7317 19.6235 20.7835 19.428 20.8824 19.2556C20.9797 19.0799 21.1232 18.9341 21.2974 18.834C21.4698 18.734 21.6656 18.6814 21.8649 18.6814C22.0642 18.6814 22.26 18.734 22.4324 18.834C22.6065 18.9317 22.7506 19.0752 22.8491 19.249C22.9231 19.3783 22.971 19.521 22.99 19.6688C23.0089 19.8167 22.9986 19.9668 22.9595 20.1107C22.9204 20.2545 22.8534 20.3893 22.7622 20.5072C22.6711 20.6252 22.5576 20.724 22.4282 20.7981C22.2989 20.8722 22.1562 20.9201 22.0083 20.939C21.8605 20.958 21.7104 20.9476 21.5665 20.9085C21.4227 20.8694 21.2879 20.8024 21.17 20.7113C21.052 20.6201 20.9532 20.5066 20.8791 20.3773C20.783 20.2081 20.7325 20.0169 20.7324 19.8223ZM20.8908 19.8223C20.8902 19.9937 20.935 20.1622 21.0208 20.3106C21.0846 20.4218 21.1699 20.5193 21.2715 20.5975C21.3732 20.6757 21.4894 20.733 21.6133 20.7661C21.7372 20.7993 21.8664 20.8076 21.9935 20.7906C22.1207 20.7737 22.2432 20.7317 22.3541 20.6673C22.5013 20.5813 22.624 20.4591 22.7107 20.3123C22.7969 20.1637 22.8423 19.9949 22.8423 19.8231C22.8423 19.6513 22.7969 19.4826 22.7107 19.334C22.6253 19.1863 22.5029 19.0634 22.3557 18.9773C22.2073 18.89 22.038 18.8445 21.8657 18.8456C21.6946 18.8444 21.5263 18.8899 21.3791 18.9773C21.2313 19.0632 21.1083 19.1861 21.0224 19.334C20.9351 19.4818 20.8896 19.6506 20.8908 19.8223ZM22.3324 19.5473C22.3342 19.6233 22.3121 19.6979 22.2691 19.7606C22.2243 19.8237 22.1616 19.8719 22.0891 19.899L22.4474 20.4956H22.1941L21.8824 19.9606H21.6657V20.5006H21.4491V19.1506H21.8424C21.9723 19.14 22.1019 19.1748 22.2091 19.249C22.2511 19.2859 22.2841 19.332 22.3055 19.3838C22.3269 19.4355 22.3361 19.4914 22.3324 19.5473ZM21.6657 19.7756H21.8324C21.9029 19.7788 21.9722 19.7563 22.0274 19.7123C22.0518 19.6922 22.0712 19.6667 22.0839 19.6377C22.0967 19.6088 22.1024 19.5772 22.1007 19.5456C22.1041 19.5147 22.1 19.4833 22.0887 19.4543C22.0774 19.4253 22.0592 19.3995 22.0357 19.379C21.9734 19.3421 21.9013 19.3252 21.8291 19.3306H21.6657V19.7756Z"
        fill="#97D700"
      />
    </svg>
  );
};

export default ArborIcon;
