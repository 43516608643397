import { TProfitCardProps } from './ProfitCard';
import Pig from '../../../assets/partners/pig.png';
import Product from '../../../assets/partners/product.png';
import Chair from '../../../assets/partners/chair.png';
import Bonus from '../../../assets/partners/bonus.png';

export const profitConstants: TProfitCardProps[] = [
  {
    img: Pig,
    title: 'Без затрат',
    subtitle: 'От вас нужна только рекомендация, далее с клиентом работаем мы',
  },
  {
    img: Product,
    title: 'Актуальный продукт',
    subtitle: 'Один из самых востребованных продуктов в последние годы. ',
  },
  {
    img: Chair,
    title: 'Личный кабинет',
    subtitle: 'От вас нужна только рекомендация, далее с клиентом работаем мы',
  },
  {
    img: Bonus,
    title: 'Бонусы',
    subtitle: 'От вас нужна только рекомендация, далее с клиентом работаем мы',
  },
];
