import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '20.625rem',
    padding: '1rem 1rem 3.125rem 1.875rem',
    backgroundColor: Colors.backgroundSecondary,
    ...GlobalStyles.borderRadius,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      padding: '15px 18px 23px 20px',
    },
  },
  containedRoot: {
    backgroundColor: Colors.white,
    ...GlobalStyles.border,
    ...GlobalStyles.boxShadow,
  },
  header: {
    display: 'flex',
  },
  leftHeader: {
    justifyContent: 'flex-end',
  },
  rightText: {
    textAlign: 'right',
  },
  circle: {
    height: '4.25rem',
    width: '4.25rem',
    padding: '0.75rem',
    backgroundColor: Colors.white,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-18px',
    },
  },
  icon: {
    height: '2.625rem',
    width: '2.625rem',
    ...GlobalStyles.icon,
  },
  containedCircle: {
    ...GlobalStyles.boxShadow,
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0em',
    },
  },
  subtitle: {
    color: Colors.lightBlack,
    margin: '0.625rem 1rem 0 0',
    [theme.breakpoints.down('xs')]: {
      margin: '10px 0 0 0',
    },
  },
  rightSubtitle: {
    color: Colors.lightBlack,
    margin: '0.625rem 0 0 1rem',
    [theme.breakpoints.down('xs')]: {
      margin: '10px 0 0 0',
    },
  },
}));

export type FeatureCardProps = {
  title: string;
  subtitle: string;
  icon: string;
  isContained: boolean;
  isLeft: boolean;
};

type TProps = {
  item: FeatureCardProps;
};

export const FeatureCard: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { item } = props;
  const { t } = useTranslation();

  return (
    <div
      className={`${classes.root} ${item.isContained && classes.containedRoot} ${
        item.isLeft && classes.rightText
      }`}
    >
      <div className={`${classes.header} ${!item.isLeft && classes.leftHeader}`}>
        <div className={`${classes.circle} ${!item.isContained && classes.containedCircle}`}>
          <div className={classes.icon} style={{ backgroundImage: `url(${item.icon})` }} />
        </div>
      </div>
      <Typography variant="h3" component="div" className={classes.title}>
        {t(item.title)}
      </Typography>
      <Typography
        variant="body2"
        component="p"
        className={`${!item.isLeft ? classes.subtitle : classes.rightSubtitle}`}
      >
        {t(item.subtitle)}
      </Typography>
    </div>
  );
};
