import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/ui/Layout/Layout';
import { Banner } from '../../components/ui/Banners/Banner';
import { PageDetails, PageDetailsProps } from '../../components/ui/Details/PageDetails';
import CertificatesBanner from '../../assets/backgrounds/certificates-banner.jpg';
import CertificatesXsBanner from '../../assets/backgrounds/certificate-xs-banner.png';
import { Colors } from '../../constants/Colors';
import { redirectToProject } from '../../utils/redirectToProject';

const useStyles = makeStyles({
  root: {},
  textButton: {
    padding: '0 !important',
    backgroundColor: 'transparent !important',
    color: Colors.darkGray,
    fontFamily: 'Raleway-Medium',
  },
});

const certificatedPageData: PageDetailsProps = {
  title: 'Ваш безопасный канал',
  subtitle:
    'Безопасность обеспечивается множеством компонентов и технологий, каждый из которых играет важную роль\n',
  descriptionOne:
    '\n' +
    'SSL (англ. Secure Sockets Layer — уровень защищённых сокетов) — криптографический протокол, который подразумевает более безопасную связь. Он использует асимметричную криптографию для аутентификации ключей обмена, симметричное шифрование для сохранения конфиденциальности, коды аутентификации сообщений для целостности сообщений. Протокол широко использовался для обмена мгновенными сообщениями и передачи голоса через IP (англ. Voice over IP — VoIP) в таких приложениях, как электронная почта, интернет-факс и др. В 2014 году правительство США сообщило об уязвимости в текущей версии протокола[1]. SSL должен быть исключён из работы в пользу TLS (см. CVE-2014-3566).\n' +
    '\n' +
    'SSL изначально разработан компанией Netscape Communications для добавления протокола HTTPS в свой веб-браузер Netscape Navigator. Впоследствии на основании протокола SSL 3.0 был разработан и принят стандарт RFC, получивший имя TLS.\n' +
    '\n' +
    'Протокол SSL обеспечивает защищённый обмен данными за счёт двух следующих элементов:\n',
  descriptionTwo:
    '\nАутентификация\n' +
    'Шифрование\n' +
    'SSL использует асимметричную криптографию для аутентификации ключей обмена, симметричный шифр для сохранения конфиденциальности, коды аутентификации сообщений для целостности сообщений.\n' +
    '\n' +
    'Протокол SSL предоставляет «безопасный канал», который имеет три основных свойства:\n' +
    '\n' +
    'Канал является частным. Шифрование используется для всех сообщений после простого диалога, который служит для определения секретного ключа.\n' +
    'Канал аутентифицирован. Серверная сторона диалога всегда аутентифицируется, а клиентская делает это опционально.\n' +
    'Канал надёжен. Транспортировка сообщений включает в себя проверку целостности.\n' +
    'Преимуществом SSL является то, что он независим от прикладного протокола. Протоколы приложений (HTTP, FTP, TELNET и т. д.) могут работать поверх протокола SSL совершенно прозрачно, то есть SSL может согласовывать алгоритм шифрования и ключ сессии, а также аутентифицировать сервер до того, как приложение примет или передаст первый байт сообщения.',
};

const CertificatesPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onGetButtonClick = () => {
    redirectToProject();
  };

  return (
    <Layout>
      <Banner
        title="Сертификаты"
        subtitle="Мы поддерживаем технологии криптографии для сайтов: TLS v1.2, TLS 1.3, или  SSL 2.0, SSL 3.0 и TLS 1.0, TLS 1.1."
        background={CertificatesBanner}
        xsBackground={CertificatesXsBanner}
        leftButton={<Button onClick={onGetButtonClick}>{t('Подключиться')}</Button>}
        rightButton={
          <Typography variant="h6" component="p" className={classes.textButton}>
            {t('Бесплатно')}
          </Typography>
        }
      />
      <PageDetails item={certificatedPageData} />
    </Layout>
  );
};

export default withRouter(CertificatesPage);
