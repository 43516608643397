import React, { useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-reveal';
import { useHistory } from 'react-router';
import { CustomAccordion, CustomAccordionProps } from '../../ui/Accordion/CustomAccordion';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { ProjectPath } from '../../../constants/ProjectPath';
import { redirectToProject } from '../../../utils/redirectToProject';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // height: '23.5rem',
  },
  grid: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'center',
    },
  },
  subtitle: {
    color: Colors.lightBlack,
    margin: '0.875rem 0 3.125rem',
    [theme.breakpoints.down('md')]: {
      margin: '0.875rem 0 1rem',
    },
  },
  button: {
    margin: '0.75rem 0.75rem 0 0',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      width: '100% !important',
      '&.MuiButton-root': {
        fontSize: '14px',
        lineHeight: '16.44px',
      },
    },
  },
  faqContainer: {
    padding: '2.5rem 2.5rem 1.75rem 2.5rem',
    backgroundColor: Colors.backgroundSecondary,
    ...GlobalStyles.borderRadius,
    [theme.breakpoints.down('md')]: {
      marginTop: '1.625rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '26px',
      padding: '20px 20px 27px 20px',
    },
  },
  allFaqButtonContainer: {
    float: 'right',
    marginTop: '26px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '51px',
      '& button': {
        width: '100% !important',
      },
    },
  },
  allFaqButton: {
    [theme.breakpoints.down('xs')]: {
      '&.MuiButton-root': {
        fontSize: '14px',
        lineHeight: '16.44px',
      },
    },
  },
  desktopGrid: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobileGrid: {
    marginTop: '2rem',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttonContainer: {
    marginTop: '0.75rem',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexWrap: 'nowrap',
      gap: '12px',
    },
  },
  linksText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
      lineHeight: '18px',
    },
  },
}));

export type TProps = {
  items: CustomAccordionProps[];
};

export const FaqBlock: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { items } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [activeAccordion, setActiveAccordion] = useState<number>(-1);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const onAccordionStateChange = (id: number) => {
    setActiveAccordion(id);
  };

  const onLearnButtonClick = () => {
    history.push(ProjectPath.KnowledgeBase);
  };

  const onSupportButtonClick = () => {
    history.push(ProjectPath.TechnicalSupport);
  };

  const onAllFaqButtonClick = () => {
    redirectToProject();
    // history.push(ProjectPath.Faq);
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.grid}>
        <Grid item md={12} lg={3}>
          <Typography variant="h2" component="div">
            {t('Частые вопросы')}
          </Typography>
          <Fade>
            <Typography variant="h6" component="p" className={classes.subtitle}>
              {t(
                'Здесь мы отвечаем на большинство возникающих вопросов. Если вы не нашли подходящий возможно вам удастся найти ответ в разделе База знаний или в чате с нашей техподдержкой.',
              )}
            </Typography>
          </Fade>
          <Fade>
            <div className={classes.desktopGrid}>
              <Typography variant="h6" component="p">
                {t('Полезные ссылки:')}
              </Typography>
              <div>
                <Button className={classes.button} variant="outlined" onClick={onLearnButtonClick}>
                  {t('База знаний')}
                </Button>
                <Button
                  className={classes.button}
                  variant="outlined"
                  onClick={onSupportButtonClick}
                >
                  {t('Поддержка')}
                </Button>
              </div>
            </div>
          </Fade>
        </Grid>
        <Grid item sm={12} md={8} lg={8} className={classes.faqContainer}>
          <Fade>
            {items.map((item, index) => {
              return (
                <CustomAccordion
                  key={index}
                  item={item}
                  activeAccordion={activeAccordion}
                  onAccordionStateChange={onAccordionStateChange}
                  hasLine={index !== items.length - 1}
                />
              );
            })}
          </Fade>
          <div className={classes.allFaqButtonContainer}>
            <Fade>
              <Button
                variant="contained"
                onClick={onAllFaqButtonClick}
                className={classes.allFaqButton}
              >
                {t('Все вопросы-ответы')}
              </Button>
            </Fade>
          </div>
        </Grid>
        <div className={classes.mobileGrid}>
          <Fade>
            <Typography variant="h6" className={classes.linksText} component="p">
              {t('Полезные ссылки:')}
            </Typography>
            {isXs ? (
              <Grid container className={classes.buttonContainer}>
                <Grid item xs={6} sm>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    onClick={onLearnButtonClick}
                  >
                    {t('База знаний')}
                  </Button>
                </Grid>
                <Grid item xs={6} sm>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    onClick={onSupportButtonClick}
                  >
                    {t('Поддержка')}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <div>
                <Button className={classes.button} variant="outlined" onClick={onLearnButtonClick}>
                  {t('База знаний')}
                </Button>
                <Button
                  className={classes.button}
                  variant="outlined"
                  onClick={onSupportButtonClick}
                >
                  {t('Поддержка')}
                </Button>
              </div>
            )}
          </Fade>
        </div>
      </Grid>
    </div>
  );
};
