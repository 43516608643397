import React from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { technologyItems, technologyOpenSource } from './ContentConstants';
import { CustomLink } from '../../ui/Link/CustomLink';
import { TechnologyItem } from '../../ui/Cards/TechnologyItem';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: '1.625rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '40px',
    },
  },
  link: {
    marginBottom: '0.875rem',
  },
  rightGrid: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'start',
    },
  },
  subtitle: {
    marginTop: '120px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '62px',
      marginBottom: '40px',
    },
  },
}));

export const AccordionContent: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography variant="h3" component="div" className={classes.title}>
          {t('Собственные разработки')}
        </Typography>
        {technologyItems.map((item, index) => {
          return <TechnologyItem key={index} item={item} />;
        })}
      </Grid>
      <Grid item xs={12} sm={6} className={classes.rightGrid}>
        <div>
          <Typography variant="h3" component="div" className={classes.title}>
            {t('Технологии Open Source')}
          </Typography>
          {technologyOpenSource.map((item, index) => {
            return (
              <div key={index} className={classes.link}>
                <CustomLink item={item} />
              </div>
            );
          })}
          <Typography variant="h3" component="div" className={classes.subtitle}>
            {t('Технологии другое')}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};
