import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PlusIcon from '../../../assets/icons/plus.svg';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { Colors } from '../../../constants/Colors';
import { CustomLink, CustomLinkProps } from '../Link/CustomLink';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&.MuiAccordion-root': {
      boxShadow: 'none',
      paddingTop: 0,
      minHeight: 'auto',
      backgroundColor: 'transparent',
      '&:before': {
        display: 'none',
      },
    },
  },
  circle: {
    marginRight: '2.5rem',
    height: '2.5rem',
    width: '2.5rem',
    minHeight: '2.5rem',
    minWidth: '2.5rem',
    padding: '0.24rem',
    backgroundColor: Colors.white,
    borderRadius: '50%',
    ...GlobalStyles.boxShadow,
    [theme.breakpoints.down('xs')]: {
      marginRight: '1rem',
    },
  },
  icon: {
    height: '100%',
    width: '100%',
    backgroundImage: `url(${PlusIcon})`,
    ...GlobalStyles.icon,
  },
  subtitle: {
    color: Colors.lightBlack,
    marginTop: '1rem',
  },
  summary: {
    '&.MuiAccordionSummary-root': {
      padding: 0,
      minHeight: '48px',
      '& .MuiAccordionSummary-content': {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
      },
      '&.Mui-expanded': {
        minHeight: '48px',
      },
    },
  },
  details: {
    '&.MuiAccordionDetails-root': {
      marginLeft: '5rem',
      padding: 0,
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        marginLeft: '3.5rem',
      },
    },
  },
  title: {
    fontSize: '20px',
    lineHeight: '125%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0em',
    },
  },
  line: {
    margin: '2rem auto',
    height: '1px',
    width: '100%',
    backgroundColor: Colors.borderColor,
    [theme.breakpoints.down('xs')]: {
      margin: '26px auto',
      height: '1px',
      width: '100%',
      backgroundColor: Colors.borderColor,
    },
  },
  menuLine: {
    margin: '1rem auto',
    height: '1px',
    width: '100%',
    backgroundColor: Colors.borderColor,
  },
  links: {},
  link: {
    marginTop: '1rem',
  },
}));

export type CustomAccordionProps = {
  id: number;
  title: string;
  subtitle?: string;
  links?: CustomLinkProps[];
};

export type TProps = {
  item: CustomAccordionProps;
  isMenu?: boolean;
  hasLine: boolean;
  activeAccordion: number;
  onAccordionStateChange: (id: number) => void;
  onLinkClick?: (link: string) => void;
};

export const CustomAccordion: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { item, hasLine, onAccordionStateChange, activeAccordion, onLinkClick, isMenu } = props;
  const { t } = useTranslation();

  const onChange = () => {
    if (onAccordionStateChange) {
      onAccordionStateChange?.(activeAccordion !== item.id ? item.id : -1);
    }
  };

  const renderLinks = () => {
    if (item.links) {
      return (
        <div className={classes.links}>
          {item.links.map((link, index) => {
            return (
              <div key={index} className={classes.link}>
                <CustomLink item={link} onClick={onLinkClick} />
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const renderSubtitle = () => {
    if (item.subtitle) {
      return (
        <Typography variant="h6" component="p" className={classes.subtitle}>
          {t(item.subtitle)}
        </Typography>
      );
    }
    return null;
  };

  const renderAccordion = () => {
    return (
      <Accordion
        expanded={activeAccordion === item.id}
        className={classes.root}
        onChange={onChange}
      >
        <AccordionSummary className={classes.summary}>
          <div
            className={[
              classes.circle,
              'circle-plus closed',
              activeAccordion === item.id ? 'opened' : '',
            ].join(' ')}
          >
            <div className="circle">
              <div className="horizontal" />
              <div className="vertical" />
            </div>
          </div>
          <div className="circle-plus-two closed">
            <div className="circle">
              <div className="horizontal" />
              <div className="vertical" />
            </div>
          </div>
          <Typography variant="h3" component="div" className={classes.title}>
            {t(item.title)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {renderLinks()}
          {renderSubtitle()}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderLine = () => {
    return <div className={`${isMenu ? classes.menuLine : classes.line}`} />;
  };

  return (
    <>
      {renderAccordion()}
      {hasLine && renderLine()}
    </>
  );
};
