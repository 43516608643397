import React, { useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import BasePopup from '../BasePopup';
import { rateButtonsList, TRateButton } from '../../../Protection/SitesRatesBlock/SitesRatesBlock';
import { sitesRatesConstants } from '../../../Protection/SitesRatesBlock/SitesRatesConstants';
import { SitesRateCard } from '../../../Protection/SitesRatesBlock/SitesRateCard';
import { Colors } from '../../../../constants/Colors';
import { GlobalStyles } from '../../../../styles/GlobalStyles';
import 'swiper/swiper-bundle.css';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    gap: '1.25rem',
    flexWrap: 'nowrap',
    [theme.breakpoints.down(1000)]: {
      flexWrap: 'wrap',
    },
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'unset',
    gap: '1.25rem',
  },
  button: {
    '&.MuiButton-root': {
      height: '2.25rem',
      width: '7.875rem',
      padding: '0.625rem 1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      fontFamily: 'Raleway-SemiBold',
      '&.MuiButton-outlined': {
        color: Colors.darkGray,
        ...GlobalStyles.lightBorder,
      },
    },
  },
  activeButton: {
    '&.MuiButton-root': {
      '&.MuiButton-outlined': {
        color: Colors.primary,
        backgroundColor: 'rgba(69, 119, 250, 0.12)',
      },
    },
  },
  buttonText: {
    color: Colors.accent,
  },
  buttonsGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.625rem',
    minWidth: '40rem',
    marginBottom: '2rem',
  },
  swiper: {
    minHeight: '353px',
    overflow: 'initial',
    ...GlobalStyles.pagination,
  },
  sliderContainer: {
    height: 'auto',
  },
  slide: {
    height: '100%',
  },
}));

type TProps = {
  onClose: () => void;
  onDontKnowButtonClick: () => void;
};

const SiteProtectionTariffsPopup: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const [activeMonth, setActiveMonth] = useState<number>(1);
  const [isDetailsHide, setIsDetailsHide] = useState(true);
  const [activeCard, setActiveCard] = useState('');
  const { onClose, onDontKnowButtonClick } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const underLine = useMediaQuery(theme.breakpoints.down(1000));

  const renderButton = (item: TRateButton, index: number) => {
    return (
      <Button
        className={`${classes.button} ${activeMonth === item.month && classes.activeButton}`}
        variant="outlined"
        key={index}
        onClick={() => setActiveMonth(item.month)}
      >
        {t(item.title)}
        {item.percent && (
          <Typography variant="h5" component="div" className={classes.buttonText}>
            {item.percent}
          </Typography>
        )}
      </Button>
    );
  };

  const renderSlides = () => {
    return sitesRatesConstants.map((val, index) => {
      return (
        <SwiperSlide key={index} className={classes.sliderContainer}>
          <div className={classes.slide}>
            <SitesRateCard
              item={val}
              hideDetails={isDetailsHide}
              onDetailsShow={() => setIsDetailsHide(false)}
              isPressed={val.title === activeCard}
              onChooseClick={(title: string) => setActiveCard(title)}
              activeMonth={activeMonth}
            />
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <BasePopup
      title="Тариф"
      onClose={onClose}
      content={
        <>
          <div className={classes.buttonsGroup}>
            {rateButtonsList.map((item, index) => renderButton(item, index))}
          </div>
          {underLine ? (
            <Swiper
              className={classes.swiper}
              spaceBetween={20}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                720: {
                  slidesPerView: 2,
                },
              }}
              direction="horizontal"
            >
              {renderSlides()}
            </Swiper>
          ) : (
            <Grid container className={classes.grid}>
              {sitesRatesConstants.map((card, index) => {
                return (
                  <Grid item key={index}>
                    <SitesRateCard
                      item={card}
                      hideDetails={isDetailsHide}
                      onDetailsShow={() => setIsDetailsHide(false)}
                      isPressed={card.title === activeCard}
                      onChooseClick={(title: string) => setActiveCard(title)}
                      activeMonth={activeMonth}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </>
      }
      leftButton={
        <Button variant="outlined" onClick={onDontKnowButtonClick}>
          {t('Не знаю')}
        </Button>
      }
      rightButton={<Button onClick={onClose}>{t('Далее')}</Button>}
    />
  );
};

export default SiteProtectionTariffsPopup;
