import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Fade } from 'react-reveal';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { SwiperButtonsContainer } from '../../ui/Buttons/SwiperButtonsContainer';
import { historyBlockConstants } from './HistoryBlockConstants';
import { HistoryCard } from './HistoryCard';

SwiperCore.use([Navigation, Pagination]);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    swiper: {
      marginTop: '1.625rem',
      ...GlobalStyles.pagination,
    },
    slide: {
      padding: '0 0 3.5rem 0',
    },
    footer: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    desktopKnowledgeButton: {
      position: 'absolute',
      top: '-2rem',
      zIndex: 2,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    mobileKnowledgeButton: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    lg: {
      [theme.breakpoints.up('sm')]: {
        width: '60% !important',
      },
      [theme.breakpoints.up('md')]: {
        width: '40% !important',
      },
    },
    md: {
      [theme.breakpoints.up('sm')]: {
        width: '26% !important',
      },
      [theme.breakpoints.up('md')]: {
        width: '18.5% !important',
      },
    },
  }),
);

export const HistorySwiper: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const historyPrevButtonClass = 'history-prev-button';
  const historyNextButtonClass = 'history-next-button';

  const renderSlides = () => {
    return historyBlockConstants.map((item, index) => {
      if (item.size === 'sm' && historyBlockConstants[index + 1].size === 'sm') {
        return (
          <SwiperSlide key={index} className={`${classes.slide} ${classes.md}`}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <HistoryCard item={item} />
              </Grid>
              <Grid item xs={12}>
                <HistoryCard item={historyBlockConstants[index + 1]} />
              </Grid>
            </Grid>
          </SwiperSlide>
        );
      }

      if (item.size === 'sm' && historyBlockConstants[index + 1].size !== 'sm') {
        return null;
      }

      return (
        <SwiperSlide
          key={index}
          className={`${classes.slide} ${item.size === 'lg' ? classes.lg : classes.md}`}
        >
          <HistoryCard item={item} />
        </SwiperSlide>
      );
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h2">{t('Наша история')}</Typography>
        <SwiperButtonsContainer
          prevButtonClass={historyPrevButtonClass}
          nextButtonClass={historyNextButtonClass}
        />
      </div>
      <Fade>
        <Swiper
          className={classes.swiper}
          slidesPerView="auto"
          spaceBetween={20}
          navigation={{
            prevEl: `.${historyPrevButtonClass}`,
            nextEl: `.${historyNextButtonClass}`,
          }}
          direction="horizontal"
          pagination={{ clickable: true }}
        >
          {renderSlides()}
        </Swiper>
      </Fade>
    </div>
  );
};
