import React, { useEffect, useState } from 'react';
import { Button, IconButton, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import {
  LAYOUT_LEFT_RIGHT_PADDING_LG,
  LAYOUT_LEFT_RIGHT_PADDING_MD,
  LAYOUT_LEFT_RIGHT_PADDING_XL,
  LAYOUT_LEFT_RIGHT_PADDING_XS,
} from '../../../../constants/UI';
import { Colors } from '../../../../constants/Colors';
import { CompanyLogo } from '../../Logo/CompanyLogo';
import { GlobalStyles } from '../../../../styles/GlobalStyles';
import { CustomLink } from '../../Link/CustomLink';
import { LanguageButton } from '../../Buttons/LanguageButton';
import UserIconSvg from '../../../../assets/icons/user.svg';
import IdeaIcon from '../../Icons/IdeaIcon';
import UserIcon from '../../Icons/UserIcon';
import { ProjectPath } from '../../../../constants/ProjectPath';
import { Menu } from './Menu/Menu';
import DownArrowIcon from '../../Icons/DownArrowIcon';
import BurgerIcon from '../../../../assets/icons/burger.svg';
import CloseBurgerIcon from '../../../../assets/icons/burger-close.svg';
import ChatIconSm from '../../Icons/ChatIconSm';
import PhoneIconSm from '../../Icons/PhoneIconSm';
import AttachPopup from '../../Popups/AttachPopup';
import ServicePopup from '../../Popups/ServicePopup/ServicePopup';
import SiteProtectionTariffsPopup from '../../Popups/ServicePopup/SiteProtectionTariffsPopup';
import ServiceProtectionTariffsPopup from '../../Popups/ServicePopup/ServiceProtectionTariffsPopup';

const useStyles = makeStyles((theme: Theme) => ({
  up: {
    zIndex: 3,
    backgroundColor: Colors.white,
    height: '2.25rem',
    borderBottom: `1px solid ${Colors.backgroundSecondary}`,
    padding: `0.5rem ${LAYOUT_LEFT_RIGHT_PADDING_XL}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      padding: `0.5rem ${LAYOUT_LEFT_RIGHT_PADDING_LG}`,
    },
    [theme.breakpoints.down('md')]: {
      padding: `0.5rem ${LAYOUT_LEFT_RIGHT_PADDING_MD}`,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  upContainers: {
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    margin: '0 0.875rem',
    ...GlobalStyles.grayCircle,
  },
  lastDot: {
    marginLeft: '0.875rem',
    ...GlobalStyles.grayCircle,
  },
  down: {
    position: 'sticky',
    top: 0,
    zIndex: 3,
    backgroundColor: Colors.white,
    height: 'calc(100% - 2.25rem)',
    padding: `1rem ${LAYOUT_LEFT_RIGHT_PADDING_XL}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      padding: `1rem ${LAYOUT_LEFT_RIGHT_PADDING_LG}`,
    },
    [theme.breakpoints.down('md')]: {
      padding: `1rem ${LAYOUT_LEFT_RIGHT_PADDING_MD}`,
    },
  },
  downShadow: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
    boxShadow: '8px 8px 24px rgba(0, 0, 0, 0.05)',
  },
  links: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    marginRight: '3.25rem',
    [theme.breakpoints.down('md')]: {
      marginRight: '1.25rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '14px',
    },
  },
  link: {
    marginRight: '1.875rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginRight: '1.25rem',
    },
  },
  mobile: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    zIndex: 3,
    backgroundColor: Colors.white,
    [theme.breakpoints.down('md')]: {
      padding: `1rem ${LAYOUT_LEFT_RIGHT_PADDING_MD}`,
    },
    [theme.breakpoints.down('xs')]: {
      padding: `1rem ${LAYOUT_LEFT_RIGHT_PADDING_XS}`,
    },
  },
  mobileContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  profileButton: {
    padding: 0,
    marginRight: '22px',
  },
  profileIcon: {
    height: '1.875rem',
    width: '1.875rem',
    backgroundImage: `url(${UserIconSvg})`,
    ...GlobalStyles.icon,
  },
  burgerMenuButton: {
    height: '2.25rem',
    width: '2.625rem',
    backgroundColor: Colors.backgroundSecondary,
  },
  burgerIcon: {
    height: '19px',
    width: '22px',
    ...GlobalStyles.icon,
  },
  status: {
    color: Colors.lightBlack,
    fontFamily: 'Raleway-Medium',
  },
  desktopKnowledgeBase: {
    marginLeft: '14px',
  },
}));

export const Header: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isShowAttachPopup, setIsShowAttachPopup] = useState(false);
  const [isShowSiteProtectionPopup, setIsShowSiteProtectionPopup] = useState(false);
  const [isShowServiceProtectionPopup, setIsShowServiceProtectionPopup] = useState(false);
  const [isShowServicesPopup, setIsShowServicesPopup] = useState(false);
  const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] =
    useState<boolean>(false);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 36);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const onAttackButtonClick = () => {
    setIsShowAttachPopup(true);
  };

  const onMenuButtonClick = () => {
    setShowMenu(!showMenu);
  };

  const onServicesButtonClick = () => {
    setIsShowServicesPopup(true);
  };

  const onDontKnowButtonClick = () => {
    setIsShowAttachPopup(true);
    setIsShowServicesPopup(false);
    setIsShowServiceProtectionPopup(false);
    setIsShowSiteProtectionPopup(false);
  };

  const renderDownHeader = () => {
    if (desktop) {
      return (
        <div
          className={
            isScrollValueMoreThanHeaderHeight
              ? [classes.down, classes.downShadow].join(' ')
              : classes.down
          }
        >
          <div className={classes.links}>
            <div className={classes.logo}>
              <CompanyLogo />
            </div>
            <div className={classes.link}>
              <CustomLink
                isLg
                item={{
                  title: 'Защита сайтов',
                  url: ProjectPath.SiteProtection,
                }}
                textColor={
                  location.pathname === ProjectPath.SiteProtection
                    ? Colors.darkGray
                    : Colors.fontColor
                }
              />
            </div>
            <div className={classes.link}>
              <CustomLink
                isLg
                item={{
                  title: 'Защита портов',
                  url: ProjectPath.PortsProtection,
                }}
                textColor={
                  location.pathname === ProjectPath.PortsProtection
                    ? Colors.darkGray
                    : Colors.fontColor
                }
              />
            </div>
            <div className={classes.link}>
              <CustomLink
                isLg
                item={{
                  title: 'Сервисы',
                }}
                isMenuActive={showMenu}
                onClick={onMenuButtonClick}
                prefix={<DownArrowIcon />}
              />

              {showMenu && <Menu onCloseButtonClick={onMenuButtonClick} />}
            </div>
            <div className={classes.link}>
              <CustomLink
                isLg
                item={{
                  title: 'Тарифы',
                }}
                isMenuActive={isShowServicesPopup}
                onClick={onServicesButtonClick}
                prefix={<DownArrowIcon />}
              />
            </div>
            <div className={classes.link}>
              <CustomLink
                isLg
                item={{
                  title: 'О компании',
                  url: ProjectPath.About,
                }}
                textColor={
                  location.pathname === ProjectPath.About ? Colors.darkGray : Colors.fontColor
                }
              />
            </div>
            <div className={classes.link}>
              <CustomLink
                isLg
                item={{
                  title: 'Блог',
                  url: ProjectPath.Blog,
                }}
                textColor={
                  location.pathname === ProjectPath.Blog ? Colors.darkGray : Colors.fontColor
                }
              />
            </div>
          </div>
          <Button variant="outlined" onClick={onAttackButtonClick}>
            {t('Под атакой?')}
          </Button>
        </div>
      );
    }
    return (
      <div
        className={
          isScrollValueMoreThanHeaderHeight
            ? [classes.mobile, classes.downShadow].join(' ')
            : classes.mobile
        }
      >
        <div className={classes.mobileContainer}>
          <div className={classes.logo}>
            <CompanyLogo />
          </div>
          <LanguageButton />
        </div>
        <div className={classes.mobileContainer}>
          <IconButton className={classes.profileButton}>
            <div className={classes.profileIcon} />
          </IconButton>
          <IconButton className={classes.burgerMenuButton} onClick={onMenuButtonClick}>
            <div
              className={classes.burgerIcon}
              style={{
                backgroundImage: showMenu ? `url(${CloseBurgerIcon})` : `url(${BurgerIcon})`,
              }}
            />
          </IconButton>
        </div>
        {showMenu && <Menu onCloseButtonClick={onMenuButtonClick} />}
      </div>
    );
  };

  return (
    <>
      {isShowSiteProtectionPopup && (
        <SiteProtectionTariffsPopup
          onDontKnowButtonClick={onDontKnowButtonClick}
          onClose={() => setIsShowSiteProtectionPopup(false)}
        />
      )}
      {isShowServiceProtectionPopup && (
        <ServiceProtectionTariffsPopup
          onDontKnowButtonClick={onDontKnowButtonClick}
          onClose={() => setIsShowServiceProtectionPopup(false)}
        />
      )}
      {isShowAttachPopup && <AttachPopup onClose={() => setIsShowAttachPopup(false)} />}
      {isShowServicesPopup && (
        <ServicePopup
          onClickService={() => {
            setIsShowServicesPopup(false);
            setIsShowServiceProtectionPopup(true);
          }}
          onClickSite={() => {
            setIsShowServicesPopup(false);
            setIsShowSiteProtectionPopup(true);
          }}
          onDontKnowButtonClick={onDontKnowButtonClick}
          onClose={() => setIsShowServicesPopup(false)}
        />
      )}
      <div className={classes.up}>
        <div className={classes.upContainers}>
          <LanguageButton />
          <CustomLink
            isSm
            className={classes.desktopKnowledgeBase}
            item={{
              icon: <IdeaIcon />,
              title: 'База знаний',
              url: ProjectPath.KnowledgeBase,
            }}
            textColor={Colors.lightBlack}
          />
          {/* <Typography variant="body2" component="p" className={classes.status}>
            {t('Статус:')}
          </Typography>
          <Status /> */}
        </div>
        <div className={classes.upContainers}>
          <CustomLink
            isSm
            isPhone
            item={{
              icon: <PhoneIconSm />,
              title: '8 800 888 77 66',
              url: '',
            }}
            textColor={Colors.lightBlack}
          />
          <div className={classes.dot} />
          <CustomLink
            isSm
            item={{
              icon: <ChatIconSm />,
              title: 'Чат-поддержка',
              url: ProjectPath.TechnicalSupport,
            }}
            textColor={Colors.lightBlack}
          />
          <div className={classes.dot} />
          <CustomLink
            isSm
            item={{
              icon: <UserIcon />,
              title: 'Личный кабинет',
              url: ProjectPath.Profile,
            }}
            textColor={Colors.lightBlack}
          />
        </div>
      </div>
      {renderDownHeader()}
    </>
  );
};
