import React from 'react';

const ComputerIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0.25H5C2.38 0.25 0.25 2.38 0.25 5V11C0.25 13.62 2.38 15.75 5 15.75H7.33C7.35 16.12 7.35 16.48 7.27 16.84C7.14 17.44 6.88 17.91 6.63 18.25H6C5.59 18.25 5.25 18.59 5.25 19C5.25 19.41 5.59 19.75 6 19.75H14C14.41 19.75 14.75 19.41 14.75 19C14.75 18.59 14.41 18.25 14 18.25H13.37C13.13 17.91 12.87 17.44 12.74 16.84C12.65 16.44 12.64 16.07 12.66 15.75H15.01C17.63 15.75 19.76 13.62 19.76 11V5C19.76 2.38 17.63 0.25 15.01 0.25H15ZM11.27 17.16C11.35 17.54 11.48 17.9 11.65 18.25H8.36C8.52 17.9 8.65 17.54 8.74 17.16C8.84 16.7 8.88 16.22 8.86 15.75H11.15C11.13 16.22 11.17 16.7 11.27 17.16ZM18.25 11C18.25 12.79 16.79 14.25 15 14.25H5C3.21 14.25 1.75 12.79 1.75 11V5C1.75 3.21 3.21 1.75 5 1.75H15C16.79 1.75 18.25 3.21 18.25 5V11Z"
        fill="currentColor"
      />
      <path
        d="M15.9997 6.25H12.9997C12.7997 6.25 12.6097 6.33 12.4697 6.47L9.89969 9.04L7.40969 7.38C7.10969 7.18 6.71969 7.22 6.45969 7.47L3.45969 10.47C3.16969 10.76 3.16969 11.24 3.45969 11.53C3.60969 11.68 3.79969 11.75 3.98969 11.75C4.17969 11.75 4.36969 11.68 4.51969 11.53L7.08969 8.96L9.57969 10.62C9.87969 10.82 10.2697 10.78 10.5297 10.53L13.3097 7.75H15.9997C16.4097 7.75 16.7497 7.41 16.7497 7C16.7497 6.59 16.4097 6.25 15.9997 6.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ComputerIcon;
