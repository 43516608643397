import React from 'react';
import { Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-reveal';
import { GlobalStyles } from '../../styles/GlobalStyles';
import { Colors } from '../../constants/Colors';
import MissionOne from '../../assets/backgrounds/mission-one.png';
import MissionTwo from '../../assets/backgrounds/mission-two.png';
import MissionThree from '../../assets/backgrounds/mission-three.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  item: {
    maxWidth: '27.5rem',
    height: 'fit-content',
    ...GlobalStyles.borderRadius,
    '& img': {
      width: '100%',
    },
  },
  imagesContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  imageGrid: {
    height: 'fit-content',
    '& img': {
      width: '100%',
    },
    borderRadius: '1.875rem',
    [theme.breakpoints.down(1880)]: {
      maxWidth: '27.5rem',
    },
  },
  textCard: {
    height: 'auto',
    padding: '1.875rem',
    backgroundColor: Colors.backgroundSecondary,
  },
  subtitle: {
    marginTop: '0.625rem',
  },
}));

export const MissionBlock: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isLgDown = useMediaQuery(theme.breakpoints.down(1881));
  const isSmUp = useMediaQuery(theme.breakpoints.up(998));

  return (
    <Grid container className={classes.root} spacing={0}>
      {isLgDown && isSmUp ? (
        <Grid item xs={12}>
          <Grid container className={classes.imagesContainer}>
            <Grid item xs={6} className={classes.imageGrid}>
              <Fade>
                <img src={MissionOne} alt="" />
              </Fade>
            </Grid>
            <Grid item xs={6} className={classes.imageGrid}>
              <Fade>
                <img src={MissionTwo} alt="" />
              </Fade>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item className={classes.item}>
          <Grid container spacing={5}>
            <Grid item xs={6} sm={12} className={classes.imageGrid}>
              <Fade>
                <img src={MissionOne} alt="" />
              </Fade>
            </Grid>
            <Grid item xs={6} sm={12} className={classes.imageGrid}>
              <Fade>
                <img src={MissionTwo} alt="" />
              </Fade>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item className={`${classes.item} ${classes.textCard}`}>
        <Fade>
          <Typography variant="h2">{t('Миссия')}</Typography>
          <Typography variant="h6" component="p" className={classes.subtitle}>
            {t(
              'Мир стремительно цифровизируется. Практически каждая компания в мире имеет собственный сайт, хотя-бы визитку. Вместе с этим распространяются угрозы информационной безопасности: утечки данных, взломы, фишинг, а так-же DDoS-атаки. \n' +
                '\n' +
                'DDoS-атаки наносят прямой ущерб — можно потерять как клиентов, так и репутацию надежной компании. Конечно, чем крупнее бизнес — тем серьезнее потери, но как быть, если ваш бизнес еще не приносит огромной прибыли, а конкуренты и злоумышленники уже пытаются «парализовать» вашу работу? \n' +
                '\n' +
                'Внедрение собственной защиты обойдется в миллионы вложенных денег, аренда защиты у крупного поставщика в десятки, а не редко и в сотни тысяч рублей в месяц, как быть в этой ситуации малому и среднему бизнесу?\n' +
                '\n' +
                'Мы стремимся предложить альтернативу, сделать качественную защиту от DDoS-атак доступной для каждого!\n',
            )}
          </Typography>
        </Fade>
      </Grid>
      <Grid item className={classes.item}>
        <Fade>
          <img src={MissionThree} alt="" />
        </Fade>
      </Grid>
    </Grid>
  );
};
