import { CustomAccordionProps } from '../../ui/Accordion/CustomAccordion';

export const sidebarConstants: CustomAccordionProps[] = [
  {
    id: 0,
    title: 'Услуги',
    links: [
      {
        title: 'Анализ GreatEey',
        url: '',
      },
      {
        title: 'FastL',
        url: '',
      },
      {
        title: 'Adamant',
        url: '',
      },
      {
        title: 'OracleLinux',
        url: '',
      },
      {
        title: 'HTTP2/0',
        url: '',
      },
      {
        title: 'Let`s Encrypt',
        url: '',
      },
      {
        title: 'Nginx',
        url: '',
      },
      {
        title: 'OpenSSL',
        url: '',
      },
    ],
  },
  {
    id: 1,
    title: 'Про атаки',
    links: [
      {
        title: 'Анализ GreatEey',
        url: '',
      },
      {
        title: 'FastL',
        url: '',
      },
      {
        title: 'Adamant',
        url: '',
      },
      {
        title: 'OracleLinux',
        url: '',
      },
      {
        title: 'HTTP2/0',
        url: '',
      },
      {
        title: 'Let`s Encrypt',
        url: '',
      },
      {
        title: 'Nginx',
        url: '',
      },
      {
        title: 'OpenSSL',
        url: '',
      },
    ],
  },
  {
    id: 2,
    title: 'Технологии',
    links: [
      {
        title: 'Анализ GreatEey',
        url: '',
      },
      {
        title: 'FastL',
        url: '',
      },
      {
        title: 'Adamant',
        url: '',
      },
      {
        title: 'OracleLinux',
        url: '',
      },
      {
        title: 'HTTP2/0',
        url: '',
      },
      {
        title: 'Let`s Encrypt',
        url: '',
      },
      {
        title: 'Nginx',
        url: '',
      },
      {
        title: 'OpenSSL',
        url: '',
      },
    ],
  },
  {
    id: 3,
    title: 'Кабинет',
    links: [
      {
        title: 'Анализ GreatEey',
        url: '',
      },
      {
        title: 'FastL',
        url: '',
      },
      {
        title: 'Adamant',
        url: '',
      },
      {
        title: 'OracleLinux',
        url: '',
      },
      {
        title: 'HTTP2/0',
        url: '',
      },
      {
        title: 'Let`s Encrypt',
        url: '',
      },
      {
        title: 'Nginx',
        url: '',
      },
      {
        title: 'OpenSSL',
        url: '',
      },
    ],
  },
  {
    id: 4,
    title: 'Покупка',
    links: [
      {
        title: 'Анализ GreatEey',
        url: '',
      },
      {
        title: 'FastL',
        url: '',
      },
      {
        title: 'Adamant',
        url: '',
      },
      {
        title: 'OracleLinux',
        url: '',
      },
      {
        title: 'HTTP2/0',
        url: '',
      },
      {
        title: 'Let`s Encrypt',
        url: '',
      },
      {
        title: 'Nginx',
        url: '',
      },
      {
        title: 'OpenSSL',
        url: '',
      },
    ],
  },
  {
    id: 5,
    title: 'Еще тема',
  },
  {
    id: 6,
    title: 'Еще тема',
  },
  {
    id: 7,
    title: 'Еще тема',
  },
];
