import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Fade } from 'react-reveal';
import { Layout } from '../../components/ui/Layout/Layout';
import ErrorWithDescriptionBanner from '../../components/Error/ErrorWithDescriptionBanner';
import AccessImage from '../../assets/errors/requirement-stop.png';

const AccessErrorDescriptionPage: React.FC<RouteComponentProps> = () => {
  return (
    <Layout>
      <Fade>
        <ErrorWithDescriptionBanner
          title="Ошибка доступа"
          subtitle="Доступ к запрашиваемому ресурсу временно ограничен"
          buttonText="На главную"
          leftTitle="Частые причины ошибки:"
          leftSubtitle={[
            'Счёт за услугу по фильтрации трафика (защита от DDoS) просрочен более чем на 3 (трое) суток.',
            'Истёк пробный период услуг по фильтрации трафика (защиты от DDoS).',
            'Нарушение условий пользовательского договора.',
          ]}
          rightTitle="Если вы администратор сайта:"
          rightSubtitle={[
            'Оплатить счёт за услуги с помощью личного кабинета.',
            'Связаться со службой поддержки с помощью личного кабинета или позвонить на телефон горячей линии: 8-800-100-2116.',
            'Вернуть записи типа "A" или NS сервера на значения по умолчанию.',
          ]}
          content={<img style={{ margin: '-48px 0' }} src={AccessImage} alt="error" />}
        />
      </Fade>
    </Layout>
  );
};

export default withRouter(AccessErrorDescriptionPage);
