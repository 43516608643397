import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import Mask from '../../../assets/protected/animation/mask.png';
import Spider from '../../../assets/protected/animation/spider.png';
import Virus from '../../../assets/protected/animation/virus.png';
import Person from '../../../assets/protected/animation/person.png';
import Basket from '../../../assets/protected/animation/basket.png';
import Disk from '../../../assets/protected/animation/disk.png';
import Panel from '../../../assets/protected/animation/panel.png';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import Key from '../../../assets/protected/animation/key.svg';
import Scan from '../../../assets/protected/animation/scan.svg';
import Indicator from '../../../assets/protected/animation/indicator.svg';
import Diamond from '../../../assets/protected/animation/diamond.svg';

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
    padding: '0.625rem 0 0 0',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '26.5rem',
  },
  panelsContainer: {
    top: '50%',
    left: 'calc(100vw / -4)',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    rotate: '-90deg',
  },
  panels: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  panel: {
    width: '115px',
    opacity: 0.1,
    position: 'absolute',
    height: '100vw',
  },
  footer: {
    position: 'absolute',
    right: 0,
    top: '38%',
    textAlign: 'left',
  },
  icons: {
    margin: '2.5rem 0 0.75rem',
    gap: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  icon: {
    height: '19.5px',
    width: '19.5px',
    opacity: 0.4,
    ...GlobalStyles.icon,
  },
  leftGridItem: {
    marginLeft: 'calc(100vw / 3.6)',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.875rem',
  },
  rightGridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '1rem',
    marginLeft: '1rem',
    position: 'relative',
    top: '-25px',
  },
  firstItem: {
    marginLeft: '2rem',
  },
  firstItemText: {
    marginLeft: '1.5rem',
  },
  horizontal: {
    position: 'relative',
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: 'column',
  },
  description: {
    maxWidth: '12.5rem',
  },
  title: {},
  subtitle: {
    fontFamily: 'Inter-Regular',
    marginTop: '0.375rem',
    color: Colors.darkGray,
  },
  smDot: {
    height: '7.87px',
    width: '7.87px',
    borderRadius: '50%',
  },
  mdDot: {
    height: '15.92px',
    width: '15.92px',
    borderRadius: '50%',
  },
  lgDot: {
    height: '25.63px',
    width: '25.63px',
    borderRadius: '50%',
  },
  smLine: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'calc(100vw / 72)',
    position: 'absolute',
  },
  mdLine: {
    position: 'absolute',
    display: 'flex',
    gap: '28px',
    left: '-190px',
    top: '-162px',
    rotate: '90deg',
  },
  lgLine: {
    position: 'absolute',
    display: 'flex',
    gap: '40px',
    top: '275px',
    left: '-178px',
    rotate: '90deg',
  },
  text: {
    position: 'absolute',
    top: '286px',
    left: '-106px',
    textAlign: 'right',
    fontFamily: 'Inter-Regular',
    fontSize: '15px',
    lineHeight: '17.61px',
  },
  titleXs: {
    fontFamily: 'Inter-Regular',
    fontSize: '15px',
    lineHeight: '17.61px',
  },
});

const MobileAnimationContainer: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderMdLine = (color: string, elements: number) => {
    return new Array(elements).fill(0).map((val, index) => {
      return (
        <div
          className={classes.mdDot}
          key={index}
          style={{
            backgroundColor: color,
            animation:
              index % 2 === 0 ? 'dot-opacity-from 1.25s infinite' : 'dot-opacity-to 1.25s infinite',
          }}
        />
      );
    });
  };

  const renderSmLine = (color: string) => {
    return new Array(3).fill(0).map((val, index) => {
      return (
        <div
          className={classes.smDot}
          key={index}
          style={{
            opacity: index % 2 === 0 ? 0.3 : 1,
            backgroundColor: color,
          }}
        />
      );
    });
  };

  const renderLgLine = () => {
    return new Array(7).fill(0).map((val, index) => {
      return (
        <>
          {index === 0 && (
            <div
              className={classes.smLine}
              style={{
                top: 'calc(100vw / -8)',
                left: '9px',
              }}
            >
              {renderSmLine(Colors.accent)}
            </div>
          )}
          <div
            className={classes.lgDot}
            key={index}
            style={{
              animation:
                index % 2 === 0
                  ? 'dot-accent-to-black 1.25s infinite'
                  : 'dot-black-to-accent 1.25s infinite',
            }}
          />
          {index === 3 && (
            <div
              className={classes.smLine}
              style={{
                left: '205px',
                top: 'calc(100vw / -8)',
              }}
            >
              {renderSmLine(Colors.lightBlack)}
            </div>
          )}
        </>
      );
    });
  };

  return (
    <Grid container className={classes.root}>
      <div className={classes.panelsContainer}>
        <div className={classes.panels}>
          <img
            src={Panel}
            alt="panel"
            className={classes.panel}
            style={{ right: '-25px', animation: 'first-panel 1.25s infinite' }}
          />
          <img
            src={Panel}
            alt="panel"
            className={classes.panel}
            style={{ right: '-50px', animation: 'second-panel 1.25s infinite' }}
          />
          <img
            src={Panel}
            alt="panel"
            className={classes.panel}
            style={{ right: '-75px', animation: 'third-panel 1.25s infinite' }}
          />
          <img
            src={Panel}
            alt="panel"
            className={classes.panel}
            style={{ right: '-100px', animation: 'four-panel 1.25s infinite' }}
          />
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.icons}>
          <div className={classes.icon} style={{ backgroundImage: `url(${Key})` }} />
          <div className={classes.icon} style={{ backgroundImage: `url(${Scan})` }} />
          <div className={classes.icon} style={{ backgroundImage: `url(${Diamond})` }} />
          <div className={classes.icon} style={{ backgroundImage: `url(${Indicator})` }} />
        </div>
        <Typography variant="h6" component="p" className={classes.text} style={{ top: '243px' }}>
          {t('Многоуровневая защита от DDosa.net')}
        </Typography>
      </div>
      <Grid item xs={8} className={classes.leftGridItem}>
        <div className={classes.lgLine}>{renderLgLine()}</div>
        <div className={classes.horizontal}>
          <img src={Person} alt="person" style={{ width: '128.88px', height: '128.88px' }} />
          <div className={classes.description} style={{ marginLeft: 0, maxWidth: '18rem' }}>
            <Typography variant="h6" component="p" className={classes.titleXs}>
              {t('Пользователи и сервисы')}
            </Typography>
            <Typography variant="body1" component="p" className={classes.subtitle}>
              {t(
                'Целевые пользователи сайтом и легальные сервисы работающие с вашим сайтом или сервером',
              )}
            </Typography>
          </div>
        </div>
        <div className={classes.horizontal}>
          <div>
            <img src={Virus} alt="virus" style={{ height: '62.58px', width: '62.58px' }} />
            <img src={Mask} alt="mask" style={{ height: '62.58px', width: '62.58px' }} />
            <img src={Spider} alt="spider" style={{ height: '62.58px', width: '62.58px' }} />
          </div>
          <Typography
            variant="h6"
            component="p"
            className={`${classes.description} ${classes.titleXs}`}
          >
            {t('Боты, атаки и вирусы')}
          </Typography>
          <Typography variant="body1" component="p" className={classes.subtitle}>
            {t('Все нежелательные активности в сторону сайта и сервиса')}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.rightGridItem}>
        <div className={classes.horizontal} style={{ marginLeft: 'calc(100vw / 4)' }}>
          <div className={classes.mdLine} style={{ left: '-174px', top: '-96px' }}>
            {renderMdLine(Colors.lightBlack, 7)}
          </div>
          <img src={Basket} alt="basket" style={{ height: '77px', width: '77px' }} />
          <div className={classes.description}>
            <Typography variant="h6" component="p" className={classes.titleXs}>
              {t('Боты, спам-сервисы и др.')}
            </Typography>
            <Typography variant="body1" component="p" className={classes.subtitle}>
              {t('Все атаки, спам-сервисы не получат доступ к сайту или портам сервера.')}
            </Typography>
          </div>
        </div>
        <div
          className={classes.horizontal}
          style={{ flexDirection: 'row', alignItems: 'center', top: '40px' }}
        >
          <div className={classes.mdLine} style={{ top: '-232px' }}>
            {renderMdLine(Colors.accent, 10)}
          </div>
          <img
            src={Disk}
            alt="disk"
            style={{ marginRight: '0.5rem', height: '72.34px', width: '72.34px' }}
          />
          <div className={classes.description} style={{ marginTop: '28px' }}>
            <Typography variant="h6" component="p" className={classes.titleXs}>
              {t('Ваш сайт или сервер')}
            </Typography>
            <Typography variant="body1" component="p" className={classes.subtitle}>
              {t('На сервер попадают только реальные пользователи и сервисы.')}
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default MobileAnimationContainer;
