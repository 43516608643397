import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { CustomLink, CustomLinkProps } from '../../ui/Link/CustomLink';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    minHeight: '11.375rem',
    padding: '30px 23px 30px 30px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.backgroundSecondary,
    ...GlobalStyles.borderRadius,
    [theme.breakpoints.down('xs')]: {
      padding: '20px 20px 18px 20px',
    },
  },
  white: {
    backgroundColor: Colors.white,
    ...GlobalStyles.boxShadow,
  },
  header: {
    display: 'flex',
    marginBottom: '1rem',
  },
  content: {},
  link: {
    fontFamily: 'Inter-Regular',
    fontSize: '15px',
    lineHeight: '18.15px',
  },
  subtitle: {
    color: Colors.newBlack,
    letterSpacing: '0.01em',
  },
}));

export type TechnologyCardProps = {
  subtitle: string;
  link: CustomLinkProps;
};

type TProps = {
  item: TechnologyCardProps;
  isWhite?: boolean;
};

export const TechnologyCard: React.FC<TProps> = (props: TProps) => {
  const { item, isWhite } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={`${classes.root} ${isWhite ? classes.white : ''}`}>
      <div className={classes.header}>
        <CustomLink item={item.link} className={classes.link} />
      </div>
      <div className={classes.content}>
        <Typography variant="body2" component="p" className={classes.subtitle}>
          {t(item.subtitle)}
        </Typography>
      </div>
    </div>
  );
};
