import { CompanyCardProps } from './HugeCompanyCard';
import CompanyShield from '../../../assets/backgrounds/company-shield.png';
import CompanyTwo from '../../../assets/backgrounds/company-huge-two.png';
import CompanySpider from '../../../assets/backgrounds/company-spider.png';
import CompanyOne from '../../../assets/backgrounds/company-huge-one.png';
import CompanyHeadphones from '../../../assets/backgrounds/company-headphones.png';
import CompanyFour from '../../../assets/backgrounds/company-huge-four.png';
import CompanyLifebuoy from '../../../assets/backgrounds/company-lifebuoy.png';
import CompanyThree from '../../../assets/backgrounds/company-huge-three.png';
import CompanyRocket from '../../../assets/backgrounds/company-rocket.png';

export type CompanyInNumbersRow = {
  id: number;
  items: CompanyCardProps[];
};

export const companyInNumbersConstants: CompanyInNumbersRow[] = [
  {
    id: 0,
    items: [
      {
        title: '10 000+',
        subtitle: 'Отбили более 10000 DDoS-атак',
        img: CompanyShield,
      },
      {
        title: '52',
        subtitle: 'ЦОДа партнеров',
        img: CompanyTwo,
      },
      {
        title: '80 видов',
        subtitle: 'Блокируем все виды известных DDoS-атак',
        img: CompanySpider,
      },
    ],
  },
  {
    id: 1,
    items: [
      {
        title: '3 Tbps',
        subtitle: 'Более 3 Tbps общая емкость каналов\n',
        img: CompanyOne,
      },
      {
        title: '24/7',
        subtitle: 'Круглосуточная поддержка ',
        img: CompanyHeadphones,
      },
      {
        title: '7 суток',
        subtitle: 'Тестовый период до 7 суток',
        img: CompanyFour,
      },
    ],
  },
  {
    id: 2,
    items: [
      {
        title: '1000+',
        subtitle: 'Защитили более 1000 проектов',
        img: CompanyLifebuoy,
      },
      {
        title: '100%',
        subtitle: 'Средний Uptime серверов близок к 100%',
        img: CompanyThree,
      },
      {
        title: '10 сек.',
        subtitle: 'Среднее время реакции защиты 10 секунд',
        img: CompanyRocket,
      },
    ],
  },
];
