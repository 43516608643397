import React from 'react';
import { IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-reveal';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import CrossIcon from '../../../assets/icons/cross-icon.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    top: 0,
    right: 0,
    left: 0,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    backgroundColor: Colors.borderColor,
  },
  popup: {
    maxHeight: '100%',
    maxWidth: '100%',
    padding: '1.875rem 2.5rem 2.375rem',
    backgroundColor: Colors.white,
    ...GlobalStyles.borderRadius,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '1.875rem 16px 2.375rem',
      maxHeight: '100vh',
    },
  },
  header: {
    marginBottom: '1.625rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    marginRight: '-1.25rem',
    height: '2.625rem',
    width: '2.625rem',
    backgroundImage: `url(${CrossIcon})`,
    ...GlobalStyles.icon,
    [theme.breakpoints.down('xs')]: {
      marginRight: '-0.25rem',
    },
  },
  content: {},
  footer: {
    marginTop: '1.875rem',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1.25rem',
  },
}));

type TProps = {
  title: string;
  leftButton?: React.ReactNode;
  rightButton?: React.ReactNode;
  content?: React.ReactNode;
  onClose: () => void;
};

const BasePopup: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { leftButton, rightButton, content, title, onClose } = props;
  const { t } = useTranslation();

  return (
    <Fade>
      <div className={classes.root}>
        <div className={classes.popup}>
          <div className={classes.header}>
            <Typography variant="h2">{t(title)}</Typography>
            <IconButton className={classes.closeButton} onClick={onClose} />
          </div>
          <div className={classes.content}>{content}</div>
          <div className={classes.footer}>
            {leftButton}
            {rightButton}
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default BasePopup;
