import React from 'react';

// not current color
const CiscoIcon: React.FC = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2887 21C11.6475 21 9.43467 20.1751 7.6501 18.5253C5.88337 16.8582 5 14.6961 5 12.0391C5 9.38205 5.8566 7.21129 7.56979 5.52677C9.30083 3.84226 11.5494 3 14.3155 3C16.2902 3 17.8194 3.31089 18.903 3.93267C18.9637 3.96747 19 4.03258 19 4.10251V7.49287C19 7.64812 18.8303 7.74479 18.694 7.67049C17.442 6.98809 16.0985 6.64689 14.6635 6.64689C13.1287 6.64689 11.8706 7.14182 10.8891 8.13169C9.90758 9.1042 9.41683 10.4067 9.41683 12.0391C9.41683 13.6715 9.90758 14.9653 10.8891 15.9204C11.8706 16.8582 13.1644 17.3271 14.7706 17.3271C16.2517 17.3271 17.5547 16.9804 18.6796 16.2869C18.8172 16.2021 19 16.2983 19 16.4599V19.8975C19 19.9674 18.9637 20.0325 18.903 20.0673C17.8189 20.6891 16.2808 21 14.2887 21Z"
        fill="#C80020"
      />
    </svg>
  );
};

export default CiscoIcon;
