import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { ProtectionCard } from '../../ui/Cards/ProtectionCard';
import ProtectIcon from '../../../assets/icons/protect-blue.svg';
import EarthIcon from '../../../assets/icons/earth-blue.svg';
import {
  certificates,
  protectedDns,
  tcpServiceLinks,
  webServiceLinks,
} from './UpperBlockConstants';
import { UpperBlockCard } from '../../ui/Cards/UpperBlockCard';
import { UpperBlockSwiper } from './UpperBlockSwiper';
import { ProjectPath } from '../../../constants/ProjectPath';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    zIndex: 1,
    marginTop: '-3.125rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-4.688rem',
    },
  },
  subtitle: {
    marginTop: '1.25rem',
  },
  horizontalCards: {
    // width: '33%',
    gap: '1.875rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  static: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  swiper: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export const UpperBlock: React.FC = () => {
  const classes = useStyles();

  const renderStatic = () => {
    return (
      <div className={classes.static}>
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <ProtectionCard
              title="Защита TCP-сервиса"
              subtitle={
                'Удаленная защита для ваших TCP сервисов.\nМоментально блокирует любые DDoS-атаки.\nЗначительно повышает отказоустойчивость вашего сервиса.\n\nТочки фильтрации на выбор: Россия или Европа.\n'
              }
              links={tcpServiceLinks}
              icon={ProtectIcon}
              url={ProjectPath.PortsProtection}
            />
          </Grid>
          <Grid item xs={4}>
            <ProtectionCard
              title="Защита веб-сайтов"
              subtitle={
                'Надежная защита от всех видов DDoS-атак без переноса сайта. Благодаря машинному обучению блокируются только вредоносные запросы.\n\nУскоряет сайт, защищает от ботов, снижает нагрузку.\nПодходит для малого, среднего и крупного бизнеса.\n'
              }
              links={webServiceLinks}
              icon={EarthIcon}
              url={ProjectPath.SiteProtection}
            />
          </Grid>
          <Grid item xs={4}>
            <Grid container className={classes.horizontalCards}>
              <Grid lg={12}>
                <UpperBlockCard
                  link={protectedDns}
                  subtitle="Безопасность обеспечивается множеством компонентов и технологий, каждый из которых играет важную роль"
                />
              </Grid>
              <Grid lg={12}>
                <UpperBlockCard
                  link={certificates}
                  subtitle="Мы поддерживаем технологии криптографии для сайтов: TLS v1.2, TLS 1.3, или  SSL 2.0, SSL 3.0 и TLS 1.0, TLS 1.1."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderSwiper = () => {
    return (
      <div className={classes.swiper}>
        <UpperBlockSwiper />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {renderStatic()}
      {renderSwiper()}
    </div>
  );
};
