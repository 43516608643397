import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '13.125rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    companyLogo: {
      height: '3.75rem',
      objectFit: 'scale-down',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
    },
  }),
);

export type PartnerCardProps = {
  imageUrl: string;
};

type TProps = {
  item: PartnerCardProps;
};

export const PartnerCard: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { item } = props;

  return (
    <div className={classes.card}>
      <img alt="" src={item.imageUrl} className={classes.companyLogo} />
    </div>
  );
};
