import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/ui/Layout/Layout';
import { FaqBlock } from '../../components/Home/FaqBlock/FaqBlock';
import { partnersFaqData } from '../../components/Home/FaqBlock/FaqBlockConstants';
import { StockAndNewsBlock } from '../../components/Home/StockAndNewsBlock/StockAndNewsBlock';
import { UpperProtectionBlock } from '../../components/Protection/UpperProtectionBlock';
import {
  siteActiveProtectionLinks,
  siteInactiveProtectionLinks,
} from '../../components/Protection/UpperProtectionLinks';
import { SitesRatesBlock } from '../../components/Protection/SitesRatesBlock/SitesRatesBlock';
import WebsiteBanner from '../../assets/backgrounds/website-banner-background.jpg';
import WebsiteXsBanner from '../../assets/backgrounds/website-xs-banner.png';
import { Banner } from '../../components/ui/Banners/Banner';
import AnimationBlock from '../../components/Protection/AnimationBlock/AnimationBlock';
import AttachPopup from '../../components/ui/Popups/AttachPopup';
import { redirectToProject } from '../../utils/redirectToProject';

const useStyles = makeStyles((theme: Theme) => ({
  upperBlock: {
    marginTop: '81px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '50px',
    },
  },
  animationBlock: {
    marginTop: '89px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '50px',
    },
  },
  ratesBlock: {
    marginTop: '130px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '50px',
    },
  },
  faqBlock: {
    marginTop: '88px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '62px',
    },
  },
  stockAndNewsBlock: {
    marginTop: '93px',
    marginBottom: '63px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '80px',
      marginBottom: '50px',
    },
  },
}));

const SiteProtectionPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isShowAttachPopup, setIsShowAttachPopup] = useState(false);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const onConnectButtonClick = () => {
    redirectToProject();
  };
  const onQuestionButtonClick = () => {
    setIsShowAttachPopup(true);
  };

  return (
    <Layout>
      {isShowAttachPopup && <AttachPopup onClose={() => setIsShowAttachPopup(false)} />}
      <Banner
        title={isXs ? 'Защита\n веб-сайтов' : 'Защита веб-сайтов'}
        subtitle="Надежная защита от всех видов DDoS-атак без переноса сайта."
        background={WebsiteBanner}
        leftButton={<Button onClick={onConnectButtonClick}>{t('Подключиться')}</Button>}
        maxWidth
        xsBackground={WebsiteXsBanner}
        rightButton={
          <Button variant="contained" onClick={onQuestionButtonClick}>
            {t('Задать вопрос')}
          </Button>
        }
      />
      <div className={classes.upperBlock}>
        <UpperProtectionBlock
          title={'Надежная защита от всех видов DDoS-атак без переноса сайта.\n'}
          subtitle={
            'Геораспределенная сеть позволяет фильтровать атаки более 1.5Tbps.\n' +
            '\n' +
            'Благодаря машинному обучению блокируются только вредоносные запросы.\n' +
            '\n' +
            'Ускоряет сайт, защищает от ботов, снижает нагрузку.\n' +
            'Подходит для малого, среднего и крупного бизнеса.\n' +
            'Точки фильтрации на выбор: Россия или Европа.\n'
          }
          item={{
            activeLinks: siteActiveProtectionLinks,
            inactiveLinks: siteInactiveProtectionLinks,
            inactiveTitle: 'Работает на OpenSource',
          }}
        />
      </div>
      <div className={classes.animationBlock}>
        <AnimationBlock />
      </div>
      <div className={classes.ratesBlock}>
        <SitesRatesBlock />
      </div>
      <div className={classes.faqBlock}>
        <FaqBlock items={partnersFaqData} />
      </div>
      <div className={classes.stockAndNewsBlock}>
        <StockAndNewsBlock />
      </div>
    </Layout>
  );
};

export default withRouter(SiteProtectionPage);
