import React from 'react';

// not current color
const EmailIcon: React.FC = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7885 5.7692H6.71154C4.80077 5.7692 3.25 7.31996 3.25 9.23073V14.7692C3.25 16.68 4.80077 18.2307 6.71154 18.2307H17.7885C19.6992 18.2307 21.25 16.68 21.25 14.7692V9.23073C21.25 7.31996 19.6992 5.7692 17.7885 5.7692ZM17.7885 7.15381C18.0931 7.15381 18.3792 7.21843 18.6377 7.33843L13.0162 12.2584C12.5823 12.6369 11.9269 12.6369 11.4931 12.2584L5.86231 7.33843C6.12077 7.21843 6.40692 7.15381 6.71154 7.15381H17.7885ZM19.8654 14.7692C19.8654 15.9138 18.9331 16.8461 17.7885 16.8461H6.71154C5.56692 16.8461 4.63462 15.9138 4.63462 14.7692V9.23073C4.63462 8.89843 4.71769 8.58458 4.86538 8.29843L10.5792 13.3015C11.0592 13.7169 11.65 13.9292 12.25 13.9292C12.85 13.9292 13.4408 13.7169 13.9208 13.3015L19.6346 8.29843C19.7731 8.57535 19.8654 8.8892 19.8654 9.23073V14.7692Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EmailIcon;
