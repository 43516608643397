import React, { useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  DESKTOP_FOOTER_HEIGHT,
  LAYOUT_LEFT_RIGHT_PADDING_LG,
  LAYOUT_LEFT_RIGHT_PADDING_MD,
  LAYOUT_LEFT_RIGHT_PADDING_XL,
  LAYOUT_LEFT_RIGHT_PADDING_XS,
  MOBILE_FOOTER_HEIGHT,
} from '../../../../constants/UI';
import { Colors } from '../../../../constants/Colors';
import { GlobalStyles } from '../../../../styles/GlobalStyles';
import DesignLogo from '../../../../assets/icons/design-logo.svg';
import { FooterLink, FooterLinkProps } from '../../Link/FooterLink';
import { companyLinks, forClientLinks, forClientLinksXs, servicesLinks } from './FooterConstants';
import { LanguageButton } from '../../Buttons/LanguageButton';
import { CustomLink } from '../../Link/CustomLink';
import { ProjectPath } from '../../../../constants/ProjectPath';
import { Socials } from '../../Social/Socials';
import { CompanyContacts } from './CompanyContacts';
import AttachPopup from '../../Popups/AttachPopup';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: DESKTOP_FOOTER_HEIGHT,
    width: '100%',
    backgroundColor: Colors.backgroundSecondary,
    borderTop: `1px solid ${Colors.borderColor}`,
    overflowX: 'hidden',
    [theme.breakpoints.down('xs')]: {
      minHeight: MOBILE_FOOTER_HEIGHT,
    },
  },
  content: {
    padding: `5rem ${LAYOUT_LEFT_RIGHT_PADDING_XL} 6.5rem ${LAYOUT_LEFT_RIGHT_PADDING_XL}`,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      padding: `5rem ${LAYOUT_LEFT_RIGHT_PADDING_LG} 6.5rem ${LAYOUT_LEFT_RIGHT_PADDING_LG}`,
    },
    [theme.breakpoints.down('md')]: {
      padding: `5rem ${LAYOUT_LEFT_RIGHT_PADDING_MD} 6.5rem ${LAYOUT_LEFT_RIGHT_PADDING_MD}`,
    },
    [theme.breakpoints.down('xs')]: {
      padding: `2.75rem ${LAYOUT_LEFT_RIGHT_PADDING_XS} 4.375rem ${LAYOUT_LEFT_RIGHT_PADDING_XS}`,
    },
  },
  designBy: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
    },
  },
  designLogo: {
    marginLeft: '0.5rem',
    width: '59.33px',
    height: '16.07px',
    backgroundImage: `url(${DesignLogo})`,
    ...GlobalStyles.icon,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  contactsContainer: {
    height: '8.625rem',
  },
  companyContact: {
    marginBottom: '1.25rem',
  },
  contacts: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: '1.25rem',
  },
  dot: {
    margin: '0 0.75rem',
    ...GlobalStyles.grayCircle,
  },
  social: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1.25rem',
  },
  links: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '4.375rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '5.375rem',
    },
  },
  linksTitle: {
    marginBottom: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
      lineHeight: '18px',
      marginBottom: '10px',
    },
  },
  footer: {
    borderTop: `1px solid ${Colors.borderColor}`,
    padding: `1.5rem ${LAYOUT_LEFT_RIGHT_PADDING_XL} 0.375rem ${LAYOUT_LEFT_RIGHT_PADDING_XL}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& p': {
      color: Colors.darkGray,
    },
    '& button': {
      color: Colors.darkGray,
    },
    [theme.breakpoints.down('lg')]: {
      padding: `1.5rem ${LAYOUT_LEFT_RIGHT_PADDING_LG} 0.375rem ${LAYOUT_LEFT_RIGHT_PADDING_LG}`,
    },
    [theme.breakpoints.down('md')]: {
      padding: `1.5rem ${LAYOUT_LEFT_RIGHT_PADDING_MD} 0.375rem ${LAYOUT_LEFT_RIGHT_PADDING_MD}`,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      padding: `1.5rem ${LAYOUT_LEFT_RIGHT_PADDING_XS} 1.5rem ${LAYOUT_LEFT_RIGHT_PADDING_XS}`,
    },
  },
  politic: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const Footer: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isShowAttachPopup, setIsShowAttachPopup] = useState(false);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const onAskQuestionButtonClick = () => {
    setIsShowAttachPopup(true);
  };

  const renderLinks = (data: FooterLinkProps[]) => {
    return data.map((item, index) => {
      if (item.title === 'Под атакой ?') {
        return <FooterLink key={index} item={item} onClick={onAskQuestionButtonClick} />;
      }
      return <FooterLink key={index} item={item} />;
    });
  };

  return (
    <>
      {isShowAttachPopup && <AttachPopup onClose={() => setIsShowAttachPopup(false)} />}

      <div className={classes.root}>
        <Grid container className={classes.content}>
          <Grid item xs={12} md={5} lg={4}>
            <div className={classes.contactsContainer}>
              <div className={classes.companyContact}>
                <CompanyContacts />
              </div>
              <div className={classes.contacts}>
                <LanguageButton isFooter />
                <div className={classes.dot} />
                <CustomLink
                  isSm
                  isPhone
                  textColor={Colors.newBlack}
                  item={{
                    title: '8 800 888 77 66',
                    url: '',
                  }}
                />
                <div className={classes.dot} />
                <CustomLink
                  isSm
                  isEmail
                  textColor={Colors.newBlack}
                  item={{
                    title: 'support@ddosa.net',
                    url: '',
                  }}
                />
              </div>
              <div className={classes.social}>
                <Socials />
                <Button variant="contained" onClick={onAskQuestionButtonClick}>
                  {t('Задать вопрос')}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={7} lg={6}>
            <Grid container spacing={10} className={classes.links}>
              {isXs ? (
                <>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="h6" component="p" className={classes.linksTitle}>
                      {t('Клиенту')}
                    </Typography>
                    {renderLinks(forClientLinksXs)}
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="h6" component="p" className={classes.linksTitle}>
                      {t('Услуги')}
                    </Typography>
                    {renderLinks(servicesLinks)}
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="h6" component="p" className={classes.linksTitle}>
                      {t('Компания')}
                    </Typography>
                    {renderLinks(companyLinks)}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="h6" component="p" className={classes.linksTitle}>
                      {t('Услуги')}
                    </Typography>
                    {renderLinks(servicesLinks)}
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="h6" component="p" className={classes.linksTitle}>
                      {t('Клиенту')}
                    </Typography>
                    {renderLinks(forClientLinks)}
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="h6" component="p" className={classes.linksTitle}>
                      {t('Компания')}
                    </Typography>
                    {renderLinks(companyLinks)}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.footer}>
          <Typography variant="body2" component="p">
            DDoSa.net 2014 - 2022 © All Rights Reserved
          </Typography>
          <div className={classes.politic}>
            <CustomLink
              isSm
              item={{
                title: 'Политика конфиденциальности',
                url: ProjectPath.Politics,
              }}
            />
          </div>
          <div className={classes.designBy}>
            <Typography variant="body2" component="p">
              {t('Дизайн от')}
            </Typography>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className={classes.designLogo}
              onClick={() => window.open('https://nuts.studio/', '_self')}
            />
          </div>
        </div>
      </div>
    </>
  );
};
