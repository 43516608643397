import React, { useState } from 'react';
import { Fade } from 'react-reveal';
import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SmallCompanyCard } from './SmallCompanyCard';
import { HugeCompanyCard } from './HugeCompanyCard';
import { companyInNumbersConstants } from './CompanyInNumbersConstants';
import AttachPopup from '../../ui/Popups/AttachPopup';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  scrollContainer: {
    marginTop: '1.625rem',
    scrollbarWidth: 'none',
    border: '1px solid rgba(43, 43, 43, 0.15)',
    padding: '1rem',
    borderRadius: '1.875rem',
    boxShadow: '8px 8px 24px rgb(0 0 0 / 5%)',
  },
  mobileButton: {
    marginTop: '26px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  desktopButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  smallCards: {
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      height: 'fit-content',
      top: '90px',
    },
  },
}));

export const CompanyInNumbersBlock: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isShowAttachPopup, setIsShowAttachPopup] = useState(false);

  const onAskQuestionButtonClick = () => {
    setIsShowAttachPopup(true);
  };

  const renderCompaniesScroll = () => {
    return companyInNumbersConstants.map((item, index) => {
      if (index !== 1) {
        return (
          <Grid item xs={12} md={4} lg={4} key={index} className={classes.smallCards}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <SmallCompanyCard item={item.items[0]} />
              </Grid>
              <Grid item xs={12}>
                <HugeCompanyCard item={item.items[1]} />
              </Grid>
              <Grid item xs={12}>
                <SmallCompanyCard item={item.items[2]} />
              </Grid>
            </Grid>
          </Grid>
        );
      }
      return (
        <Grid item xs={12} md={4} lg={4} key={index}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <HugeCompanyCard item={item.items[0]} />
            </Grid>
            <Grid item xs={12}>
              <SmallCompanyCard item={item.items[1]} />
            </Grid>
            <Grid item xs={12}>
              <HugeCompanyCard item={item.items[2]} />
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <>
      {isShowAttachPopup && <AttachPopup onClose={() => setIsShowAttachPopup(false)} />}
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h2">{t('DDosa.net в цифрах')}</Typography>
          <div className={classes.desktopButton}>
            <Button variant="outlined" onClick={onAskQuestionButtonClick}>
              {t('Задать вопрос')}
            </Button>
          </div>
        </div>
        <Fade>
          <div className={classes.scrollContainer}>
            <Grid container spacing={5}>
              {renderCompaniesScroll()}
              <div />
            </Grid>
          </div>
        </Fade>
        <div className={classes.mobileButton}>
          <Button variant="outlined" onClick={onAskQuestionButtonClick}>
            {t('Задать вопрос')}
          </Button>
        </div>
      </div>
    </>
  );
};
