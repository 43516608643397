import React from 'react';

const IdeaIcon: React.FC = () => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7159 4.8C11.1984 2.1 8.75337 0.0974981 6.01587 0.187498C3.26337 0.0899981 0.803368 2.1 0.285868 4.8C-0.149132 7.08 0.885868 9.4425 2.85087 10.6875C3.12087 10.86 3.36837 11.31 3.36837 11.625V13.2075C3.36837 14.0925 4.05087 14.8125 4.88337 14.8125H7.10337C7.93587 14.8125 8.61837 14.0925 8.61837 13.2075V11.625C8.61837 11.31 8.86587 10.86 9.13587 10.6875C11.1084 9.4425 12.1359 7.08 11.7009 4.8H11.7159ZM7.11087 13.6875H4.89087C4.67337 13.6875 4.50087 13.47 4.50087 13.2075V12.5625H7.47087C7.47087 12.5625 7.49337 12.5625 7.50837 12.5625V13.215C7.50837 13.4775 7.33587 13.695 7.11837 13.695L7.11087 13.6875ZM8.54337 9.735C7.99587 10.08 7.59087 10.7925 7.52337 11.4525C7.50087 11.4525 7.48587 11.4375 7.46337 11.4375H4.47087C4.40337 10.785 3.99837 10.08 3.45837 9.735C1.87587 8.7375 1.04337 6.8325 1.39587 5.01C1.80087 2.88 3.71337 1.3125 5.90337 1.3125C5.94087 1.3125 5.97837 1.3125 6.01587 1.3125C8.24337 1.245 10.1934 2.8425 10.6134 5.01C10.9584 6.84 10.1334 8.7375 8.55087 9.735H8.54337Z"
        fill="currentColor"
        fillOpacity="0.8"
      />
      <path
        d="M3.81858 2.91C3.44358 3.12 2.91858 3.51 2.56608 4.17C1.80108 5.5725 2.42358 7.005 2.55108 7.2825C2.64858 7.485 2.85108 7.5975 3.06108 7.5975C3.14358 7.5975 3.22608 7.5825 3.30108 7.545C3.57858 7.41 3.69858 7.0725 3.56358 6.795C3.51108 6.6825 3.03858 5.6475 3.54858 4.71C3.78108 4.29 4.11858 4.035 4.36608 3.8925C4.63608 3.7425 4.73358 3.3975 4.57608 3.1275C4.42608 2.8575 4.08108 2.76 3.81108 2.9175L3.81858 2.91Z"
        fill="currentColor"
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default IdeaIcon;
