import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { makeStyles } from '@material-ui/core';
import { Layout } from '../../components/ui/Layout/Layout';

const useStyles = makeStyles({
  root: {},
});

const PoliticsPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  return <Layout>Политика конфиденциальности</Layout>;
};

export default withRouter(PoliticsPage);
