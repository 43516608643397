import React from 'react';
import { Button, makeStyles, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';

const useStyles = makeStyles({
  input: {
    height: '100%',
    width: '100%',
    '& .MuiInputBase-root': {
      height: '100%',
      backgroundColor: Colors.white,
    },
  },
  customTextField: {
    '& input::placeholder': {
      fontFamily: 'Inter-Regular !important',
      fontStyle: 'italic !important',
      fontSize: '13px',
      color: `${Colors.borderColor} !important`,
    },
  },
  button: {
    position: 'absolute',
  },
});

type TProps = {
  onFindButtonClick: () => void;
};

export const InputWithButton: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { onFindButtonClick } = props;
  const { t } = useTranslation();

  return (
    <>
      <TextField
        classes={{ root: classes.customTextField }}
        placeholder={t('Атака это что ?...')}
        className={classes.input}
        variant="outlined"
      />
      <Button className={classes.button} onClick={onFindButtonClick}>
        {t('Найти')}
      </Button>
    </>
  );
};
