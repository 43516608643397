import React from 'react';
import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import ErrorCard, { TErrorCard } from './ErrorCard';
import { Colors } from '../../constants/Colors';

const useStyles = makeStyles((theme: Theme) => ({
  errorCardsContainer: {},
  errorCards: {
    display: 'flex',
    gap: '121px',
    [theme.breakpoints.down(680)]: {
      gap: '8px',
    },
  },
  line: {
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    gap: '35px',
    flexDirection: 'row',
    [theme.breakpoints.down(680)]: {
      gap: '31px',
    },
  },
  circle: {
    width: '25.63px',
    height: '25.63px',
    borderRadius: '50%',
  },
  smallLine: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '9px',
  },
  smallCircle: {
    width: '7.87px',
    height: '7.87px',
    borderRadius: '50%',
  },
}));

type TErrorCardsProps = {
  cards: TErrorCard[];
  blackIndex: number;
  xsBlackIndex: number;
};

const ErrorCards: React.FC<TErrorCardsProps> = (props: TErrorCardsProps) => {
  const classes = useStyles();
  const { cards, blackIndex, xsBlackIndex } = props;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down(680));
  const lgCirclesCount = isXs ? 5 : 9;
  const blackIndexCheck = isXs ? xsBlackIndex : blackIndex;
  const lgIndexes = [0, 4, 8];
  const xsIndexes = [0, 2, 4];

  const needSmallLine = (index: number) => {
    if (isXs) {
      return xsIndexes.includes(index);
    }
    return lgIndexes.includes(index);
  };

  const renderSmallLine = (lgLineIndex: number) => {
    return (
      <div className={classes.smallLine}>
        {new Array(3).fill(0).map((val, index) => {
          return (
            <div
              className={classes.smallCircle}
              key={index}
              style={{
                background: blackIndexCheck < lgLineIndex ? Colors.newBlack : '#FD9A3F',
                opacity: index % 2 !== 0 ? 1 : 0.3,
              }}
            />
          );
        })}
      </div>
    );
  };

  const renderLine = () => {
    return (
      <div className={classes.line}>
        {new Array(lgCirclesCount).fill(0).map((val, index) => {
          return (
            <div>
              {needSmallLine(index) && renderSmallLine(index)}
              <div
                className={classes.circle}
                style={{ background: blackIndexCheck < index ? Colors.newBlack : '#FD9A3F' }}
                key={index}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={classes.errorCardsContainer}>
      <div className={classes.errorCards}>
        {cards.map((item, index) => {
          return <ErrorCard key={index} item={item} />;
        })}
      </div>
      {renderLine()}
    </div>
  );
};

export default ErrorCards;
