import React from 'react';
import { Button, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/Colors';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import TestStockBackground from '../../../assets/backgrounds/test-stock.svg';
import { redirectToProject } from '../../../utils/redirectToProject';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // width: '28.375rem',
      height: '100%',
      ...GlobalStyles.boxShadow,
      overflow: 'hidden',
      ...GlobalStyles.borderRadius,
      ...GlobalStyles.lightBorder,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    container: {
      height: '100%',
      backgroundColor: Colors.white,
    },
    content: {
      padding: '2.5rem 0 2rem 2.5rem',
      [theme.breakpoints.down('xs')]: {
        padding: '20px 22px 34px 20px',
      },
    },
    title: {
      marginBottom: '0.875rem',
      color: Colors.primary,
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        lineHeight: '18.78px',
      },
    },
    subtitle: {
      marginRight: '3.75rem',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    button: {
      marginTop: '5rem',
      [theme.breakpoints.down('xs')]: {
        marginTop: '40px',
      },
    },
    background: {
      width: '100%',
      backgroundImage: `url(${TestStockBackground})`,
      backgroundPosition: 'left',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      ...GlobalStyles.borderRadius,
    },
    buttonHover: {
      '&.MuiButtonBase-root.MuiButton-root.MuiButton-outlined': {
        color: Colors.white,
        opacity: 1,
        backgroundColor: Colors.primary,
      },
    },
  }),
);

export type StockMdCardProps = {
  title: string;
  subtitle: string;
};

type TProps = {
  item?: StockMdCardProps;
};

export const StockMdCard: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { item } = props;
  const { t } = useTranslation();
  const buttonSelector = '.MuiButtonBase-root.MuiButton-root.MuiButton-outlined';

  const onButtonClick = () => {
    redirectToProject();
  };
  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <div
      className={classes.root}
      onClick={onButtonClick}
      onKeyDown={onButtonClick}
      onMouseEnter={(e) => {
        const button = e.currentTarget.querySelector(buttonSelector);

        if (button) {
          button.className += ` ${classes.buttonHover}`;
        }
      }}
      onMouseLeave={(e) => {
        const button = e.currentTarget.querySelector(buttonSelector);

        if (button) {
          const items = button.className.split(' ');
          const index = items.indexOf(classes.buttonHover);

          items.splice(index, 1);
          button.className = items.join(' ');
        }
      }}
    >
      <Grid container className={classes.container}>
        <Grid item md={5} className={classes.content}>
          <Typography variant="h3" component="div" className={classes.title}>
            {t('Акция «Выгодный контракт»')}
          </Typography>
          <Typography variant="body2" component="p" className={classes.subtitle}>
            {t(
              'Это базовая бессрочная акция для всех клиентов, при оплате услуг за период от 3 до 12 месяцев вы получите скидку от 15 до 25%. Ваше доверие — наша ответственность!\n\nСтоимость услуг со скидкой указана на странице тарифы.',
            )}
          </Typography>
          <Button variant="outlined" className={classes.button}>
            {t('Подробнее')}
          </Button>
        </Grid>
        <Grid item md={7} className={classes.background} />
      </Grid>
    </div>
  );
};
