import React from 'react';

const SpeedIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7502 9.99995C12.7502 9.51995 12.6202 9.06995 12.3902 8.66995L14.7702 6.28995C15.0602 5.99995 15.0602 5.51995 14.7702 5.22995C14.4802 4.93995 14.0002 4.93995 13.7102 5.22995L11.3302 7.60995C10.2802 7.02995 8.94023 7.16995 8.05023 8.05995C7.53023 8.57995 7.24023 9.26995 7.24023 9.99995C7.24023 10.73 7.53023 11.43 8.05023 11.94C8.59023 12.48 9.29023 12.74 9.99023 12.74C10.6902 12.74 11.4002 12.47 11.9302 11.94C12.4502 11.42 12.7402 10.73 12.7402 9.99995H12.7502ZM10.8802 10.88C10.3902 11.37 9.60023 11.37 9.11023 10.88C8.87023 10.64 8.74023 10.33 8.74023 9.99995C8.74023 9.66995 8.87023 9.34995 9.11023 9.11995C9.35023 8.87995 9.67023 8.74995 9.99023 8.74995C10.3102 8.74995 10.6302 8.86995 10.8702 9.11995C11.1102 9.35995 11.2402 9.66995 11.2402 9.99995C11.2402 10.33 11.1102 10.65 10.8702 10.88H10.8802Z"
        fill="currentColor"
      />
      <path
        d="M10 0.25C4.62 0.25 0.25 4.62 0.25 10C0.25 15.38 4.62 19.75 10 19.75C15.38 19.75 19.75 15.38 19.75 10C19.75 4.62 15.38 0.25 10 0.25ZM10 18.25C5.45 18.25 1.75 14.55 1.75 10C1.75 5.45 5.45 1.75 10 1.75C14.55 1.75 18.25 5.45 18.25 10C18.25 14.55 14.55 18.25 10 18.25Z"
        fill="currentColor"
      />
      <path
        d="M8.30023 13.9C6.75023 13.22 5.75023 11.69 5.75023 9.99999C5.75023 7.65999 7.66023 5.74999 10.0002 5.74999C10.2902 5.74999 10.5702 5.77999 10.8502 5.82999C11.2502 5.90999 11.6502 5.64999 11.7302 5.23999C11.8102 4.82999 11.5502 4.43999 11.1402 4.35999C10.7602 4.27999 10.3802 4.23999 9.99023 4.23999C6.82023 4.23999 4.24023 6.81999 4.24023 9.98999C4.24023 12.28 5.59023 14.35 7.69023 15.26C7.79023 15.3 7.89023 15.32 7.99023 15.32C8.28023 15.32 8.55023 15.15 8.68023 14.87C8.85023 14.49 8.68023 14.05 8.29023 13.88L8.30023 13.9Z"
        fill="currentColor"
      />
      <path
        d="M14.7502 8.27006C14.3402 8.35006 14.0802 8.75006 14.1602 9.15006C14.2202 9.43006 14.2402 9.71006 14.2402 10.0001C14.2402 11.6901 13.2402 13.2201 11.6902 13.9001C11.3102 14.0701 11.1403 14.5101 11.3003 14.8901C11.4203 15.1701 11.7002 15.3401 11.9902 15.3401C12.0902 15.3401 12.1902 15.3201 12.2902 15.2801C14.3802 14.3601 15.7402 12.3001 15.7402 10.0101C15.7402 9.62006 15.7002 9.24006 15.6302 8.86006C15.5502 8.45006 15.1402 8.19006 14.7502 8.27006Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SpeedIcon;
