import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  Box,
  Container,
  createStyles,
  CssBaseline,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core';

import { theme as mainTheme } from './styles/theme';
import { Colors } from './constants/Colors';
import { ProjectPath } from './constants/ProjectPath';
import NotFoundPage from './pages/Errors/NotFoundPage';
import IndexPage from './pages/Public/IndexPage';
import ProtectedDnsPage from './pages/Public/ProtectedDnsPage';
import CertificatesPage from './pages/Public/CertificatesPage';
import KnowledgeBasePage from './pages/Public/KnowledgeBasePage';
import AboutPage from './pages/Public/AboutPage';
import SiteProtectionPage from './pages/Public/SiteProtectionPage';
import PartnersPage from './pages/Public/PartnersPage';
import PortsProtection from './pages/Public/PortsProtectionPage';
import NewsAndStocksPage from './pages/Public/NewsAndStocksPage';
import ServerErrorPage from './pages/Errors/ServerErrorPage';
import PoliticsPage from './pages/Public/PoliticsPage';
import GatewayErrorPage from './pages/Errors/GatewayErrorPage';
import StopErrorPage from './pages/Errors/StopErrorPage';
import AnotherErrorPage from './pages/Errors/AnotherErrorPage';
import ServerErrorDescriptionPage from './pages/Errors/ServerErrorDescriptionPage';
import GatewayErrorDescriptionPage from './pages/Errors/GatewayErrorDescriptionPage';
import AccessErrorPage from './pages/Errors/AccessErrorPage';
import RequirementErrorPage from './pages/Errors/RequirementErrorPage';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      width: '100%',
      margin: '0 5rem',
    },
    success: {
      backgroundColor: Colors.success,
    },
    error: {
      backgroundColor: Colors.error,
    },
  }),
);

const App: React.FC = () => {
  const classes = useStyles();
  const init = {
    identifier: 'BQTAnnnrysMytxt0k9PBKJi1Yhj86vcr',
    secret: 'zw9nzMmWWWdDRABIMJrhwD692h6SLK7F',
    endpoint: 'https://my.ddosa.net/includes/api.php',
    userAgent: '',
    username: '',
  };

  return (
    <React.StrictMode>
      <CssBaseline />
      <ThemeProvider theme={mainTheme}>
        <Container maxWidth={false} disableGutters className={classes.root}>
          <Box className={classes.container}>
            <Router>
              <Switch>
                <Route exact path={ProjectPath.Index} component={IndexPage} />
                <Route exact path={ProjectPath.ProtectedDns} component={ProtectedDnsPage} />
                <Route exact path={ProjectPath.Certificates} component={CertificatesPage} />
                <Route exact path={ProjectPath.KnowledgeBase} component={KnowledgeBasePage} />
                <Route exact path={ProjectPath.About} component={AboutPage} />
                <Route exact path={ProjectPath.Blog} component={NewsAndStocksPage} />
                <Route exact path={ProjectPath.SiteProtection} component={SiteProtectionPage} />
                <Route exact path={ProjectPath.Partners} component={PartnersPage} />
                <Route exact path={ProjectPath.PortsProtection} component={PortsProtection} />
                <Route exact path={ProjectPath.Politics} component={PoliticsPage} />
                {/* ERRORS */}
                <Route exact path={ProjectPath.ServerError} component={ServerErrorPage} />
                <Route
                  exact
                  path={ProjectPath.AnotherServerError}
                  component={ServerErrorDescriptionPage}
                />
                <Route exact path={ProjectPath.GatewayError} component={GatewayErrorPage} />
                <Route
                  exact
                  path={ProjectPath.AnotherGatewayError}
                  component={GatewayErrorDescriptionPage}
                />
                <Route exact path={ProjectPath.AnotherError} component={AnotherErrorPage} />
                <Route exact path={ProjectPath.StopError} component={StopErrorPage} />
                <Route exact path={ProjectPath.AccessError} component={AccessErrorPage} />
                <Route exact path={ProjectPath.RequirementError} component={RequirementErrorPage} />
                <Route component={NotFoundPage} />
              </Switch>
            </Router>
          </Box>
        </Container>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
