import React from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Fade } from 'react-reveal';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { GlobalStyles } from '../../../styles/GlobalStyles';
import { Colors } from '../../../constants/Colors';
import { SmHowProtectionWorksBanner } from '../Banners/HowProtectionWorks/SmHowProtectionWorksBanner';
import DnsTip from '../../../assets/backgrounds/dns-tip.png';
import { ProjectPath } from '../../../constants/ProjectPath';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '5.375rem',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      marginTop: '50px',
    },
  },
  descriptionContainer: {
    maxWidth: '38.75rem',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      // marginBottom: '5rem',
    },
  },
  title: {
    marginBottom: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '30px',
    },
  },
  subtitle: {
    marginBottom: '2.25rem',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
      fontFamily: 'Inter-Regular',
      fontSize: '13px',
      lineHeight: '15.73px',
      color: Colors.newBlack,
    },
  },
  text: {
    color: Colors.newBlack,
  },
  gridItem: {
    width: '100%',
    height: '19.25rem',
    backgroundColor: Colors.gray,
    ...GlobalStyles.borderRadius,
    [theme.breakpoints.down('md')]: {
      margin: '2rem 0',
    },
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  imageContainer: {
    height: 'auto',
    padding: '30px 34px 21px 30px',
    backgroundColor: Colors.backgroundSecondary,
  },
  image: {
    [theme.breakpoints.down('xs')]: {
      margin: '15px -30px 26px -30px',
    },
    width: '100%',
    margin: '27px 0 34px -30px',
    borderRadius: '0 8px 8px 0',
    ...GlobalStyles.lightBorder,
    filter: 'drop-shadow(8px 8px 24px rgba(0, 0, 0, 0.05))',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(1870)]: {
      display: 'block',
    },
  },
  imageSubtitle: {
    maxWidth: '454px',
    color: Colors.darkGray,
  },
  button: {
    [theme.breakpoints.down(1870)]: {
      marginTop: '11px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}));

export type PageDetailsProps = {
  title: string;
  subtitle: string;
  descriptionOne: string;
  descriptionTwo: string;
};

type TProps = {
  isDns?: boolean;
  item: PageDetailsProps;
};

export const PageDetails: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { item, isDns } = props;
  const theme = useTheme();
  const laptop = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  const renderImage = () => {
    if (isDns) {
      return (
        <div className={`${classes.gridItem} ${classes.imageContainer}`}>
          <Typography variant="h3" component="div">
            {t('Удобное управление в личном кабинете')}
          </Typography>
          <Fade>
            <img src={DnsTip} alt="" className={classes.image} />
          </Fade>
          <div className={classes.container}>
            <Typography variant="h6" component="p" className={classes.imageSubtitle}>
              {t('Если возникнут вопросы, наша база знаий всегда поможет')}
            </Typography>
            <div className={classes.button}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => history.push(ProjectPath.KnowledgeBase)}
              >
                {t('База знаний')}
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return <div className={classes.gridItem} />;
  };

  const renderDnsBottom = () => {
    if (!laptop) {
      return (
        <Grid item xs={12}>
          {renderImage()}
        </Grid>
      );
    }
    return <div className={classes.gridItem} />;
  };

  return (
    <Grid container className={classes.root}>
      <Grid item sm={12} md={5} className={classes.descriptionContainer}>
        <Typography variant="h2" className={classes.title}>
          {t(item.title)}
        </Typography>
        <Typography variant="h4" component="div" className={classes.subtitle}>
          {t(item.subtitle)}
        </Typography>
        <Typography variant="h6" component="p" className={classes.text}>
          {t(item.descriptionOne)}
        </Typography>
        {laptop && renderImage()}
        <Typography variant="h6" component="p" className={classes.text}>
          {t(item.descriptionTwo)}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={5}>
          {isDns ? (
            renderDnsBottom()
          ) : (
            <>
              {!laptop && (
                <Grid item xs={12} xl={6}>
                  <Fade>{renderImage()}</Fade>
                </Grid>
              )}
              <Grid item xs={12} xl={6}>
                <Fade>
                  <div className={classes.gridItem} />
                </Fade>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Fade>
              <SmHowProtectionWorksBanner />
            </Fade>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
