import React, { PropsWithChildren, ReactNode, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import {
  DESKTOP_FOOTER_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
  LAYOUT_LEFT_RIGHT_PADDING_LG,
  LAYOUT_LEFT_RIGHT_PADDING_MD,
  LAYOUT_LEFT_RIGHT_PADDING_XL,
  MOBILE_FOOTER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from '../../../constants/UI';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  content: {
    minHeight: `calc(100vh - ${DESKTOP_HEADER_HEIGHT} - ${DESKTOP_FOOTER_HEIGHT})`,
    width: '100%',
    padding: `0 ${LAYOUT_LEFT_RIGHT_PADDING_XL} 3.75rem ${LAYOUT_LEFT_RIGHT_PADDING_XL}`,
    [theme.breakpoints.down('lg')]: {
      padding: `0 ${LAYOUT_LEFT_RIGHT_PADDING_LG} 3.75rem ${LAYOUT_LEFT_RIGHT_PADDING_LG}`,
    },
    [theme.breakpoints.down('md')]: {
      padding: `0 ${LAYOUT_LEFT_RIGHT_PADDING_MD} 3.75rem ${LAYOUT_LEFT_RIGHT_PADDING_MD}`,
    },
    [theme.breakpoints.down('xs')]: {
      padding: `0 16px 3.75rem 16px`,
      minHeight: `calc(100vh - ${MOBILE_HEADER_HEIGHT} - ${MOBILE_FOOTER_HEIGHT})`,
    },
  },
  iosContent: {
    overflow: 'hidden',
  },
  anotherPlatformsContent: {
    contain: 'paint',
  },
}));

export const Layout: React.FC<PropsWithChildren<ReactNode>> = (
  props: PropsWithChildren<ReactNode>,
) => {
  const classes = useStyles();
  const { children } = props;
  const anchor = window.location.hash;
  const isIOS = /iPad|iPhone|iPod/.test(navigator.platform);

  useEffect(() => {
    if (anchor) {
      const domElement = document.querySelector(anchor);
      if (domElement) {
        domElement.scrollIntoView(true);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [anchor]);

  return (
    <div className={classes.root}>
      <Header />
      <div
        className={`${classes.content} ${
          isIOS ? classes.iosContent : classes.anotherPlatformsContent
        }`}
      >
        {children}
      </div>
      <Footer />
    </div>
  );
};
