import { CustomLinkProps } from '../../ui/Link/CustomLink';
import { ProjectPath } from '../../../constants/ProjectPath';
import LaptopIcon from '../../ui/Icons/LaptopIcon';
import RotateIcon from '../../ui/Icons/RotateIcon';
import LockIcon from '../../ui/Icons/LockIcon';
import DatabaseIcon from '../../ui/Icons/DatabaseIcon';
import FingerPrintIcon from '../../ui/Icons/FingerPrintIcon';
import SpeedIcon from '../../ui/Icons/SpeedIcon';
import DiamondIcon from '../../ui/Icons/DiamondIcon';
import ComputerIcon from '../../ui/Icons/ComputerIcon';
import RocketIcon from '../../ui/Icons/RocketIcon';
import KeyIcon from '../../ui/Icons/KeyIcon';

export const tcpServiceLinks: CustomLinkProps[] = [
  {
    title: 'OracleLinux',
    url: ProjectPath.KnowledgeBase,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <LaptopIcon />,
  },
  {
    title: 'QResp',
    url: ProjectPath.KnowledgeBase,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <RotateIcon />,
  },
  {
    title: 'SafeWall',
    url: ProjectPath.KnowledgeBase,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <LockIcon />,
  },
  {
    title: 'Серверы Supermicro и Arbor',
    url: ProjectPath.KnowledgeBase,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <DatabaseIcon />,
  },
];

export const webServiceLinks: CustomLinkProps[] = [
  {
    title: 'Анализ GreatEey',
    url: ProjectPath.KnowledgeBase,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <FingerPrintIcon />,
  },
  {
    title: 'FastL',
    url: ProjectPath.KnowledgeBase,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <SpeedIcon />,
  },
  {
    title: 'Adamant',
    url: ProjectPath.KnowledgeBase,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <DiamondIcon />,
  },
  {
    title: 'ЦОД на Supermicro',
    url: ProjectPath.KnowledgeBase,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <ComputerIcon />,
  },
];

export const protectedDns: CustomLinkProps = {
  title: 'Защищенный DNS',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <RocketIcon />,
  url: ProjectPath.ProtectedDns,
};

export const certificates: CustomLinkProps = {
  title: 'Сертификаты',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <KeyIcon />,
  url: ProjectPath.Certificates,
};
