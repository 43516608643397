import React from 'react';
import { Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GlobalStyles } from '../../../../styles/GlobalStyles';
import { Colors } from '../../../../constants/Colors';
import { SocialCard } from './SocialCard';
import VkIcon from '../../../../assets/icons/vk-icon.svg';
import TelegramIcon from '../../../../assets/icons/telegram-icon.svg';
import VcIcon from '../../../../assets/icons/vc-icon.svg';
import { CustomLink } from '../../../ui/Link/CustomLink';
import MobilePayment from '../../../../assets/icons/mobile-payment.svg';
import MobilePaymentEmail from '../../../../assets/icons/mobile-payment-email.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    padding: '2.5rem 2.875rem',
    backgroundColor: Colors.backgroundSecondary,
    ...GlobalStyles.borderRadius,
    [theme.breakpoints.down('xs')]: {
      padding: '18px',
    },
  },
  contactsGridItem: {
    height: '100%',
    padding: '1.375rem 1.375rem 1.875rem 1.875rem',
    backgroundColor: 'transparent',
    display: 'grid',
    gap: '0.875rem',
    ...GlobalStyles.border,
    ...GlobalStyles.borderRadius,
  },
  colContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    height: '3.625rem',
    width: '3.625rem',
    marginRight: '0.625rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    ...GlobalStyles.boxShadow,
  },
  icon: {
    height: '2.5rem',
    width: '2.5rem',
    ...GlobalStyles.icon,
  },
  title: {
    marginBottom: '0.25rem',
  },
}));

export const SocialsGrid: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));
  const laptop = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  return (
    <Grid container spacing={mobile ? 3 : 7} className={classes.root}>
      {laptop && (
        <Grid item xs={12} md={6}>
          <div className={classes.contactsGridItem}>
            <div className={classes.colContainer}>
              <div className={classes.iconContainer}>
                <div
                  className={classes.icon}
                  style={{ backgroundImage: `url(${MobilePayment})` }}
                />
              </div>
              <div>
                <Typography className={classes.title} variant="h4" component="div">
                  {t('Телефон')}
                </Typography>
                <CustomLink
                  isSm
                  isPhone
                  item={{
                    title: '8 (800) 100-21-16',
                    url: '',
                  }}
                  textColor={Colors.primary}
                />
              </div>
            </div>
            <div className={classes.colContainer}>
              <div className={classes.iconContainer}>
                <div
                  className={classes.icon}
                  style={{ backgroundImage: `url(${MobilePaymentEmail})` }}
                />
              </div>
              <div>
                <Typography className={classes.title} variant="h4" component="div">
                  {t('Почта')}
                </Typography>
                <CustomLink
                  isSm
                  isEmail
                  item={{
                    title: 'support@ddosa.net',
                    url: '',
                  }}
                  textColor={Colors.primary}
                />
              </div>
            </div>
          </div>
        </Grid>
      )}
      {desktop && (
        <SocialCard
          item={{ title: 'Телеграм', link: 'https://t.me/ddosanet', icon: TelegramIcon }}
        />
      )}
      <SocialCard item={{ title: 'Вконтакте', link: 'https://vk.com/ddosanet', icon: VkIcon }} />
      {laptop && (
        <SocialCard
          item={{ title: 'Телеграм', link: 'https://t.me/ddosanet', icon: TelegramIcon }}
        />
      )}
      {desktop && (
        <Grid item xs={12} md={6}>
          <div className={classes.contactsGridItem}>
            <div className={classes.colContainer}>
              <div className={classes.iconContainer}>
                <div
                  className={classes.icon}
                  style={{ backgroundImage: `url(${MobilePayment})` }}
                />
              </div>
              <div>
                <Typography className={classes.title} variant="h4" component="div">
                  {t('Телефон')}
                </Typography>
                <CustomLink
                  isSm
                  item={{
                    title: '8 (800) 100-21-16',
                    url: '',
                  }}
                  textColor={Colors.primary}
                />
              </div>
            </div>
            <div className={classes.colContainer}>
              <div className={classes.iconContainer}>
                <div
                  className={classes.icon}
                  style={{ backgroundImage: `url(${MobilePaymentEmail})` }}
                />
              </div>
              <div>
                <Typography className={classes.title} variant="h4" component="div">
                  {t('Почта')}
                </Typography>
                <CustomLink
                  isSm
                  isEmail
                  item={{
                    title: 'support@ddosa.net',
                    url: '',
                  }}
                  textColor={Colors.primary}
                />
              </div>
            </div>
          </div>
        </Grid>
      )}
      <SocialCard item={{ title: 'Блог VC', link: 'https://vc.ru/ddosanet', icon: VcIcon }} />
    </Grid>
  );
};
