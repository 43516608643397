import { theme as mainTheme } from './theme';
import { Colors } from '../constants/Colors';

export const GlobalStyles = {
  container: {
    width: '100%',
    margin: '0 5rem',
    [mainTheme.breakpoints.down(1800)]: {
      margin: '0 auto',
      width: 'calc(100% - 10rem)',
    },
    [mainTheme.breakpoints.up(1800)]: {
      margin: '0 auto',
      maxWidth: 1620,
    },
  },
  border: {
    border: `1px solid ${Colors.borderColor}`,
  },
  lightBorder: {
    border: `1px solid ${Colors.backgroundSecondary}`,
  },
  icon: {
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  borderRadius: {
    borderRadius: '1.875rem',
    [`@media screen and (max-width: 600px)`]: {
      borderRadius: '20px',
    },
  },
  boxShadow: {
    boxShadow: '8px 8px 24px rgba(0, 0, 0, 0.05)',
  },
  grayCircle: {
    height: '3px',
    width: '3px',
    borderRadius: '50%',
    backgroundColor: Colors.borderColor,
  },
  pagination: {
    '& .swiper-wrapper': {
      boxSizing: 'inherit',
    },
    '& .swiper-pagination': {
      left: 0,
      bottom: 0,
      backgroundColor: 'transparent',
    },
    '& .swiper-pagination-bullet': {
      height: '0.75rem',
      width: '0.75rem',
      backgroundColor: Colors.borderColor,
      opacity: 0.3,
      [`@media screen and (max-width: 600px)`]: {
        height: '10px',
        width: '10px',
      },
    },
    '& .swiper-pagination-bullet-active': {
      height: '0.75rem',
      width: '0.75rem',
      backgroundColor: Colors.borderColor,
      opacity: 1,
    },
  },
  slides: {
    '& .swiper-slide': {
      height: 'auto',
    },
  },
};
