import React from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Switcher, TSwitcherProps } from '../ui/Switcher/Switcher';
import { Colors } from '../../constants/Colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      alignItems: 'initial',
      flexDirection: 'column-reverse !important',
    },
  },
  subtitle: {
    marginTop: '1.875rem',
    color: Colors.lightBlack,
  },
  notReversed: {
    marginTop: '16px',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
}));

type TProps = {
  isReverse?: boolean;
  title: string;
  subtitle: string;
  item: TSwitcherProps;
};

export const UpperProtectionBlock: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isReverse, title, subtitle, item } = props;

  return (
    <Grid
      spacing={5}
      container
      className={classes.root}
      style={{ flexDirection: isReverse ? 'row-reverse' : 'row' }}
    >
      <Grid item xs={12} lg={6}>
        <Switcher data={item} isReversed={isReverse} />
      </Grid>
      <Grid item xs={12} lg={5}>
        <Typography variant="h2" className={`${isReverse ? '' : classes.notReversed}`}>
          {t(title)}
        </Typography>
        <Typography variant="h6" component="p" className={classes.subtitle}>
          {t(subtitle)}
        </Typography>
      </Grid>
    </Grid>
  );
};
